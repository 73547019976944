/*
Theme Name: Progressive
Theme URI: http://template.progressive.itembridge.com/
Author: InfoStyle
Author URI: http://themeforest.net/user/InfoStyle
Description: Progressive responsive theme
Version: 3.0.2
License: ThemeForest Regular & Extended License
License URI: http://themeforest.net/licenses/regular_extended
*/
/* line 33, ../sass/_theme-variables.scss */
.toolbar .up-down, .sitemap a:before {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0);
}

/******************************************************************************
  Typography
*******************************************************************************/
/* line 4, ../sass/_typography.scss */
* {
  border-color: #e1e1e1;
  border-style: solid;
  border-width: 0;
  zoom: 1;
  -webkit-tap-highlight-color: transparent;
}

/* line 11, ../sass/_typography.scss */
*,
*:hover,
*:focus {
  outline: none !important;
}

/* line 16, ../sass/_typography.scss */
html,
body {
  font-size: 14px;
  height: 100%;
}

/* line 21, ../sass/_typography.scss */
.full-height {
  height: 100% !important;
}
/* line 24, ../sass/_typography.scss */
.full-height.scroll {
  height: auto !important;
}

/* line 28, ../sass/_typography.scss */
.full-height body {
  position: relative;
}

/* line 31, ../sass/_typography.scss */
body {
  color: #333;
  font-family: "Arimo", sans-serif;
  height: 100%;
  line-height: 1.6;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

/* line 40, ../sass/_typography.scss */
body.loaded {
  overflow-x: inherit;
}

/* line 43, ../sass/_typography.scss */
.scroll-width {
  height: 150px;
  left: 0;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
  top: 0;
  width: 200px;
}

/* line 52, ../sass/_typography.scss */
.scroll-width div {
  height: 200px;
  width: 100%;
}

/* line 56, ../sass/_typography.scss */
input,
button,
select,
textarea {
  font-family: "Arimo", sans-serif;
}

/* line 62, ../sass/_typography.scss */
.page-box {
  min-height: 100%;
  height: auto !important;
  height: 100%;
}

/* line 67, ../sass/_typography.scss */
img {
  max-width: 100%;
  height: auto;
}

/* line 71, ../sass/_typography.scss */
svg image {
  display: none;
}

/* line 74, ../sass/_typography.scss */
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* line 79, ../sass/_typography.scss */
label {
  font-weight: normal;
}

/* Links */
/* line 83, ../sass/_typography.scss */
a:active {
  color: #000;
}

/* line 86, ../sass/_typography.scss */
a.no-border,
.no-border a {
  color: #7f7f7f;
  text-decoration: none;
  -webkit-transition: color .2s linear;
          transition: color .2s linear;
}
/* line 92, ../sass/_typography.scss */
a.no-border:hover,
.no-border a:hover {
  color: #1e1e1e;
  text-decoration: none;
  -webkit-transition: color .2s linear;
          transition: color .2s linear;
}
/* line 97, ../sass/_typography.scss */
.white a.no-border, .white
.no-border a {
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
}
/* line 101, ../sass/_typography.scss */
.white a.no-border:hover, .white
.no-border a:hover {
  color: #fff;
}

/* Text */
/* line 107, ../sass/_typography.scss */
h1, h2, h3, h4, h5, h6 {
  margin: 0 0 22px;
  text-rendering: optimizelegibility;
}

/* line 111, ../sass/_typography.scss */
.light {
  font-weight: normal;
}

/* line 114, ../sass/_typography.scss */
p {
  margin-bottom: 22px;
}

/* line 117, ../sass/_typography.scss */
p:last-child {
  margin-bottom: 0;
}

/* line 120, ../sass/_typography.scss */
address {
  font-size: 13px;
}

/* line 123, ../sass/_typography.scss */
ul,
ol {
  padding: 0 0 0 60px;
  margin: 0 0 22px 0;
}

/* line 128, ../sass/_typography.scss */
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* line 133, ../sass/_typography.scss */
.white,
.icon.white {
  color: #fff;
}

/* line 137, ../sass/_typography.scss */
.muted,
.icon.muted {
  color: #555;
}

/* line 141, ../sass/_typography.scss */
.text-warning,
a.text-warning:hover,
.icon.text-warning {
  color: #f89406;
}

/* line 146, ../sass/_typography.scss */
.text-error,
a.text-error:hover,
.icon.text-error,
.text-danger,
a.text-danger:hover,
.icon.text-danger {
  color: #c10841;
}

/* line 154, ../sass/_typography.scss */
.text-info,
a.text-info:hover,
.icon.text-info {
  color: #0098ca;
}

/* line 159, ../sass/_typography.scss */
.text-success,
a.text-success:hover,
.icon.text-success {
  color: #738d00;
}

/* line 164, ../sass/_typography.scss */
.bg-white {
  background: #fff !important;
}

/* line 167, ../sass/_typography.scss */
.bg-muted {
  background: #555 !important;
}

/* line 170, ../sass/_typography.scss */
.bg-primary {
  background: #037ac5 !important;
}

/* line 173, ../sass/_typography.scss */
.bg-warning {
  background: #f89406 !important;
}

/* line 176, ../sass/_typography.scss */
.bg-error,
.bg-danger {
  background: #c10841 !important;
}

/* line 180, ../sass/_typography.scss */
.bg-info {
  background: #0098ca !important;
}

/* line 183, ../sass/_typography.scss */
.bg-success {
  background: #738d00 !important;
}

/* line 186, ../sass/_typography.scss */
.border-muted {
  border-color: #555 !important;
}

/* line 189, ../sass/_typography.scss */
.border-warning {
  border-color: #f89406 !important;
}

/* line 192, ../sass/_typography.scss */
.border-error,
.border-danger {
  border-color: #c10841 !important;
}

/* line 196, ../sass/_typography.scss */
.border-info {
  border-color: #0098ca !important;
}

/* line 199, ../sass/_typography.scss */
.border-success {
  border-color: #738d00 !important;
}

/* line 202, ../sass/_typography.scss */
.border-grey {
  border-color: #777 !important;
}

/* line 205, ../sass/_typography.scss */
.lead {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 26px;
}

/* line 210, ../sass/_typography.scss */
.text-small {
  font-size: 12px;
  line-height: 18px;
}

/* hr */
/* line 215, ../sass/_typography.scss */
hr {
  border: 0;
  border-top: 1px solid #e1e1e1;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0 none;
  margin: 40px 0;
}
/* line 222, ../sass/_typography.scss */
hr.dotted {
  border-style: dotted;
  border-top-width: 1px;
}
/* line 226, ../sass/_typography.scss */
hr.dashed {
  border-style: dashed;
  border-top-width: 1px;
}
/* line 230, ../sass/_typography.scss */
hr.double {
  border-style: double;
  border-top-width: 3px;
}
/* line 234, ../sass/_typography.scss */
hr.shadow {
  border: 0 none;
  margin-bottom: 30px;
  overflow: hidden;
  padding-top: 10px;
  position: relative;
}
/* line 241, ../sass/_typography.scss */
hr.shadow:after {
  bottom: 10px;
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  content: "";
  height: 20px;
  left: 5%;
  position: absolute;
  right: 5%;
  z-index: -1;
}
/* line 253, ../sass/_typography.scss */
hr.no-line {
  border: 0 none;
  height: 1px;
}
/* line 257, ../sass/_typography.scss */
hr.margin-bottom {
  margin-top: 0;
}
/* line 260, ../sass/_typography.scss */
hr.margin-top {
  margin-bottom: 0;
}

/* Button */
/* line 265, ../sass/_typography.scss */
.btn {
  border-radius: 3px;
  line-height: 22px;
  outline: none;
  padding: 8px 19px;
  -webkit-filter: none;
          filter: none;
  -webkit-transition: background .2s linear, border .2s linear, color .2s linear;
          transition: background .2s linear, border .2s linear, color .2s linear;
  white-space: normal;
}
/* line 278, ../sass/_typography.scss */
.btn.btn-default:hover, .btn.btn-default:focus, .btn.btn-default.focus, .btn.btn-default:active, .btn.btn-default.active, .open > .btn.btn-default.dropdown-toggle {
  background: #565656;
  border-color: #565656;
}
/* line 289, ../sass/_typography.scss */
.btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary.focus, .btn.btn-primary:active, .btn.btn-primary.active, .open > .btn.btn-primary.dropdown-toggle {
  background: #0499f7;
  border-color: #0499f7;
}
/* line 300, ../sass/_typography.scss */
.btn.btn-success:hover, .btn.btn-success:focus, .btn.btn-success.focus, .btn.btn-success:active, .btn.btn-success.active, .open > .btn.btn-success.dropdown-toggle {
  background: #9dc000;
  border-color: #9dc000;
}
/* line 311, ../sass/_typography.scss */
.btn.btn-info:hover, .btn.btn-info:focus, .btn.btn-info.focus, .btn.btn-info:active, .btn.btn-info.active, .open > .btn.btn-info.dropdown-toggle {
  background: #00befd;
  border-color: #00befd;
}
/* line 322, ../sass/_typography.scss */
.btn.btn-warning:hover, .btn.btn-warning:focus, .btn.btn-warning.focus, .btn.btn-warning:active, .btn.btn-warning.active, .open > .btn.btn-warning.dropdown-toggle {
  background: #faa937;
  border-color: #faa937;
}
/* line 333, ../sass/_typography.scss */
.btn.btn-danger:hover, .btn.btn-danger:focus, .btn.btn-danger.focus, .btn.btn-danger:active, .btn.btn-danger.active, .open > .btn.btn-danger.dropdown-toggle {
  background: #f20a51;
  border-color: #f20a51;
}
/* line 343, ../sass/_typography.scss */
.btn.btn-red {
  background: #ed093a;
  color: #fff;
}
/* line 347, ../sass/_typography.scss */
.btn.btn-red:hover, .btn.btn-red:focus, .btn.btn-red:active, .btn.btn-red.active, .btn.btn-red.disabled, .btn.btn-red[disabled] {
  color: #ffffff;
  background-color: #fb4c71;
}
/* line 356, ../sass/_typography.scss */
.btn.btn-red:active, .btn.btn-red.active, .btn-group.open .btn.btn-red.dropdown-toggle {
  box-shadow: inset 0px 5px 0px rgba(0, 0, 0, 0.12);
}
/* line 362, ../sass/_typography.scss */
.btn.btn-white {
  background: #fff;
  border-color: #fff;
  color: #1e1e1e;
}
/* line 367, ../sass/_typography.scss */
.btn.btn-white:hover, .btn.btn-white:focus, .btn.btn-white:active, .btn.btn-white.active, .btn.btn-white.disabled, .btn.btn-white[disabled], .btn-group.open .btn.btn-white.dropdown-toggle {
  background-color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.8);
  color: #1e1e1e;
}
/* line 379, ../sass/_typography.scss */
.btn.btn-link[disabled]:hover, .btn.btn-link[disabled]:focus {
  opacity: .8;
  filter: alpha(opacity=80);
}
/* line 384, ../sass/_typography.scss */
.btn .btn.disabled,
.btn .btn[disabled] {
  opacity: .8;
  filter: alpha(opacity=80);
}
/* line 389, ../sass/_typography.scss */
.btn.btn-inverse {
  background: #ccc;
  background: rgba(30, 30, 30, 0.3);
  color: #fff;
}
/* line 394, ../sass/_typography.scss */
.btn.btn-inverse:hover, .btn.btn-inverse:focus, .btn.btn-inverse:active, .btn.btn-inverse.active, .btn.btn-inverse.disabled, .btn.btn-inverse[disabled], .btn-group.open .btn.btn-inverse.dropdown-toggle {
  color: #efefef;
  background-color: #000;
}
/* line 405, ../sass/_typography.scss */
.btn.btn-border {
  background: none;
  border: 1px solid #1e1e1e;
  color: #1e1e1e;
}
/* line 410, ../sass/_typography.scss */
.btn.btn-border .caret {
  color: #1e1e1e;
}
/* line 413, ../sass/_typography.scss */
.btn.btn-border.btn-inverse, .btn.btn-border.btn-inverse .caret {
  border-color: rgba(30, 30, 30, 0.3);
  color: rgba(30, 30, 30, 0.3);
}
/* line 418, ../sass/_typography.scss */
.btn.btn-border.btn-primary, .btn.btn-border.btn-primary .caret {
  border-color: #037ac5;
  color: #037ac5;
}
/* line 423, ../sass/_typography.scss */
.btn.btn-border.btn-warning, .btn.btn-border.btn-warning .caret {
  border-color: #f89406;
  color: #f89406;
}
/* line 428, ../sass/_typography.scss */
.btn.btn-border.btn-danger, .btn.btn-border.btn-danger .caret {
  border-color: #c10841;
  color: #c10841;
}
/* line 433, ../sass/_typography.scss */
.btn.btn-border.btn-success, .btn.btn-border.btn-success .caret {
  border-color: #738d00;
  color: #738d00;
}
/* line 438, ../sass/_typography.scss */
.btn.btn-border.btn-info, .btn.btn-border.btn-info .caret {
  border-color: #0098ca;
  color: #0098ca;
}
/* line 443, ../sass/_typography.scss */
.btn.btn-border:hover, .btn.btn-border:focus, .btn.btn-border:active, .btn.btn-border.active, .btn.btn-border.disabled, .btn.btn-border[disabled], .btn-group.open .btn.btn-border.btn.dropdown-toggle {
  color: #fff;
}
/* line 452, ../sass/_typography.scss */
.btn.btn-border:hover .caret, .btn.btn-border:focus .caret, .btn.btn-border:active .caret, .btn.btn-border.active .caret, .btn.btn-border.disabled .caret, .btn.btn-border[disabled] .caret, .btn-group.open .btn.btn-border.btn.dropdown-toggle .caret {
  color: #fff;
}
/* line 457, ../sass/_typography.scss */
.btn.btn-lg {
  font-size: 14px;
  padding: 15px 44px;
}
/* line 461, ../sass/_typography.scss */
.btn.btn-sm {
  font-size: 12px;
  padding: 4px 14px;
}
/* line 465, ../sass/_typography.scss */
.btn.btn-xs {
  font-size: 10px;
  padding: 1px 9px;
}

/* line 470, ../sass/_typography.scss */
.dropdown-menu {
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  min-width: 156px;
  padding: 0;
  z-index: 800;
}
/* line 478, ../sass/_typography.scss */
.dropdown-menu > li > a i {
  font-size: 18px;
  margin-right: 10px;
  min-width: 18px;
  vertical-align: middle;
}

/* line 485, ../sass/_typography.scss */
.dropdown-backdrop {
  position: static;
}

/* line 488, ../sass/_typography.scss */
.btn-group .dropdown-toggle,
.btn-group.open .dropdown-toggle {
  box-shadow: none;
}

/* line 494, ../sass/_typography.scss */
.open > .dropdown-menu {
  -webkit-animation: fadeIn 0.4s;
  animation: fadeIn 0.4s;
}

/* line 498, ../sass/_typography.scss */
.navbar-inverse .brand,
.navbar-inverse .nav > li > a {
  color: #7b7b7b;
  text-shadow: none;
}

/* line 504, ../sass/_typography.scss */
.btn-group > .btn:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
/* line 508, ../sass/_typography.scss */
.btn-group > .btn:last-child,
.btn-group > .dropdown-toggle {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
/* line 513, ../sass/_typography.scss */
.btn-group > .btn:before,
.btn-group > .btn:after {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  bottom: 0;
  display: inline-block;
  content: "";
  left: -1px;
  position: absolute;
  top: 0;
}
/* line 523, ../sass/_typography.scss */
.btn-group > .btn.btn-border:before,
.btn-group > .btn.btn-border:after {
  display: none;
}
/* line 527, ../sass/_typography.scss */
.btn-group .btn-group > .btn:after {
  left: auto;
  right: 0;
}
/* line 531, ../sass/_typography.scss */
.btn-group > .btn:first-child:before,
.btn-group > .btn:last-child:after,
.btn-group > .btn:only-of-type:after,
.btn-group > .btn:only-of-type:before {
  display: none;
}

/* line 538, ../sass/_typography.scss */
.ladda-button {
  font-size: 100%;
}
/* line 541, ../sass/_typography.scss */
.ladda-button[data-style=contract][data-loading] {
  width: 42px;
}

/* Forms */
/* line 547, ../sass/_typography.scss */
form {
  margin-bottom: 22px;
}

/* line 550, ../sass/_typography.scss */
label {
  font-weight: bold;
  margin: 0 0 3px;
}

/* line 554, ../sass/_typography.scss */
.required {
  color: #c3293a;
  display: inline-block;
  font-size: 14px;
}

/* line 559, ../sass/_typography.scss */
.selectBox,
.form-control {
  border: 1px solid #e1e1e1;
  box-shadow: 0 1px #fff, inset 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  font-size: 12px;
  height: 40px;
  line-height: 22px;
  margin-bottom: 22px;
  min-height: 40px;
  padding: 8px 12px;
  width: 100%;
}
/* line 572, ../sass/_typography.scss */
.selectBox:hover,
.form-control:hover {
  border-color: #ccc;
  text-decoration: none;
}
/* line 576, ../sass/_typography.scss */
.selectBox:focus, .selectBox.selectBox-menuShowing,
.form-control:focus,
.form-control.selectBox-menuShowing {
  box-shadow: 0 0 10px rgba(46, 84, 129, 0.3);
  border: solid 1px #839bb7;
  outline: none;
  -webkit-transition: border linear .2s, box-shadow linear .2s;
          transition: border linear .2s, box-shadow linear .2s;
}

/* line 585, ../sass/_typography.scss */
textarea.form-control {
  height: 100px;
  max-width: 100%;
}

/* line 589, ../sass/_typography.scss */
.selectBox {
  max-width: 100%;
  width: 100% !important;
}

/* line 593, ../sass/_typography.scss */
.has-feedback .form-control-feedback {
  height: 40px;
  line-height: 40px;
}

/* line 597, ../sass/_typography.scss */
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success .form-control-feedback {
  color: #738d00;
}

/* line 606, ../sass/_typography.scss */
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning .form-control-feedback {
  color: #f89406;
}

/* line 615, ../sass/_typography.scss */
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error .form-control-feedback {
  color: #c10841;
}

/* line 624, ../sass/_typography.scss */
.has-success .form-control {
  border-color: #738d00;
  -webkit-transition: box-shadow linear .2s;
          transition: box-shadow linear .2s;
}

/* line 628, ../sass/_typography.scss */
.has-warning .form-control {
  border-color: #f89406;
  -webkit-transition: box-shadow linear .2s;
          transition: box-shadow linear .2s;
}

/* line 632, ../sass/_typography.scss */
.has-error .form-control {
  border-color: #c10841;
  -webkit-transition: box-shadow linear .2s;
          transition: box-shadow linear .2s;
}

/* line 636, ../sass/_typography.scss */
.has-success .form-control:focus {
  border-color: #738d00;
  box-shadow: 0 0 10px rgba(115, 141, 0, 0.3);
}

/* line 640, ../sass/_typography.scss */
.has-warning .form-control:focus {
  border-color: #f89406;
  box-shadow: 0 0 10px rgba(248, 148, 6, 0.3);
}

/* line 644, ../sass/_typography.scss */
.has-error .form-control:focus {
  border-color: #c10841;
  box-shadow: 0 0 10px rgba(193, 8, 65, 0.3);
}

/* line 648, ../sass/_typography.scss */
.has-feedback label ~ .form-control-feedback {
  top: 25px;
}

/* line 651, ../sass/_typography.scss */
.radio,
.checkbox {
  font-weight: normal;
  margin: 0 0 8px;
  min-height: 18px;
  padding-left: 20px;
}

/* line 658, ../sass/_typography.scss */
.new-radio,
.new-checkbox {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  cursor: pointer;
  float: left;
  height: 15px;
  margin: 3px 0 0 -20px;
  position: absolute;
  width: 15px;
}

/* line 670, ../sass/_typography.scss */
.new-radio {
  border-radius: 7px;
  height: 14px;
  width: 14px;
}

/* line 675, ../sass/_typography.scss */
.new-radio input[type="radio"],
.new-checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
  cursor: pointer;
  height: 100%;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  vertical-align: top;
  width: 100%;
}

/* line 689, ../sass/_typography.scss */
.new-checkbox svg {
  display: none;
  height: 18px;
  margin: -3px 0 0 -2px;
  width: 18px;
}
/* line 695, ../sass/_typography.scss */
.new-checkbox svg polygon {
  fill: #1e1e1e;
}

/* line 701, ../sass/_typography.scss */
.new-checkbox svg polygon {
  fill: #1e1e1e;
}

/* line 704, ../sass/_typography.scss */
.new-checkbox.checked svg {
  display: block;
}

/* line 707, ../sass/_typography.scss */
.new-radio span {
  border-radius: 3px;
  display: block;
  height: 6px;
  margin: 3px 0 0 3px;
  width: 6px;
}

/* line 714, ../sass/_typography.scss */
.new-radio.checked span {
  background: #1e1e1e;
}

/* line 717, ../sass/_typography.scss */
.new-radio.disabled,
.new-checkbox.disabled {
  opacity: .5;
  filter: alpha(opacity=50);
}

/* line 722, ../sass/_typography.scss */
.form-search .radio,
.form-search .checkbox,
.form-inline .radio,
.form-inline .checkbox {
  padding-left: 20px;
}

/* line 728, ../sass/_typography.scss */
.form-search .radio input[type="radio"],
.form-search .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
  margin-left: -20px;
}

/* line 735, ../sass/_typography.scss */
.selectBox::-webkit-scrollbar,
.selectBox-dropdown-menu::-webkit-scrollbar {
  background: #f2f2f2;
  width: 10px;
}

/* line 740, ../sass/_typography.scss */
.selectBox::-webkit-scrollbar-thumb,
.selectBox-dropdown-menu::-webkit-scrollbar-thumb {
  border-width: 1px 1px 1px 2px;
  background-color: #839bb7;
  width: 5px;
}

/* line 746, ../sass/_typography.scss */
.selectBox {
  background: #fff;
  cursor: pointer;
  position: relative;
  text-decoration: none;
}

/* line 752, ../sass/_typography.scss */
.selectBox:hover,
.selectBox:focus,
button {
  outline: none;
  text-decoration: none;
}

/* line 758, ../sass/_typography.scss */
.selectBox-label {
  box-sizing: border-box;
  color: #555;
  display: block;
  max-width: 100%;
  padding: 0 25px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

/* line 769, ../sass/_typography.scss */
.selectBox-arrow {
  background: url("../img/svg/select.svg") 50% 50% no-repeat;
  border-radius: 0 3px 3px 0;
  height: 100%;
  left: auto;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
}

/* line 780, ../sass/_typography.scss */
.selectBox-dropdown-menu {
  background: #fff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 0 10px rgba(46, 84, 129, 0.3);
  border: solid 1px #839bb7;
  outline: none;
  -webkit-transition: border linear .2s, box-shadow linear .2s;
          transition: border linear .2s, box-shadow linear .2s;
  color: #5f5f5f;
  font-size: 12px;
  list-style: none;
  margin: 0;
  max-height: 117px;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}

/* line 800, ../sass/_typography.scss */
.selectBox-options li {
  display: block;
}

/* line 803, ../sass/_typography.scss */
.selectBox-options li a {
  color: #1e1e1e;
  cursor: pointer;
  display: block;
  line-height: 1.5;
  padding: 2px 2px 2px 16px;
  margin: 0;
  min-height: 23px;
  text-decoration: none;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}

/* line 814, ../sass/_typography.scss */
.selectBox-options li + li a {
  border-top: 1px solid #839bb7;
}

/* line 817, ../sass/_typography.scss */
.selectBox-options li a:hover {
  background: #f2f2f2;
  color: #111;
  text-decoration: none;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}

/* line 823, ../sass/_typography.scss */
.selectBox-options li.selectBox-selected a {
  background: #e9e9e9;
  color: #000;
  text-decoration: none;
}

/* line 828, ../sass/_typography.scss */
.selectBox-inline {
  overflow-y: auto;
  padding: 0;
  width: 100%;
}

/* line 833, ../sass/_typography.scss */
.selectBox-inline ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

/* line 839, ../sass/_typography.scss */
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
  cursor: default;
}

/* line 843, ../sass/_typography.scss */
fieldset[disabled] select,
fieldset[disabled] .selectBox,
fieldset[disabled] .selectBox .selectBox-label,
.selectBox.selectBox-disabled,
.selectBox.selectBox-disabled .selectBox-label {
  color: #999;
}

/* line 850, ../sass/_typography.scss */
fieldset[disabled] .selectBox .selectBox-arrow,
.selectBox.selectBox-disabled .selectBox-arrow {
  opacity: .3;
  filter: alpha(opacity=30);
}

/* line 855, ../sass/_typography.scss */
.selectBox.selectBox-disabled:focus {
  border-color: #e1e1e1;
  box-shadow: 0 1px #fff, inset 0 1px 4px rgba(0, 0, 0, 0.15);
}

/* line 859, ../sass/_typography.scss */
.form-inline .control-label,
.form-inline .form-control,
.form-horizontal .form-group .form-control {
  margin-bottom: 0;
}

/* line 865, ../sass/_typography.scss */
.input-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  border-radius: 4px;
  font-size: 18px;
  height: 46px;
  line-height: 1.33;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* line 876, ../sass/_typography.scss */
.input-lg-selectBox-dropdown-menu {
  border-radius: 0 0 4px 4px;
}

/* line 879, ../sass/_typography.scss */
.input-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  border-radius: 2px;
  font-size: 12px;
  height: 30px;
  line-height: 1.5;
  min-height: inherit !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* line 891, ../sass/_typography.scss */
.input-sm-selectBox-dropdown-menu {
  border-radius: 0 0 2px 2px;
}

/* IMG */
/* line 895, ../sass/_typography.scss */
.alignnone {
  margin: 0 0 25px;
}

/* line 898, ../sass/_typography.scss */
.aligncenter {
  clear: both;
  margin: 0 auto 25px;
  text-align: center;
}

/* line 903, ../sass/_typography.scss */
.alignleft {
  float: left;
  margin: 0 25px 25px 0;
}

/* line 907, ../sass/_typography.scss */
.alignright {
  float: right;
  margin: 0 0 25px 25px;
}

/* line 911, ../sass/_typography.scss */
.caption {
  color: #7f7f7f;
  display: inline-block;
  font-size: 12px;
  font-style: italic;
  line-height: 18px;
  margin-bottom: 22px;
  max-width: 100%;
}
/* line 920, ../sass/_typography.scss */
.caption img {
  display: block;
  margin: 0 auto 10px;
}

/* line 925, ../sass/_typography.scss */
.img-rounded, .img-thumbnail.img-rounded img,
.img-thumbnail.img-rounded .bg-images, .img-thumbnail.img-circle img,
.img-thumbnail.img-circle .bg-images {
  border-radius: 4px;
}

/* line 928, ../sass/_typography.scss */
.img-thumbnail {
  border-radius: 0;
  display: inline-block;
  line-height: 0;
  position: relative;
  -webkit-transition: opacity .2s ease-in;
          transition: opacity .2s ease-in;
  width: auto;
}
/* line 936, ../sass/_typography.scss */
.img-thumbnail:hover {
  opacity: .8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s ease-out;
          transition: opacity .2s ease-out;
}
/* line 941, ../sass/_typography.scss */
.img-thumbnail:hover .bg-images {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  -webkit-transition: all .2s ease-out;
          transition: all .2s ease-out;
}
/* line 947, ../sass/_typography.scss */
.img-thumbnail:hover .bg-images i:before {
  color: #000;
  font-size: 30px;
  -webkit-transition: all .2s ease-out;
          transition: all .2s ease-out;
}
/* line 955, ../sass/_typography.scss */
.img-thumbnail.lightbox:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
/* line 959, ../sass/_typography.scss */
.img-thumbnail .bg-images {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all .2s ease-out;
          transition: all .2s ease-out;
}
/* line 967, ../sass/_typography.scss */
.img-thumbnail .bg-images i {
  margin: 9px 0 0 9px;
}
/* line 970, ../sass/_typography.scss */
.img-thumbnail .bg-images i:before {
  color: #fff;
  font-size: 20px;
  -webkit-transition: all .2s ease-out;
          transition: all .2s ease-out;
}
/* line 977, ../sass/_typography.scss */
.img-thumbnail.img-rounded, .img-thumbnail.img-rounded img.img-thumbnail,
.img-thumbnail.img-rounded .img-thumbnail.bg-images, .img-thumbnail.img-circle img.img-thumbnail,
.img-thumbnail.img-circle .img-thumbnail.bg-images {
  border-radius: 4px;
}
/* line 985, ../sass/_typography.scss */
.img-thumbnail.img-circle {
  border-radius: 50%;
}
/* line 988, ../sass/_typography.scss */
.img-thumbnail.img-circle img,
.img-thumbnail.img-circle .bg-images {
  border-radius: 50%;
}
/* line 993, ../sass/_typography.scss */
.img-thumbnail.img-circle .bg-images i {
  margin: 17% 0 0 17%;
}

/* line 998, ../sass/_typography.scss */
.fancybox-wrap {
  -webkit-overflow-scrolling: touch;
}

/* Alert */
/* line 1002, ../sass/_typography.scss */
.alert {
  min-height: 34px;
  margin-bottom: 22px;
  overflow: hidden;
  position: relative;
  text-shadow: none;
  -webkit-transition: opacity .3s linear;
          transition: opacity .3s linear;
}
/* line 1010, ../sass/_typography.scss */
.alert .alert-link {
  color: #fff;
  color: rgba(255, 255, 255, 0.7);
  -webkit-transition: color .2s linear;
          transition: color .2s linear;
}
/* line 1015, ../sass/_typography.scss */
.alert .alert-link:hover {
  color: #fff;
  text-decoration: none;
}
/* line 1020, ../sass/_typography.scss */
.alert .close {
  color: #fff;
  line-height: 22px;
  position: absolute;
  right: 10px;
  top: 9px;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 1028, ../sass/_typography.scss */
.alert .close:hover {
  color: #fff;
  opacity: 0.8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 1035, ../sass/_typography.scss */
.alert .alert-icon {
  display: block;
  float: left;
  margin: 0 20px 0 0;
}
/* line 1040, ../sass/_typography.scss */
.alert .alert-icon:before {
  font-size: 34px;
}
/* line 1044, ../sass/_typography.scss */
.alert.alert-default {
  background-color: #555;
  border: 1px solid #555;
  color: #fff;
}
/* line 1049, ../sass/_typography.scss */
.alert.alert-black {
  background-color: #1e1e1e;
  border: 1px solid #1e1e1e;
  color: #fff;
}
/* line 1054, ../sass/_typography.scss */
.alert.alert-border {
  background: none;
  color: #1e1e1e;
}
/* line 1058, ../sass/_typography.scss */
.alert.alert-border .close {
  color: #1e1e1e;
}

/* Tooltip */
/* line 1064, ../sass/_typography.scss */
.tooltip.left {
  margin-left: -12px;
}

/* line 1067, ../sass/_typography.scss */
#tooltip {
  border: 1px solid #e1e1e1;
  background-color: #f7f7f7;
  padding: 5px;
  position: absolute;
  z-index: 3000;
}
/* line 1074, ../sass/_typography.scss */
#tooltip h3,
#tooltip div {
  margin: 0;
}

/* line 1087, ../sass/_typography.scss */
.text-warning + .tooltip .tooltip-inner {
  background-color: #f89406;
}
/* line 1091, ../sass/_typography.scss */
.text-warning + .tooltip.top .tooltip-arrow {
  border-top-color: #f89406;
}
/* line 1096, ../sass/_typography.scss */
.text-warning + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #f89406;
}
/* line 1101, ../sass/_typography.scss */
.text-warning + .tooltip.left .tooltip-arrow {
  border-left-color: #f89406;
}
/* line 1106, ../sass/_typography.scss */
.text-warning + .tooltip.right .tooltip-arrow {
  border-right-color: #f89406;
}

/* line 1087, ../sass/_typography.scss */
.text-error + .tooltip .tooltip-inner {
  background-color: #c10841;
}
/* line 1091, ../sass/_typography.scss */
.text-error + .tooltip.top .tooltip-arrow {
  border-top-color: #c10841;
}
/* line 1096, ../sass/_typography.scss */
.text-error + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #c10841;
}
/* line 1101, ../sass/_typography.scss */
.text-error + .tooltip.left .tooltip-arrow {
  border-left-color: #c10841;
}
/* line 1106, ../sass/_typography.scss */
.text-error + .tooltip.right .tooltip-arrow {
  border-right-color: #c10841;
}

/* line 1087, ../sass/_typography.scss */
.text-danger + .tooltip .tooltip-inner {
  background-color: #c10841;
}
/* line 1091, ../sass/_typography.scss */
.text-danger + .tooltip.top .tooltip-arrow {
  border-top-color: #c10841;
}
/* line 1096, ../sass/_typography.scss */
.text-danger + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #c10841;
}
/* line 1101, ../sass/_typography.scss */
.text-danger + .tooltip.left .tooltip-arrow {
  border-left-color: #c10841;
}
/* line 1106, ../sass/_typography.scss */
.text-danger + .tooltip.right .tooltip-arrow {
  border-right-color: #c10841;
}

/* line 1087, ../sass/_typography.scss */
.text-info + .tooltip .tooltip-inner {
  background-color: #0098ca;
}
/* line 1091, ../sass/_typography.scss */
.text-info + .tooltip.top .tooltip-arrow {
  border-top-color: #0098ca;
}
/* line 1096, ../sass/_typography.scss */
.text-info + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #0098ca;
}
/* line 1101, ../sass/_typography.scss */
.text-info + .tooltip.left .tooltip-arrow {
  border-left-color: #0098ca;
}
/* line 1106, ../sass/_typography.scss */
.text-info + .tooltip.right .tooltip-arrow {
  border-right-color: #0098ca;
}

/* line 1087, ../sass/_typography.scss */
.text-success + .tooltip .tooltip-inner {
  background-color: #738d00;
}
/* line 1091, ../sass/_typography.scss */
.text-success + .tooltip.top .tooltip-arrow {
  border-top-color: #738d00;
}
/* line 1096, ../sass/_typography.scss */
.text-success + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #738d00;
}
/* line 1101, ../sass/_typography.scss */
.text-success + .tooltip.left .tooltip-arrow {
  border-left-color: #738d00;
}
/* line 1106, ../sass/_typography.scss */
.text-success + .tooltip.right .tooltip-arrow {
  border-right-color: #738d00;
}

/* Popover */
/* line 1114, ../sass/_typography.scss */
.popover {
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

/* List Group */
/* line 1119, ../sass/_typography.scss */
a.list-group-item,
a.list-group-item:hover,
a.list-group-item:focus {
  -webkit-transition: background-color .3s linear, color .3s linear;
          transition: background-color .3s linear, color .3s linear;
}

/* line 1131, ../sass/_typography.scss */
.list-group-item-warning {
  background-color: #f89406;
  border: 0 none;
  color: #fff;
}

/* line 1136, ../sass/_typography.scss */
a.list-group-item-warning {
  color: #fff;
}
/* line 1139, ../sass/_typography.scss */
a.list-group-item-warning:hover, a.list-group-item-warning:focus {
  background-color: #faa937;
  color: rgba(255, 255, 255, 0.7);
}

/* line 1131, ../sass/_typography.scss */
.list-group-item-danger {
  background-color: #c10841;
  border: 0 none;
  color: #fff;
}

/* line 1136, ../sass/_typography.scss */
a.list-group-item-danger {
  color: #fff;
}
/* line 1139, ../sass/_typography.scss */
a.list-group-item-danger:hover, a.list-group-item-danger:focus {
  background-color: #f20a51;
  color: rgba(255, 255, 255, 0.7);
}

/* line 1131, ../sass/_typography.scss */
.list-group-item-info {
  background-color: #0098ca;
  border: 0 none;
  color: #fff;
}

/* line 1136, ../sass/_typography.scss */
a.list-group-item-info {
  color: #fff;
}
/* line 1139, ../sass/_typography.scss */
a.list-group-item-info:hover, a.list-group-item-info:focus {
  background-color: #00befd;
  color: rgba(255, 255, 255, 0.7);
}

/* line 1131, ../sass/_typography.scss */
.list-group-item-success {
  background-color: #738d00;
  border: 0 none;
  color: #fff;
}

/* line 1136, ../sass/_typography.scss */
a.list-group-item-success {
  color: #fff;
}
/* line 1139, ../sass/_typography.scss */
a.list-group-item-success:hover, a.list-group-item-success:focus {
  background-color: #9dc000;
  color: rgba(255, 255, 255, 0.7);
}

/* line 1146, ../sass/_typography.scss */
.list-group-item .badge {
  margin-top: 2px;
}

/* Tables */
/* line 1150, ../sass/_typography.scss */
.table {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 22px;
  text-align: center;
}

/* line 1156, ../sass/_typography.scss */
th {
  text-align: center;
}

/* line 1159, ../sass/_typography.scss */
.table.text-left,
.table.text-left th:not(.text-center) {
  text-align: left;
}

/* line 1163, ../sass/_typography.scss */
.table.text-right,
.table.text-right th:not(.text-center) {
  text-align: right;
}

/* line 1167, ../sass/_typography.scss */
.table th,
.table td,
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  line-height: 18px;
}

/* line 1177, ../sass/_typography.scss */
.table > thead > tr > th {
  border-bottom-width: 1px;
  font-size: 13px;
}

/* line 1181, ../sass/_typography.scss */
.table-striped thead th {
  background-color: #f7f7f7;
}

/* line 1184, ../sass/_typography.scss */
.table-striped tbody > tr:nth-child(odd) > td,
.table-striped tbody > tr:nth-child(odd) > th {
  background: none;
}

/* line 1188, ../sass/_typography.scss */
.table-bordered {
  border-radius: 0;
}

/* line 1191, ../sass/_typography.scss */
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 1px;
}

/* line 1195, ../sass/_typography.scss */
.table-hover tbody tr > td,
.table-hover tbody tr > th {
  -webkit-transition: all 0.4s linear;
          transition: all 0.4s linear;
}

/* line 1199, ../sass/_typography.scss */
.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
  background-color: #fffdee;
  -webkit-transition: all 0.4s linear;
          transition: all 0.4s linear;
}

/* line 1204, ../sass/_typography.scss */
.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th,
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th,
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th,
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  color: #fff;
}

/* Quotes */
/* line 1255, ../sass/_typography.scss */
blockquote {
  padding: 0 0 0 20px;
  margin: 0 0 22px;
  border-left: 2px solid #e1e1e1;
}
/* line 1260, ../sass/_typography.scss */
blockquote.pull-right {
  border-left: 0;
  border-right: 2px solid #e1e1e1;
  float: right;
  padding-left: 0;
  padding-right: 20px;
}
/* line 1267, ../sass/_typography.scss */
blockquote p {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-style: italic;
  font-size: 16px;
  line-height: 1.625;
  margin-bottom: 0;
}
/* line 1274, ../sass/_typography.scss */
blockquote small {
  line-height: 26px;
}

/* Progress */
/* line 1279, ../sass/_typography.scss */
.progress {
  height: 40px;
}

/* line 1282, ../sass/_typography.scss */
.progress-bar {
  font-size: 14px;
  -webkit-filter: none;
          filter: none;
  box-shadow: none;
  line-height: 40px;
  text-align: left;
  text-indent: 10px;
  text-shadow: none;
  -webkit-transition: background 0.2s linear;
          transition: background 0.2s linear;
}

/* line 1292, ../sass/_typography.scss */
.progress.border-radius {
  border-radius: 5px;
}

/* line 1297, ../sass/_typography.scss */
.progress[data-appear-progress-animation] .progress-bar {
  text-indent: -100%;
}

/* line 1301, ../sass/_typography.scss */
.progress.hover .progress-bar:hover {
  background: #1e1e1e;
}
/* line 1304, ../sass/_typography.scss */
.progress.hover .progress-bar-info:hover {
  background: #0098ca;
}
/* line 1307, ../sass/_typography.scss */
.progress.hover .progress-bar-success:hover {
  background: #738d00;
}
/* line 1310, ../sass/_typography.scss */
.progress.hover .progress-bar-warning:hover {
  background: #f89406;
}
/* line 1313, ../sass/_typography.scss */
.progress.hover .progress-bar-danger:hover {
  background: #c10841;
}

/* line 1317, ../sass/_typography.scss */
.progress-circular {
  position: relative;
  text-align: center;
}
/* line 1321, ../sass/_typography.scss */
.progress-circular > div {
  display: inline-block;
  vertical-align: top;
}
/* line 1325, ../sass/_typography.scss */
.progress-circular input.knob {
  border: 0 none;
  box-shadow: none;
  color: #7f7f7f;
  font-size: 0;
  font-style: italic;
  font-weight: normal !important;
}

/* Pagination */
/* line 1335, ../sass/_typography.scss */
.pagination {
  margin: 20px 0;
}
/* line 1339, ../sass/_typography.scss */
.pagination > li > a,
.pagination > li > span {
  border: 0 none;
  border-radius: 2px;
  float: left;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  margin: 0 5px 0 0;
  min-width: 6px;
  padding: 0 9px;
  text-align: center;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 1355, ../sass/_typography.scss */
.pagination > li:first-child > a,
.pagination > li:first-child > span, .pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-left-width: 0;
  border-radius: 2px;
  font-size: 15px;
  font-weight: normal;
}
/* line 1364, ../sass/_typography.scss */
.pagination + .pagination-text {
  color: #999;
  font-size: 12px;
  float: right;
  line-height: 24px;
  vertical-align: top;
}
/* line 1371, ../sass/_typography.scss */
.pagination.pagination-lg > li > a, .pagination.pagination-lg > li > span {
  font-size: 16px;
  line-height: 28px;
  padding: 4px 14px;
}
/* line 1377, ../sass/_typography.scss */
.pagination.pagination-sm > li > a, .pagination.pagination-sm > li > span {
  font-size: 10px;
  line-height: 20px;
  padding: 0 7px;
}

/* line 1384, ../sass/_typography.scss */
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span,
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

/* line 1391, ../sass/_typography.scss */
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span,
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* Modal */
/* line 1399, ../sass/_typography.scss */
.modal {
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
/* line 1404, ../sass/_typography.scss */
.modal .close {
  font-size: 26px;
  position: absolute;
  right: 20px;
  top: 20px;
}
/* line 1410, ../sass/_typography.scss */
.modal .close span {
  line-height: 18px;
  margin-right: 5px;
  margin-top: 0;
  text-transform: uppercase;
}
/* line 1417, ../sass/_typography.scss */
.modal.modal-center .modal-dialog {
  -webkit-transform: translate(0, 0%) !important;
      -ms-transform: translate(0, 0%) !important;
          transform: translate(0, 0%) !important;
}

/* line 1421, ../sass/_typography.scss */
.modal-header {
  border: 0 none;
  padding: 30px 20px 0;
}
/* line 1425, ../sass/_typography.scss */
.modal-header .title-box {
  margin-bottom: 20px;
}
/* line 1428, ../sass/_typography.scss */
.modal-header .title-box .title {
  margin: 0 0 12px 0;
  padding: 0;
}

/* line 1434, ../sass/_typography.scss */
.modal-body {
  padding: 0 20px 20px;
}

/* line 1437, ../sass/_typography.scss */
.modal-footer {
  background-color: #f7f7f7;
  border-top: 1px solid #e1e1e1;
  border-radius: 0 0 3px 3px;
  box-shadow: inset 0 1px 0 #ffffff;
  padding: 20px;
}

/* Tabs */
/* line 1445, ../sass/_typography.scss */
.nav > li > a:hover,
.nav > li > a:focus {
  background-color: #f7f7f7;
  -webkit-transition: background-color .2s linear;
          transition: background-color .2s linear;
}

/* line 1450, ../sass/_typography.scss */
.nav-tabs {
  margin-bottom: 0;
  margin-left: 0;
}
/* line 1455, ../sass/_typography.scss */
.nav-tabs > li > a {
  border-radius: 2px 2px 0 0;
  color: #1e1e1e;
  font-size: 12px;
  padding: 10px 20px 11px;
  -webkit-transition: background-color .2s linear;
          transition: background-color .2s linear;
}
/* line 1462, ../sass/_typography.scss */
.nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
  border-color: #f7f7f7 #f7f7f7 #e1e1e1;
}
/* line 1469, ../sass/_typography.scss */
.nav-tabs > .active > a,
.nav-tabs > .active > a:hover,
.nav-tabs > .active > a:focus {
  border: 1px solid #e1e1e1;
  border-bottom-color: transparent;
  color: #1e1e1e;
  font-weight: bold;
}
/* line 1477, ../sass/_typography.scss */
.nav-tabs > .active.dropdown > a {
  z-index: 911;
}

/* line 1482, ../sass/_typography.scss */
.tab-content > .tab-pane,
.pill-content > .pill-pane {
  padding: 36px 0 0;
}

/* line 1486, ../sass/_typography.scss */
.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
  margin: 0;
}

/* line 1491, ../sass/_typography.scss */
.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 110px;
  margin-right: 0;
  margin-bottom: 3px;
}

/* line 1498, ../sass/_typography.scss */
.tabs-left > .nav-tabs {
  border: 0 none;
  border-right: 1px solid #ddd;
  float: left;
  margin: 0 -1px 0 0;
}
/* line 1504, ../sass/_typography.scss */
.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  border-radius: 4px 0 0 4px;
}
/* line 1508, ../sass/_typography.scss */
.tabs-left > .nav-tabs > li > a:hover, .tabs-left > .nav-tabs > li > a:focus {
  border-color: #f7f7f7 #ddd #f7f7f7 #f7f7f7;
}
/* line 1514, ../sass/_typography.scss */
.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
  border-color: #ddd transparent #ddd #ddd;
}
/* line 1521, ../sass/_typography.scss */
.tabs-left > .tab-content {
  border-left: 1px solid #ddd;
  margin: 0;
  overflow: auto;
}
/* line 1526, ../sass/_typography.scss */
.tabs-left > .tab-content > .tab-pane {
  padding: 0 0 0 20px;
}

/* line 1532, ../sass/_typography.scss */
.tabs-right > .nav-tabs {
  border: 0 none;
  border-left: 1px solid #ddd;
  float: right;
  margin: 0 0 0 -1px;
}
/* line 1539, ../sass/_typography.scss */
.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  border-radius: 0 4px 4px 0;
}
/* line 1543, ../sass/_typography.scss */
.tabs-right > .nav-tabs > li > a:hover, .tabs-right > .nav-tabs > li > a:focus {
  border-color: #f7f7f7 #f7f7f7 #f7f7f7 #ddd;
}
/* line 1550, ../sass/_typography.scss */
.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
  border-color: #ddd #ddd #ddd transparent;
}
/* line 1557, ../sass/_typography.scss */
.tabs-right > .tab-content {
  border-right: 1px solid #ddd;
  margin: 0;
  overflow: auto;
}
/* line 1562, ../sass/_typography.scss */
.tabs-right > .tab-content > .tab-pane {
  padding: 0 20px 0 0;
}

/* Accordion */
/* line 1568, ../sass/_typography.scss */
.panel {
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 22px;
}

/* line 1573, ../sass/_typography.scss */
.panel-heading {
  border: 0 none;
}

/* line 1576, ../sass/_typography.scss */
.panel-title {
  font-size: 14px;
}

/* line 1579, ../sass/_typography.scss */
.panel-footer {
  border: 0;
}

/* line 1582, ../sass/_typography.scss */
.list-group-item {
  padding-left: 20px;
  padding-right: 20px;
}

/* line 1586, ../sass/_typography.scss */
.panel-group {
  margin-bottom: 22px;
}
/* line 1589, ../sass/_typography.scss */
.panel-group .panel {
  border: 0 none;
  border-radius: 0;
  margin-bottom: 0;
}
/* line 1594, ../sass/_typography.scss */
.panel-group .panel .panel-heading {
  background-color: inherit;
  padding: 0;
  position: relative;
}
/* line 1600, ../sass/_typography.scss */
.panel-group .panel .panel-heading > .panel-title:before {
  background: rgba(255, 255, 255, 0.4);
  bottom: 0;
  content: "";
  display: block;
  left: 45px;
  position: absolute;
  top: 0;
  width: 1px;
  z-index: 1;
}
/* line 1611, ../sass/_typography.scss */
.panel-group .panel .panel-heading > .panel-title > a {
  background-color: #f2f2f2;
  display: block;
  line-height: 22px;
  padding: 9px 9px 9px 60px;
  text-decoration: none;
  -webkit-transition: background-color .2s linear;
          transition: background-color .2s linear;
}
/* line 1619, ../sass/_typography.scss */
.panel-group .panel .panel-heading > .panel-title > a:before, .panel-group .panel .panel-heading > .panel-title > a:after {
  background: #1e1e1e;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  margin: 19px 0 0 15px;
  position: absolute;
  top: 0;
  width: 16px;
}
/* line 1631, ../sass/_typography.scss */
.panel-group .panel .panel-heading > .panel-title > a:after {
  height: 16px;
  margin: 12px 0 0 22px;
  width: 2px;
}
/* line 1636, ../sass/_typography.scss */
.panel-group .panel .panel-heading > .panel-title > a:hover {
  background-color: #e9e9e9;
}
/* line 1641, ../sass/_typography.scss */
.panel-group .panel .panel-heading + .panel-collapse .panel-body {
  border-top: 0 none;
}
/* line 1648, ../sass/_typography.scss */
.panel-group .panel.active .panel-heading > .panel-title:before {
  background: rgba(255, 255, 255, 0.2);
}
/* line 1651, ../sass/_typography.scss */
.panel-group .panel.active .panel-heading > .panel-title > a,
.panel-group .panel.active .panel-heading > .panel-title > a:hover {
  background-color: #1e1e1e;
  color: #fff;
}
/* line 1656, ../sass/_typography.scss */
.panel-group .panel.active .panel-heading > .panel-title > a:before, .panel-group .panel.active .panel-heading > .panel-title > a:after,
.panel-group .panel.active .panel-heading > .panel-title > a:hover:before,
.panel-group .panel.active .panel-heading > .panel-title > a:hover:after {
  background: #fff;
}
/* line 1660, ../sass/_typography.scss */
.panel-group .panel.active .panel-heading > .panel-title > a:after,
.panel-group .panel.active .panel-heading > .panel-title > a:hover:after {
  display: none;
}
/* line 1682, ../sass/_typography.scss */
.panel-group .panel.panel-primary .panel-heading > .panel-title > a {
  background-color: #037ac5;
  color: #fff;
}
/* line 1686, ../sass/_typography.scss */
.panel-group .panel.panel-primary .panel-heading > .panel-title > a:hover {
  background-color: rgba(3, 122, 197, 0.9);
}
/* line 1689, ../sass/_typography.scss */
.panel-group .panel.panel-primary .panel-heading > .panel-title > a:before, .panel-group .panel.panel-primary .panel-heading > .panel-title > a:after {
  background-color: #fff;
}
/* line 1682, ../sass/_typography.scss */
.panel-group .panel.panel-warning .panel-heading > .panel-title > a {
  background-color: #f89406;
  color: #fff;
}
/* line 1686, ../sass/_typography.scss */
.panel-group .panel.panel-warning .panel-heading > .panel-title > a:hover {
  background-color: rgba(248, 148, 6, 0.9);
}
/* line 1689, ../sass/_typography.scss */
.panel-group .panel.panel-warning .panel-heading > .panel-title > a:before, .panel-group .panel.panel-warning .panel-heading > .panel-title > a:after {
  background-color: #fff;
}
/* line 1682, ../sass/_typography.scss */
.panel-group .panel.panel-danger .panel-heading > .panel-title > a {
  background-color: #c10841;
  color: #fff;
}
/* line 1686, ../sass/_typography.scss */
.panel-group .panel.panel-danger .panel-heading > .panel-title > a:hover {
  background-color: rgba(193, 8, 65, 0.9);
}
/* line 1689, ../sass/_typography.scss */
.panel-group .panel.panel-danger .panel-heading > .panel-title > a:before, .panel-group .panel.panel-danger .panel-heading > .panel-title > a:after {
  background-color: #fff;
}
/* line 1682, ../sass/_typography.scss */
.panel-group .panel.panel-info .panel-heading > .panel-title > a {
  background-color: #0098ca;
  color: #fff;
}
/* line 1686, ../sass/_typography.scss */
.panel-group .panel.panel-info .panel-heading > .panel-title > a:hover {
  background-color: rgba(0, 152, 202, 0.9);
}
/* line 1689, ../sass/_typography.scss */
.panel-group .panel.panel-info .panel-heading > .panel-title > a:before, .panel-group .panel.panel-info .panel-heading > .panel-title > a:after {
  background-color: #fff;
}
/* line 1682, ../sass/_typography.scss */
.panel-group .panel.panel-success .panel-heading > .panel-title > a {
  background-color: #738d00;
  color: #fff;
}
/* line 1686, ../sass/_typography.scss */
.panel-group .panel.panel-success .panel-heading > .panel-title > a:hover {
  background-color: rgba(115, 141, 0, 0.9);
}
/* line 1689, ../sass/_typography.scss */
.panel-group .panel.panel-success .panel-heading > .panel-title > a:before, .panel-group .panel.panel-success .panel-heading > .panel-title > a:after {
  background-color: #fff;
}
/* line 1698, ../sass/_typography.scss */
.panel-group .panel.e-hidden {
  margin: 0;
  overflow: hidden;
}
/* line 1703, ../sass/_typography.scss */
.panel-group .panel-body {
  padding: 10px 0 10px 1px;
}
/* line 1706, ../sass/_typography.scss */
.panel-group.panel-bg .panel-body {
  background: #f7f7f7;
  padding-left: 20px;
  padding-right: 20px;
}
/* line 1711, ../sass/_typography.scss */
.panel-group.one-open .panel.active .panel-heading > .panel-title > a {
  cursor: default;
}

/******************************************************************************
  Helper Classes
*******************************************************************************/
/* line 4, ../sass/_helper-classes.scss */
.close {
  float: none;
  font-size: 16px;
  font-weight: normal;
  line-height: 16px;
  color: #7f7f7f;
  position: relative;
  text-shadow: none;
  opacity: 1;
  filter: alpha(opacity=100);
  vertical-align: top;
  -webkit-transition: color .2s linear;
          transition: color .2s linear;
}
/* line 17, ../sass/_helper-classes.scss */
.close:hover {
  color: #1e1e1e;
  opacity: 1;
  filter: alpha(opacity=100);
}
/* line 22, ../sass/_helper-classes.scss */
.close span {
  display: inline-block;
  font-size: 12px;
  font-family: Arial;
  line-height: 12px;
  margin-top: 2px;
  vertical-align: top;
}

/* line 31, ../sass/_helper-classes.scss */
a.edit {
  color: #707070;
  font-size: 12px;
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 37, ../sass/_helper-classes.scss */
a.edit:hover {
  color: #707070;
  opacity: 0.8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 43, ../sass/_helper-classes.scss */
a.edit i:before {
  font-style: italic;
  line-height: 9px;
  margin: 0;
}

/* line 49, ../sass/_helper-classes.scss */
.rating-box {
  background-image: url("../img/svg/rating.svg"), none;
  background-repeat: no-repeat;
  background-position: 0 0;
  height: 12px;
  width: 73px;
}
/* line 56, ../sass/_helper-classes.scss */
.rating-box .rating {
  height: 12px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 73px;
}
/* line 63, ../sass/_helper-classes.scss */
.rating-box .rating * {
  vertical-align: top;
}
/* line 66, ../sass/_helper-classes.scss */
.pricing-info .rating-box .rating svg polygon {
  fill: #0098ca;
}
/* line 69, ../sass/_helper-classes.scss */
.pricing-success .rating-box .rating svg polygon {
  fill: #738d00;
}
/* line 72, ../sass/_helper-classes.scss */
.pricing-warning .rating-box .rating svg polygon {
  fill: #f89406;
}
/* line 75, ../sass/_helper-classes.scss */
.pricing-error .rating-box .rating svg polygon, .pricing-danger .rating-box .rating svg polygon {
  fill: #c10841;
}

/* line 81, ../sass/_helper-classes.scss */
.reviews-box {
  color: #7f7f7f;
  font-size: 12px;
  line-height: 14px;
}
/* line 86, ../sass/_helper-classes.scss */
.reviews-box .rating-box {
  float: left;
  margin: 0 20px 0 0;
}
/* line 90, ../sass/_helper-classes.scss */
.reviews-box .separator {
  margin: 0 4px;
}
/* line 93, ../sass/_helper-classes.scss */
.reviews-box .add-review {
  color: #7f7f7f;
  text-decoration: none;
}
/* line 97, ../sass/_helper-classes.scss */
.reviews-box .add-review:hover {
  opacity: .8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

/* line 105, ../sass/_helper-classes.scss */
.display-t {
  display: table;
}

/* line 108, ../sass/_helper-classes.scss */
.display-tc {
  display: table-cell;
}

/* line 111, ../sass/_helper-classes.scss */
.slim {
  font-weight: normal;
}

/* line 114, ../sass/_helper-classes.scss */
.border-radius {
  border-radius: 5px;
}

/* line 117, ../sass/_helper-classes.scss */
.bg {
  background-color: #f2f2f2;
}

/* line 120, ../sass/_helper-classes.scss */
.border {
  border-width: 1px;
}

/* line 123, ../sass/_helper-classes.scss */
.width-auto .col-md-1,
.width-auto .col-md-2,
.width-auto .col-md-3,
.width-auto .col-md-4,
.width-auto .col-md-5,
.width-auto .col-md-6,
.width-auto .col-md-7,
.width-auto .col-md-8,
.width-auto .col-md-9,
.width-auto .col-md-10,
.width-auto .col-md-11,
.width-auto .col-md-12 {
  float: left;
  width: auto;
}

/* line 138, ../sass/_helper-classes.scss */
.margin-center {
  margin: 0 auto;
}

/* line 141, ../sass/_helper-classes.scss */
.bottom-padding {
  margin-bottom: 80px;
}

/* line 144, ../sass/_helper-classes.scss */
.bottom-padding-mini {
  margin-bottom: 40px;
}

/* line 147, ../sass/_helper-classes.scss */
.bottom-padding.last {
  margin-bottom: 0;
}

/* line 150, ../sass/_helper-classes.scss */
.no-top-padding {
  padding-top: 0 !important;
}

/* rotation */
/* line 155, ../sass/_helper-classes.scss */
.rotation {
  background: none;
  -webkit-perspective: 600px;
  perspective: 600px;
}
/* line 161, ../sass/_helper-classes.scss */
.rotation.product, .rotation.employee {
  overflow: visible;
}
/* line 165, ../sass/_helper-classes.scss */
.rotation .default,
.rotation .front-end {
  overflow: hidden;
  position: relative;
  -webkit-transform: rotateX(0deg) rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-transform: rotateX(0deg) rotateY(0deg);
  -moz-transform-style: preserve-3d;
  -moz-backface-visibility: hidden;
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  -webkit-transition: all .6s ease-in-out;
  transition: all .6s ease-in-out;
  z-index: 24;
}
/* line 181, ../sass/_helper-classes.scss */
.rotation .default {
  background: #f7f7f7;
  border-radius: 3px;
}
/* line 185, ../sass/_helper-classes.scss */
.rotation.product .product-hover,
.rotation .back-end {
  display: block;
  height: inherit;
  -webkit-transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  /*-webkit-backface-visibility: hidden;*/
  -moz-transform: rotateY(-180deg);
  -moz-transform-style: preserve-3d;
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
  -webkit-transition: all .6s ease-in-out;
  transition: all .6s ease-in-out;
  width: 100%;
  z-index: 13;
}
/* line 202, ../sass/_helper-classes.scss */
.rotation.hover .default,
.rotation.hover .front-end {
  z-index: 35;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transition-delay: .2s;
  transition-delay: .2s;
}
/* line 212, ../sass/_helper-classes.scss */
.rotation.hover .product-hover,
.rotation.hover .back-end {
  z-index: 46;
  -webkit-transform: rotateX(0deg) rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transition-delay: .2s;
  transition-delay: .2s;
}

/* line 224, ../sass/_helper-classes.scss */
.btn.dropdown-toggle,
.btn.dropdown-toggle:hover,
.btn.dropdown-toggle:focus {
  outline: none;
}

/* line 229, ../sass/_helper-classes.scss */
.btn-group.btn-select {
  color: #7f7f7f;
  font-size: 12px;
  vertical-align: top;
}
/* line 234, ../sass/_helper-classes.scss */
.btn-group.btn-select .dropdown-toggle {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid transparent;
  box-shadow: none;
  color: #7f7f7f;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  padding: 0 7px 3px;
  z-index: 2;
}
/* line 245, ../sass/_helper-classes.scss */
.btn-group.btn-select .btn.dropdown-toggle .caret {
  color: #fff;
  color: rgba(255, 255, 255, 0.5);
  margin-left: 0;
}
/* line 251, ../sass/_helper-classes.scss */
.btn-group.btn-select.open .btn.dropdown-toggle {
  background: #fff;
  border: 1px solid #ccc;
  border-bottom: 0 none;
  border-radius: 3px 3px 0 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  color: #7f7f7f;
}
/* line 259, ../sass/_helper-classes.scss */
.btn-group.btn-select.open .btn.dropdown-toggle:after {
  background: #fff;
  bottom: 0;
  content: "";
  display: block;
  height: 6px;
  left: 0px;
  margin: 0 0 -6px;
  position: absolute;
  right: 0;
  top: auto;
  z-index: 27;
  -webkit-animation: fadeIn 0.3s;
  animation: fadeIn 0.3s;
}
/* line 274, ../sass/_helper-classes.scss */
.btn-group.btn-select.open .btn.dropdown-toggle .caret {
  color: #777;
}
/* line 279, ../sass/_helper-classes.scss */
.btn-group.btn-select .dropdown-menu {
  border-radius: 0 3px 3px 3px;
  margin: 0;
  padding-top: 8px;
  z-index: 25;
}
/* line 285, ../sass/_helper-classes.scss */
.btn-group.btn-select .dropdown-menu > li > a {
  font-size: 12px;
  line-height: 22px;
  padding: 4px 6px;
}
/* line 290, ../sass/_helper-classes.scss */
.btn-group.btn-select .dropdown-menu > li > a:hover {
  background: #f2f2f2;
}
/* line 293, ../sass/_helper-classes.scss */
.btn-group.btn-select .dropdown-menu > li > a img {
  margin: 5px 9px 0 0;
  vertical-align: top;
}

/* line 300, ../sass/_helper-classes.scss */
.jslider .jslider-pointer {
  background: none;
  height: 12px;
  margin: 0 0 0 -4px;
  width: 8px;
}
/* line 306, ../sass/_helper-classes.scss */
.jslider .jslider-pointer svg {
  vertical-align: top;
}

/* line 310, ../sass/_helper-classes.scss */
.pagination-box {
  margin: 40px 0 0;
}

/* line 313, ../sass/_helper-classes.scss */
.pagination-box .pagination {
  margin: 0 10px 0 0;
}

/* line 317, ../sass/_helper-classes.scss */
.map-box iframe {
  border: 0 none;
  border-radius: 5px;
  width: 100%;
}
/* line 322, ../sass/_helper-classes.scss */
.map-box img {
  max-width: inherit;
}

/* line 326, ../sass/_helper-classes.scss */
.map-canvas {
  height: 400px;
}
/* line 329, ../sass/_helper-classes.scss */
.map-canvas * {
  border: 0 none;
}

/* line 333, ../sass/_helper-classes.scss */
.map-content {
  width: 200px;
}
/* line 336, ../sass/_helper-classes.scss */
.map-content .title {
  font-size: 16px;
  margin-bottom: 10px;
}

/* line 341, ../sass/_helper-classes.scss */
.caret {
  color: #777;
  border: 0 none;
  height: 6px;
  width: 9px;
}
/* line 347, ../sass/_helper-classes.scss */
.caret:before {
  content: "\f107";
  display: block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 6px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 6px;
  position: absolute;
  width: 9px;
}
/* line 360, ../sass/_helper-classes.scss */
.open .caret {
  color: #777;
}
/* line 363, ../sass/_helper-classes.scss */
.btn .caret {
  margin: -2px 0 0;
}
/* line 366, ../sass/_helper-classes.scss */
.btn-group .caret {
  color: #fff;
}
/* line 369, ../sass/_helper-classes.scss */
.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border: 0 none;
}
/* line 373, ../sass/_helper-classes.scss */
.dropup .caret:before, .navbar-fixed-bottom .dropdown .caret:before {
  content: "\f106";
}

/* line 378, ../sass/_helper-classes.scss */
.help-block {
  margin-top: -10px;
  margin-bottom: 15px;
}

/* line 382, ../sass/_helper-classes.scss */
.buttons-box .required {
  font-size: 11px;
}

/* Social */
/* line 387, ../sass/_helper-classes.scss */
.social .social-list {
  margin: 0 -4px;
}
/* line 390, ../sass/_helper-classes.scss */
.social .icon {
  background: #1e1e1e;
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  margin-left: 4px;
  margin-right: 4px;
  opacity: .35;
  filter: alpha(opacity=35);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
  text-align: center;
  width: 40px;
}
/* line 405, ../sass/_helper-classes.scss */
.social .icon:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 411, ../sass/_helper-classes.scss */
.social .icon-facebook {
  background: #3b5998;
}
/* line 414, ../sass/_helper-classes.scss */
.social .icon-twitter {
  background: #00bdec;
}
/* line 417, ../sass/_helper-classes.scss */
.social .icon-google {
  background: #d94a39;
}
/* line 420, ../sass/_helper-classes.scss */
.social .icon-linkedin {
  background: #1b92bd;
}

/* Filter */
/* line 425, ../sass/_helper-classes.scss */
.filter-elements-wrapper {
  margin: 0 auto;
}

/* line 428, ../sass/_helper-classes.scss */
.filter-buttons {
  display: block;
  margin-bottom: 30px;
}
/* line 432, ../sass/_helper-classes.scss */
.filter-buttons .dropdown-toggle {
  background: transparent;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  color: #1e1e1e;
  display: block;
  float: left;
  font-weight: bold;
  height: 38px;
  line-height: 38px;
  margin-right: 20px;
  padding: 0 10px 0 20px;
  -webkit-transition: opacity .2s ease-out;
          transition: opacity .2s ease-out;
}
/* line 446, ../sass/_helper-classes.scss */
.filter-buttons .dropdown-toggle .caret {
  margin: -2px 0 0 5px;
}
/* line 449, ../sass/_helper-classes.scss */
.filter-buttons .dropdown-toggle:hover {
  background: transparent;
  text-decoration: none;
  opacity: .7;
  filter: alpha(opacity=70);
  -webkit-transition: opacity .2s ease-out;
          transition: opacity .2s ease-out;
}
/* line 457, ../sass/_helper-classes.scss */
.filter-buttons .dropdown-menu {
  border: 1px solid #e1e1e1;
}
/* line 460, ../sass/_helper-classes.scss */
.filter-buttons .dropdown-menu .active {
  background-color: #f5f5f5;
}
/* line 465, ../sass/_helper-classes.scss */
.filter-buttons.open .dropdown-toggle {
  background: transparent;
}
/* line 470, ../sass/_helper-classes.scss */
.filter-buttons.white .dropdown-toggle,
.filter-buttons.white .dropdown-toggle .caret {
  color: #fff;
}

/* Isotop */
/* line 477, ../sass/_helper-classes.scss */
.isotope,
.isotope .isotope-item {
  -webkit-transition-duration: 0.8s;
  transition-duration: 0.8s;
}

/* line 485, ../sass/_helper-classes.scss */
.isotope {
  -webkit-transition-property: height, width;
  transition-property: height, width;
}

/* line 492, ../sass/_helper-classes.scss */
.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  transition-property: transform, opacity;
}

/* line 499, ../sass/_helper-classes.scss */
.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

/* line 508, ../sass/_helper-classes.scss */
.isotope-item {
  z-index: 2;
}

/* line 511, ../sass/_helper-classes.scss */
.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

/* line 515, ../sass/_helper-classes.scss */
.year-regulator {
  padding-top: 13px;
}
/* line 518, ../sass/_helper-classes.scss */
.year-regulator .label {
  color: inherit;
  display: table-cell;
  font-size: 12px;
  line-height: 14px;
  padding: 0 20px 0 0;
  text-align: left;
  vertical-align: middle;
}
/* line 527, ../sass/_helper-classes.scss */
.year-regulator .layout-slider {
  display: table-cell;
  width: 100%;
}
/* line 531, ../sass/_helper-classes.scss */
.year-regulator .layout-slider input[type="slider"] {
  visibility: hidden;
}

@media (max-width: 600px) {
  /* line 537, ../sass/_helper-classes.scss */
  .hidden-600 {
    display: none !important;
  }
}
/******************************************************************************
  Top Bar
*******************************************************************************/
/* line 4, ../sass/_top-bar.scss */
#top-box {
  width: 100%;
  /* Menu */
  /* Social Icons */
  /* Search */
  /* Info */
}
/* line 7, ../sass/_top-bar.scss */
#top-box .top-box-wrapper {
  background: #1e1e1e;
  min-height: 30px;
  padding: 5px 0;
}
/* line 12, ../sass/_top-bar.scss */
#top-box .btn-group.btn-select {
  margin: 3px 0 0 0;
}
/* line 15, ../sass/_top-bar.scss */
#top-box .btn-group.btn-select.language {
  margin-right: 6px;
}
/* line 18, ../sass/_top-bar.scss */
#top-box .btn-group.btn-select .dropdown-toggle {
  z-index: 911;
}
/* line 21, ../sass/_top-bar.scss */
#top-box .btn-group.btn-select .dropdown-menu {
  z-index: 910;
}
/* line 25, ../sass/_top-bar.scss */
#top-box .btn-group.btn-select.open .btn.dropdown-toggle:after {
  z-index: 912;
}
/* line 31, ../sass/_top-bar.scss */
#top-box .top-navbar {
  background: none;
  border: 0 none;
  border-radius: 0;
  height: auto;
  margin: 0;
  min-height: 30px;
}
/* line 39, ../sass/_top-bar.scss */
#top-box .top-navbar .collapse {
  padding: 0;
}
/* line 42, ../sass/_top-bar.scss */
#top-box .top-navbar .nav {
  margin-top: -5px;
  margin-bottom: -5px;
}
/* line 47, ../sass/_top-bar.scss */
#top-box .top-navbar .nav > li a {
  font-size: 12px;
  line-height: 22px;
  padding: 9px 15px 9px;
  -webkit-transition: background 0.2s linear, color 0.2s linear;
          transition: background 0.2s linear, color 0.2s linear;
}
/* line 55, ../sass/_top-bar.scss */
#top-box .top-navbar .nav > li a .fa {
  font-size: 120%;
  margin-right: 4px;
}
/* line 59, ../sass/_top-bar.scss */
#top-box .top-navbar .nav > li a .fa.after {
  margin-left: 4px;
  margin-right: 0;
}
/* line 64, ../sass/_top-bar.scss */
#top-box .top-navbar .nav > li a .count {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  display: inline-block;
  font-size: 110%;
  line-height: 1;
  margin-left: 4px;
  padding: 3px 5px;
  -webkit-transition: background 0.2s linear;
          transition: background 0.2s linear;
}
/* line 76, ../sass/_top-bar.scss */
#top-box .top-navbar .nav > li:hover a {
  background: #efefef;
  color: #7b7b7b;
  -webkit-transition: background 0.2s linear, color 0.2s linear;
  transition: background 0.2s linear, color 0.2s linear;
}
/* line 86, ../sass/_top-bar.scss */
#top-box .top-navbar .nav > li:hover a .count {
  background: rgba(0, 0, 0, 0.1);
  -webkit-transition: background 0.2s linear;
          transition: background 0.2s linear;
}
/* line 96, ../sass/_top-bar.scss */
#top-box .top-social {
  margin: 0;
}
/* line 99, ../sass/_top-bar.scss */
#top-box .top-social .icon-item {
  color: #fff;
  color: rgba(255, 255, 255, 0.3);
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin-left: 1px;
  margin-right: 1px;
  position: relative;
  text-align: center;
  -webkit-transition: color 0.2s linear;
          transition: color 0.2s linear;
  vertical-align: top;
  width: 30px;
}
/* line 113, ../sass/_top-bar.scss */
#top-box .top-social .icon-item i {
  bottom: 0;
  left: 0;
  line-height: 30px;
  position: absolute;
  right: 0;
  top: 0;
}
/* line 121, ../sass/_top-bar.scss */
#top-box .top-social .icon-item i:before {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}
/* line 125, ../sass/_top-bar.scss */
#top-box .top-social .icon-item:hover {
  color: #fff;
  -webkit-transition: color 0.2s linear;
          transition: color 0.2s linear;
}
/* line 130, ../sass/_top-bar.scss */
#top-box .top-social .icon-item:hover i:before {
  text-shadow: none;
}
/* line 137, ../sass/_top-bar.scss */
#top-box .top-social.bg-social .icon-item {
  background: #fff;
  border-radius: 3px;
  color: #fff;
}
/* line 142, ../sass/_top-bar.scss */
#top-box .top-social.bg-social .icon-item i {
  border-radius: 2px;
  opacity: .7;
  filter: alpha(opacity=70);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 148, ../sass/_top-bar.scss */
#top-box .top-social.bg-social .icon-item i:before {
  text-shadow: none;
}
/* line 152, ../sass/_top-bar.scss */
#top-box .top-social.bg-social .icon-item:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
/* line 156, ../sass/_top-bar.scss */
#top-box .top-social.bg-social .icon-item:hover i {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 162, ../sass/_top-bar.scss */
#top-box .top-social.bg-social .icon-item .fa-facebook {
  background: #3b5998;
}
/* line 165, ../sass/_top-bar.scss */
#top-box .top-social.bg-social .icon-item .fa-twitter {
  background: #00bdec;
}
/* line 168, ../sass/_top-bar.scss */
#top-box .top-social.bg-social .icon-item .fa-google-plus {
  background: #d94a39;
}
/* line 171, ../sass/_top-bar.scss */
#top-box .top-social.bg-social .icon-item .fa-linkedin {
  background: #1b92bd;
}
/* line 178, ../sass/_top-bar.scss */
#top-box .top-search {
  margin: 0 0 0 auto;
  max-width: 300px;
  position: relative;
}
/* line 183, ../sass/_top-bar.scss */
#top-box .top-search .form-control {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #e1e1e1;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: none;
  color: #fff;
  height: 30px;
  line-height: 14px;
  margin: 0;
  min-height: 30px;
  padding-top: 4px;
  padding-bottom: 4px;
}
/* line 196, ../sass/_top-bar.scss */
#top-box .top-search .form-control:focus {
  border-color: rgba(255, 255, 255, 0.7);
}
/* line 199, ../sass/_top-bar.scss */
#top-box .top-search .form-control::-moz-placeholder {
  color: #fff;
  color: rgba(255, 255, 255, 0.3);
}
/* line 203, ../sass/_top-bar.scss */
#top-box .top-search .form-control:-ms-input-placeholder {
  color: #fff;
  color: rgba(255, 255, 255, 0.3);
}
/* line 207, ../sass/_top-bar.scss */
#top-box .top-search .form-control::-webkit-input-placeholder {
  color: #fff;
  color: rgba(255, 255, 255, 0.3);
}
/* line 212, ../sass/_top-bar.scss */
#top-box .top-search .search-submit {
  background: none;
  height: 30px;
  opacity: .5;
  filter: alpha(opacity=50);
  padding: 6px 0 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
  width: 30px;
}
/* line 224, ../sass/_top-bar.scss */
#top-box .top-search .search-submit:hover {
  opacity: .7;
  filter: alpha(opacity=70);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 232, ../sass/_top-bar.scss */
#top-box .top-info {
  color: #fff;
  font-size: 13px;
  padding: 5px 0;
}
/* line 237, ../sass/_top-bar.scss */
#top-box .top-info a {
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
  -webkit-transition: color .2s linear;
          transition: color .2s linear;
}
/* line 242, ../sass/_top-bar.scss */
#top-box .top-info a:hover {
  color: #fff;
  text-decoration: none;
  -webkit-transition: color .2s linear;
          transition: color .2s linear;
}
/* line 248, ../sass/_top-bar.scss */
#top-box .top-info .separator {
  color: rgba(255, 255, 255, 0.4);
  vertical-align: top;
}

/******************************************************************************
  Header
*******************************************************************************/
/* line 4, ../sass/_header.scss */
.header {
  width: 100%;
}
/* line 7, ../sass/_header.scss */
.header .header-wrapper {
  background: rgba(255, 255, 255, 0.97);
  min-height: 100px;
  padding: 2px 0;
  /* Primary Menu */
  /* Cart  */
}
/* line 12, ../sass/_header.scss */
.header .header-wrapper .container {
  position: relative;
}
/* line 15, ../sass/_header.scss */
.header .header-wrapper .logo-box {
  display: table;
  table-layout: fixed;
}
/* line 19, ../sass/_header.scss */
.header .header-wrapper .logo-box .logo {
  display: table-cell;
  height: 100px;
  max-width: 100%;
  vertical-align: middle;
  width: 100%;
}
/* line 26, ../sass/_header.scss */
.header .header-wrapper .logo-box .logo a {
  display: inline-block;
  max-width: 100%;
}
/* line 30, ../sass/_header.scss */
.header .header-wrapper .logo-box .logo a img,
.header .header-wrapper .logo-box .logo a .logo-img {
  max-height: 100px;
}
/* line 36, ../sass/_header.scss */
.header .header-wrapper .logo-box.auto-height {
  display: block;
  height: 100px;
}
/* line 40, ../sass/_header.scss */
.header .header-wrapper .logo-box.auto-height .logo {
  display: block;
}
/* line 43, ../sass/_header.scss */
.header .header-wrapper .logo-box.auto-height .logo img {
  max-height: inherit;
}
/* line 51, ../sass/_header.scss */
.header .header-wrapper .primary {
  float: left;
  margin-bottom: 10px;
  z-index: 900;
}
@media (max-width: 991px) {
  /* line 51, ../sass/_header.scss */
  .header .header-wrapper .primary {
    display: inline-block;
    float: none;
    margin: 0 0 0 -4px;
    text-align: left;
    vertical-align: top;
    z-index: inherit;
  }
}
/* line 64, ../sass/_header.scss */
.header .header-wrapper .primary .navbar {
  background: none;
  border: 0 none;
  margin: 0;
  min-height: 44px;
}
@media (max-width: 600px) {
  /* line 70, ../sass/_header.scss */
  .header .header-wrapper .primary .navbar .btn-navbar {
    padding-left: 5px;
    padding-right: 5px;
  }
}
/* line 77, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse {
  padding: 0;
  position: relative;
}
/* line 81, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav {
  margin: 0;
  position: relative;
  /* Menu Color */
}
/* line 85, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .parent {
  position: relative;
}
/* line 88, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .parent:hover > .sub {
  display: block;
  -webkit-animation: fadeIn 0.4s;
  animation: fadeIn 0.4s;
}
/* line 94, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li {
  padding: 12px 3px 0;
  position: relative;
}
/* line 98, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li > a {
  background: none;
  border: 1px solid #fff;
  border: 1px solid transparent;
  border-radius: 3px;
  color: #1e1e1e;
  line-height: 20px;
  padding: 11px 13px;
  position: static;
  text-shadow: none;
  -webkit-transition: all 0.2s linear;
          transition: all 0.2s linear;
  z-index: 901;
}
/* line 111, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.selected > a {
  color: #777;
}
/* line 115, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li:hover > a {
  background: #fff;
  border-color: #ccc;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.2s linear;
          transition: all 0.2s linear;
}
/* line 123, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.parent:hover > a {
  background: #fff;
  border-bottom-color: transparent;
  border-radius: 3px 3px 0 0;
}
/* line 130, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.parent > a:after {
  background: transparent;
  bottom: 0;
  content: "";
  display: none;
  height: 12px;
  left: 4px;
  margin: 0 0 -6px 0;
  position: absolute;
  right: 4px;
  -webkit-transition: background 0.5s linear;
          transition: background 0.5s linear;
  z-index: 906;
}
/* line 145, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.parent:hover > a:after {
  background: #fff;
  display: block;
  -webkit-transition: background 0.1s linear;
          transition: background 0.1s linear;
}
/* line 151, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.parent.megamenu {
  overflow: hidden;
  position: static;
}
/* line 155, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.parent.megamenu > a {
  position: relative;
}
/* line 158, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.parent.megamenu:hover > a:after {
  left: 0;
  right: 0;
}
/* line 163, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.parent .parent > a:after {
  content: "\f105";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  display: block;
  line-height: 1;
  position: absolute;
  right: -10px;
  top: 6px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* line 179, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav a > i {
  margin-right: 2px;
  opacity: .8;
  filter: alpha(opacity=80);
  text-align: center;
  width: 15px;
}
/* line 186, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav a .item-new {
  background: #ff0068;
  border-radius: 3px;
  color: #fff;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0.01em;
  padding: 4px 10px;
  position: absolute;
  right: 19px;
  top: 1px;
  text-transform: uppercase;
}
/* line 199, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav a .item-new .before, .header .header-wrapper .primary .navbar .navbar-collapse .nav a .item-new:before {
  border: 5px solid transparent;
  border-right-color: #ff0068;
  bottom: -5px;
  content: "";
  display: block;
  position: absolute;
  right: 0;
}
/* line 209, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav a .item-new.bg-info .before, .header .header-wrapper .primary .navbar .navbar-collapse .nav a .item-new.bg-info:before {
  border-right-color: #0098ca;
}
/* line 213, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav a .item-new.bg-success .before, .header .header-wrapper .primary .navbar .navbar-collapse .nav a .item-new.bg-success:before {
  border-right-color: #738d00;
}
/* line 217, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav a .item-new.bg-primary .before, .header .header-wrapper .primary .navbar .navbar-collapse .nav a .item-new.bg-primary:before {
  border-right-color: #037ac5;
}
/* line 221, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav a .item-new.bg-warning .before, .header .header-wrapper .primary .navbar .navbar-collapse .nav a .item-new.bg-warning:before {
  border-right-color: #f89406;
}
/* line 225, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav a .item-new.bg-danger .before, .header .header-wrapper .primary .navbar .navbar-collapse .nav a .item-new.bg-danger:before {
  border-right-color: #c10841;
}
/* line 231, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu a .item-new {
  right: 14px;
  top: -12px;
}
/* line 235, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .sub .item-new,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu .sub a .item-new {
  float: right;
  left: 0;
  margin: 2px 0 0 10px;
  position: relative;
  top: 0;
}
/* line 243, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu .sub a .item-new {
  float: none;
}
/* line 246, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .sub {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 0 3px 3px 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  display: none;
  font-size: 13px;
  line-height: 24px;
  margin: -1px 0 0;
  padding: 15px 20px;
  position: absolute;
  z-index: 905;
}
/* line 259, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .sub li {
  font-size: 13px;
  line-height: 24px;
}
/* line 263, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .sub li a {
  color: #1e1e1e;
  display: block;
  text-decoration: none;
  width: 175px;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 270, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .sub li a:hover {
  opacity: .6;
  filter: alpha(opacity=60);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 277, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .sub .item-new .before,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .sub .item-new:before {
  display: none;
}
/* line 281, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .sub .sub {
  left: 100%;
  top: 0;
}
/* line 286, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .sub .sub .sub .parent > a {
  background: none !important;
}
/* line 289, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .sub .sub .sub .parent > a:after {
  display: none;
}
/* line 293, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .sub .sub .sub .sub {
  background: none;
  border: 0 none;
  box-shadow: none;
  display: block;
  left: 0;
  padding: 0 0 0 10px;
  position: relative;
  -webkit-animation: fadeIn 0;
  animation: fadeIn 0;
}
/* line 307, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu {
  /* Four columns */
  /* Five columns */
}
/* line 308, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu .title {
  font-size: 13px;
  line-height: 23px;
  margin: 0 0 3px;
  text-transform: uppercase;
}
/* line 314, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub {
  border-radius: 3px;
  left: -15px;
  padding: 0;
  width: 886px;
  z-index: 900;
}
@media (min-width: 992px) and (max-width: 1199px) {
  /* line 314, ../sass/_header.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub {
    margin: -1px 0 0 -37px;
    width: 832px;
  }
}
/* line 327, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub a {
  width: 100%;
}
/* line 330, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .sub-wrapper {
  display: table;
  width: 100%;
}
/* line 334, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .promo-block {
  border-right: 1px solid #ccc;
  display: table-cell;
  font-size: 12px;
  line-height: 18px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 34%;
}
/* line 344, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .promo-block.text-right {
  text-align: right;
}
/* line 347, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .promo-block.text-left {
  text-align: left;
}
/* line 351, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .sub-list {
  display: table-cell;
  padding: 35px 5px;
  vertical-align: top;
  width: 100%;
}
/* line 357, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .sub-list + .promo-block {
  border-left: 1px solid #ccc;
  border-right: 0 none;
}
/* line 362, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .box {
  float: left;
  padding: 0 20px 28px;
  width: 33%;
}
/* line 367, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .box:nth-child(3n+1), .header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .box:nth-child(2n+1) {
  clear: left;
}
/* line 375, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu.promo > .sub .sub-list {
  width: 66%;
}
/* line 378, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu.promo > .sub .box {
  clear: none;
  width: 50%;
}
/* line 386, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu.four-columns > .sub .box {
  clear: none;
}
/* line 389, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu.four-columns > .sub .box:nth-child(4n+1), .header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu.four-columns > .sub .box:nth-child(3n+1) {
  clear: left;
}
/* line 393, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu.promo.four-columns > .sub .box {
  clear: none;
}
/* line 396, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu.four-columns > .sub .promo-block, .header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu.four-columns > .sub .box {
  width: 25%;
}
/* line 400, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu.four-columns > .sub .sub-list {
  width: 75%;
}
/* line 403, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu.promo.four-columns > .sub .box {
  width: 33%;
}
/* line 407, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu.five-columns > .sub .box {
  clear: none;
}
/* line 410, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu.five-columns > .sub .box:nth-child(5n+1), .header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu.promo.five-columns > .sub .box:nth-child(4n+1) {
  clear: left;
}
/* line 414, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu.promo.five-columns > .sub .box {
  clear: none;
}
/* line 417, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu.five-columns > .sub .promo-block, .header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu.five-columns > .sub .box {
  width: 20%;
}
/* line 421, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu.five-columns > .sub .sub-list {
  width: 80%;
}
/* line 424, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu.promo.five-columns > .sub .box {
  width: 25%;
}
/* line 429, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li[class*="item-"]:hover > a,
.header .header-wrapper .primary .navbar .navbar-collapse .nav [class*="item-"] .sub {
  box-shadow: none;
}
/* line 434, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.text-primary > a {
  color: #037ac5;
}
/* line 437, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.text-success > a {
  color: #738d00;
}
/* line 440, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.text-info > a {
  color: #0098ca;
}
/* line 443, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.text-warning > a {
  color: #f89406;
}
/* line 446, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.text-danger > a {
  color: #c10841;
}
/* line 449, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.parent.item-bg:hover > a:after {
  display: none;
}
/* line 452, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-inverse > a {
  color: #fff;
}
/* line 455, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-primary.item-inverse > a {
  background: #037ac5;
}
/* line 458, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-success.item-inverse > a {
  background: #738d00;
}
/* line 461, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-info.item-inverse > a {
  background: #0098ca;
}
/* line 464, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-warning.item-inverse > a {
  background: #f89406;
}
/* line 467, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-danger.item-inverse > a {
  background: #c10841;
}
/* line 470, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-inverse:hover > a {
  background: #fff;
  color: #1e1e1e;
}
/* line 474, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-primary:hover > a,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-primary .sub,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-primary.megamenu > .sub .promo-block {
  border-color: #037ac5;
}
/* line 479, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-success:hover > a,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-success .sub,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-success.megamenu > .sub .promo-block {
  border-color: #738d00;
}
/* line 484, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-info:hover > a,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-info .sub,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-info.megamenu > .sub .promo-block {
  border-color: #0098ca;
}
/* line 489, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-warning:hover > a,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-warning .sub,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-warning.megamenu > .sub .promo-block {
  border-color: #f89406;
}
/* line 494, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-danger:hover > a,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-danger .sub,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-danger.megamenu > .sub .promo-block {
  border-color: #c10841;
}
/* line 499, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-bg:hover a,
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-bg .sub a {
  color: #fff;
}
/* line 503, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-primary.item-bg:hover > a,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-primary.item-bg .sub,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-primary.item-bg.parent .sub,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-primary.item-bg.parent:hover > .sub {
  background: #037ac5;
}
/* line 509, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-success.item-bg:hover > a,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-success.item-bg .sub,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-success.item-bg.parent .sub,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-success.item-bg.parent:hover > .sub {
  background: #738d00;
}
/* line 515, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-info.item-bg:hover > a,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-info.item-bg .sub,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-info.item-bg.parent .sub,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-info.item-bg.parent:hover > .sub {
  background: #0098ca;
}
/* line 521, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-warning.item-bg:hover > a,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-warning.item-bg .sub,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-warning.item-bg.parent .sub,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-warning.item-bg.parent:hover > .sub {
  background: #f89406;
}
/* line 527, ../sass/_header.scss */
.header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-danger.item-bg:hover > a,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-danger.item-bg .sub,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-danger.item-bg.parent .sub,
.header .header-wrapper .primary .navbar .navbar-collapse .nav .item-danger.item-bg.parent:hover > .sub {
  background: #c10841;
}
/* line 538, ../sass/_header.scss */
.header .header-wrapper .header-icons {
  float: right;
  margin: 9px 0;
  text-align: right;
}
@media (max-width: 991px) {
  /* line 538, ../sass/_header.scss */
  .header .header-wrapper .header-icons {
    display: inline-block;
    float: none;
    margin: 0;
    vertical-align: top;
  }
}
/* line 551, ../sass/_header.scss */
.header .header-wrapper .cart-header {
  display: inline-block;
  font-size: 12px;
  margin: 0 -15px 0 -1px;
  white-space: normal;
}
/* line 557, ../sass/_header.scss */
.header .header-wrapper .cart-header.no-open {
  opacity: 0;
  filter: alpha(opacity=0);
}
/* line 561, ../sass/_header.scss */
.header .header-wrapper .cart-header .dropdown-toggle {
  border-width: 1px;
  border-color: #fff;
  border-color: transparent;
  border-bottom: 0 none;
  color: #1e1e1e;
  display: block;
  line-height: 22px;
  outline: none;
  padding: 11px 15px 12px;
  position: relative;
  text-decoration: none;
  text-shadow: none;
  -webkit-transition: border-color 0.4s linear, box-shadow 0.4s linear;
          transition: border-color 0.4s linear, box-shadow 0.4s linear;
  z-index: 891;
}
@media (max-width: 600px) {
  /* line 561, ../sass/_header.scss */
  .header .header-wrapper .cart-header .dropdown-toggle {
    padding-left: 5px;
    padding-right: 5px;
  }
}
/* line 583, ../sass/_header.scss */
.header .header-wrapper .cart-header .dropdown-toggle .icon {
  display: inline-block;
  height: 22px;
  margin: 0 8px 0 0;
  vertical-align: top;
  width: 16px;
}
/* line 590, ../sass/_header.scss */
.header .header-wrapper .cart-header .dropdown-toggle .icon svg {
  margin-top: 2px;
  vertical-align: top;
}
@media (max-width: 600px) {
  /* line 596, ../sass/_header.scss */
  .header .header-wrapper .cart-header .dropdown-toggle .count {
    display: none;
  }
}
/* line 601, ../sass/_header.scss */
.header .header-wrapper .cart-header.open .dropdown-toggle {
  background: #fff;
  border-color: #ccc;
  border-bottom: 0 none;
  border-radius: 3px 3px 0 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  -webkit-transition: border-color 0.4s linear, box-shadow 0.4s linear;
          transition: border-color 0.4s linear, box-shadow 0.4s linear;
}
/* line 611, ../sass/_header.scss */
.header .header-wrapper .cart-header.open .dropdown-toggle:after {
  background: #fff;
  bottom: 0;
  content: "";
  display: block;
  height: 8px;
  left: -3px;
  margin: 0 0 -5px 0;
  position: absolute;
  right: 0;
}
/* line 622, ../sass/_header.scss */
.header .header-wrapper .cart-header .dropdown-menu {
  left: auto;
  margin: -4px 0 0 0;
  padding: 17px 20px 20px;
  right: 0;
  text-align: left;
  width: 355px;
  -webkit-animation: fadeIn 0.4s;
  animation: fadeIn 0.4s;
  z-index: 890;
}
/* line 633, ../sass/_header.scss */
.header .header-wrapper .cart-header .dropdown-menu .close-box {
  line-height: 12px;
  margin: 0 0 13px;
  text-align: right;
}
/* line 638, ../sass/_header.scss */
.header .header-wrapper .cart-header .dropdown-menu .close {
  font-size: 26px;
  line-height: 12px;
  text-transform: uppercase;
  vertical-align: top;
}
/* line 644, ../sass/_header.scss */
.header .header-wrapper .cart-header .dropdown-menu .close span {
  line-height: 14px;
  margin: 0 5px 0 0;
}
/* line 649, ../sass/_header.scss */
.header .header-wrapper .cart-header .dropdown-menu > strong {
  font-size: 13px;
  line-height: 22px;
  text-transform: uppercase;
}
/* line 654, ../sass/_header.scss */
.header .header-wrapper .cart-header .dropdown-menu > ul {
  margin: 0 0 20px;
}
/* line 657, ../sass/_header.scss */
.header .header-wrapper .cart-header .dropdown-menu > ul li {
  border-bottom: 1px solid #e1e1e1;
  padding: 24px 20px 24px 0;
  position: relative;
}
/* line 663, ../sass/_header.scss */
.header .header-wrapper .cart-header .dropdown-menu .product-image {
  display: block;
  float: left;
  margin: 0 15px 0 0;
  width: 70px;
}
/* line 669, ../sass/_header.scss */
.header .header-wrapper .cart-header .dropdown-menu .product-remove {
  margin: 0 0 0 5px;
  position: absolute;
  right: 0;
  top: 24px;
}
/* line 675, ../sass/_header.scss */
.header .header-wrapper .cart-header .dropdown-menu .product-name {
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  margin: 0 0 10px;
  padding-left: 85px;
}
/* line 682, ../sass/_header.scss */
.header .header-wrapper .cart-header .dropdown-menu .product-price {
  font-size: 12px;
  line-height: 16px;
  padding-left: 85px;
}
/* line 687, ../sass/_header.scss */
.header .header-wrapper .cart-header .dropdown-menu .product-price .price {
  font-size: 16px;
}
/* line 691, ../sass/_header.scss */
.header .header-wrapper .cart-header .dropdown-menu .cart-button {
  text-align: right;
}
/* line 694, ../sass/_header.scss */
.header .header-wrapper .cart-header .dropdown-menu .cart-button .checkout {
  margin-left: 6px;
}
/* line 701, ../sass/_header.scss */
.fixed-header .header {
  left: 0;
  margin: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 870;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  width: auto;
}
/* line 711, ../sass/_header.scss */
.fixed-header .header .header-wrapper {
  background: #fff;
  -webkit-transition: background 0.2s linear;
          transition: background 0.2s linear;
}
/* line 716, ../sass/_header.scss */
.fixed-header.loaded .header {
  -webkit-transition: margin-top 0.2s linear;
          transition: margin-top 0.2s linear;
}
/* line 719, ../sass/_header.scss */
.fixed-header.background-opacity .header .header-wrapper {
  background: rgba(255, 255, 255, 0.97);
  -webkit-transition: background 0.2s linear;
          transition: background 0.2s linear;
}

/* Fixed Header */
/* line 726, ../sass/_header.scss */
.fixed-header {
  margin-top: 0;
}
@media (max-width: 991px) {
  /* line 726, ../sass/_header.scss */
  .fixed-header {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
}

/* line 733, ../sass/_header.scss */
.fixed-header .page-box {
  padding-top: 104px;
}
@media (max-width: 991px) {
  /* line 733, ../sass/_header.scss */
  .fixed-header .page-box {
    margin-top: 0 !important;
    padding-top: 0 !important;
    -webkit-transition: none !important;
            transition: none !important;
  }
}

/* line 742, ../sass/_header.scss */
.hidden-top.visible-top .header-wrapper:only-child,
.fixed-header.fixed .header-wrapper:only-child {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

/* Hidden Top Bar */
/* line 749, ../sass/_header.scss */
.hidden-top #top-box {
  left: 0;
  margin-top: -32px;
  position: fixed;
  top: 0;
  z-index: 910;
}
/* line 756, ../sass/_header.scss */
.hidden-top.loaded #top-box {
  -webkit-transition: margin-top 0.2s linear;
          transition: margin-top 0.2s linear;
}
/* line 759, ../sass/_header.scss */
.hidden-top.visible-top #top-box {
  margin-top: 0;
  -webkit-transition: margin-top 0.2s linear;
          transition: margin-top 0.2s linear;
}
@media (min-width: 992px) {
  /* line 764, ../sass/_header.scss */
  #top-box:not(.minimized-menu) .navbar-collapse {
    display: block !important;
    height: auto !important;
  }
  /* line 768, ../sass/_header.scss */
  #top-box:not(.minimized-menu) .navbar-collapse.collapse {
    padding-bottom: 0;
    overflow: visible !important;
  }
}

/* line 776, ../sass/_header.scss */
.hidden-top.loaded .page-box,
.hidden-top.visible-top.loaded .page-box {
  -webkit-transition: padding-top 0.2s linear;
          transition: padding-top 0.2s linear;
}

/* line 780, ../sass/_header.scss */
.hidden-top .page-box {
  padding-top: 8px;
}

/* line 783, ../sass/_header.scss */
.hidden-top.visible-top .page-box {
  padding-top: 40px;
}

/* Fixed Header/Hidden Top Bar */
/* line 788, ../sass/_header.scss */
.fixed-header.hidden-top .page-box {
  padding-top: 112px;
}

/* line 791, ../sass/_header.scss */
.fixed-header.hidden-top.fixed .page-box {
  padding-top: 104px;
}

/* line 794, ../sass/_header.scss */
.fixed-header.hidden-top.fixed #top-box,
.fixed-header.hidden-top.visible-top.fixed #top-box {
  margin-top: -40px;
  -webkit-transition: margin-top 0.2s linear;
          transition: margin-top 0.2s linear;
}

/* line 799, ../sass/_header.scss */
.fixed-header.hidden-top .header {
  margin-top: 8px;
  top: 0;
}

/* line 803, ../sass/_header.scss */
.fixed-header.hidden-top.visible-top .header {
  margin-top: 40px;
}

/* line 806, ../sass/_header.scss */
.fixed-header.hidden-top.fixed .header {
  margin-top: 0;
  -webkit-transition: margin-top 0.2s linear;
          transition: margin-top 0.2s linear;
}

/* Fixed Top Bar */
/* line 811, ../sass/_header.scss */
.fixed-header.fixed-top #top-box {
  left: 0;
  margin: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 871;
}

/* line 819, ../sass/_header.scss */
.fixed-header.fixed-top .header {
  top: 40px;
}

/* line 822, ../sass/_header.scss */
.fixed-header.fixed-top .page-box {
  padding-top: 144px;
}

/* line 825, ../sass/_header.scss */
.header .right-box {
  display: table;
}

/* line 828, ../sass/_header.scss */
.header .right-box-wrapper {
  display: table-cell;
  height: 100px;
  vertical-align: middle;
}

/* line 833, ../sass/_header.scss */
.fixed-header.fixed-top.boxed #top-box {
  position: absolute;
}

/* Fixed Double Header */
/* line 837, ../sass/_header.scss */
.fixed-double-header .header {
  margin-bottom: 104px;
}

/* line 840, ../sass/_header.scss */
.fixed-double-header .header .header-wrapper + .header-wrapper {
  margin: 0;
  position: absolute;
  width: 100%;
}

/* Phone & Search Header */
/* line 847, ../sass/_header.scss */
.phone-header,
.search-header {
  border-right: 1px solid #ccc;
  border-right: 1px solid rgba(204, 204, 204, 0.8);
  color: #1e1e1e;
  display: inline-block;
  height: 30px;
  margin: 8px 0;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: 41px;
}
/* line 860, ../sass/_header.scss */
.phone-header:last-child,
.search-header:last-child {
  border-right: 0 none;
}
/* line 863, ../sass/_header.scss */
.phone-header a,
.search-header a {
  display: block;
  height: 23px;
  padding-top: 7px;
  width: 100%;
}
/* line 869, ../sass/_header.scss */
.phone-header a svg,
.search-header a svg {
  overflow: visible;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: all .3s linear;
          transition: all .3s linear;
  -webkit-transform: translateZ(0px);
}
/* line 877, ../sass/_header.scss */
.phone-header a:hover svg,
.search-header a:hover svg {
  opacity: .5;
  filter: alpha(opacity=50);
  -webkit-transition: all .3s linear;
          transition: all .3s linear;
}

/* Phone & Search Active Header */
/* line 887, ../sass/_header.scss */
.phone-active,
.search-active {
  bottom: 0;
  display: none;
  margin: 18px 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 920;
}
/* line 898, ../sass/_header.scss */
.phone-active .close,
.search-active .close {
  float: right;
  font-size: 26px;
  margin: 24px 0 0 5px;
}
/* line 903, ../sass/_header.scss */
.phone-active .close span,
.search-active .close span {
  line-height: 18px;
  margin-right: 5px;
  margin-top: 0;
  text-transform: uppercase;
}

/* line 911, ../sass/_header.scss */
.phone-active {
  background: #fff;
  color: #ebebeb;
  font-size: 48px;
  line-height: 58px;
}
/* line 917, ../sass/_header.scss */
.phone-active strong {
  color: #1e1e1e;
  letter-spacing: 1px;
}

/* line 922, ../sass/_header.scss */
.search-active .close {
  position: absolute;
  right: 79px;
  z-index: 1;
}

/* line 927, ../sass/_header.scss */
.header .search-form {
  height: 100%;
  margin: 0;
  position: relative;
  width: 100%;
}
/* line 933, ../sass/_header.scss */
.header .search-form .search-string, .header .search-form .search-string:hover {
  box-shadow: none;
  color: #1e1e1e;
  font-size: 24px;
  height: 100%;
  line-height: 30px;
  margin: 0;
  padding: 14px 135px 14px 18px;
  width: 100%;
}
/* line 946, ../sass/_header.scss */
.header .search-form .search-string:focus {
  border-color: #ccc;
  outline: 0;
  outline: thin dotted \9;
  box-shadow: none;
}
/* line 953, ../sass/_header.scss */
.header .search-form .search-submit {
  background: none;
  border-left-width: 1px;
  height: 30px;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  right: 1px;
  top: 17px;
  width: 46px;
  -webkit-transition: all .3s linear;
          transition: all .3s linear;
}
/* line 967, ../sass/_header.scss */
.header .search-form .search-submit svg {
  margin-top: 2px;
}
/* line 970, ../sass/_header.scss */
.header .search-form .search-submit svg path {
  fill: #1e1e1e;
}
/* line 974, ../sass/_header.scss */
.header .search-form .search-submit:hover {
  opacity: .5;
  filter: alpha(opacity=50);
  -webkit-transition: all .3s linear;
          transition: all .3s linear;
}

@media (max-width: 600px) {
  /* line 985, ../sass/_header.scss */
  .header.header-two .header-wrapper .primary .navbar .btn-navbar.collapsed:before {
    display: none;
  }
}
/* line 989, ../sass/_header.scss */
.header.header-two .phone-header {
  border-right: 0 none;
  margin-right: -15px;
}
/* line 994, ../sass/_header.scss */
.header.header-three {
  background: rgba(255, 255, 255, 0.9);
  min-height: inherit;
}
/* line 998, ../sass/_header.scss */
.header.header-three .row,
.header.header-three .container {
  min-height: inherit;
}
/* line 1002, ../sass/_header.scss */
.header.header-three .logo-box {
  text-align: center;
}
/* line 1007, ../sass/_header.scss */
.header .right-box .search-form {
  height: 64px;
}
@media (max-width: 480px) {
  /* line 1007, ../sass/_header.scss */
  .header .right-box .search-form {
    display: none;
  }
}
/* line 1013, ../sass/_header.scss */
.header .right-box .search-form .search-string {
  padding-right: 64px;
}
/* line 1018, ../sass/_header.scss */
.header .header-wrapper + .header-wrapper {
  border-bottom: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
  min-height: 104px;
  padding: 0;
}
/* line 1024, ../sass/_header.scss */
.header .header-wrapper + .header-wrapper .right-box-wrapper {
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  height: 104px;
}
/* line 1029, ../sass/_header.scss */
.header .header-wrapper + .header-wrapper .cart-header {
  margin-right: -1px;
}
/* line 1032, ../sass/_header.scss */
.header .header-wrapper + .header-wrapper .phone-active {
  left: 16px;
  margin: 20px 0;
  right: 16px;
  width: auto;
}
/* line 1038, ../sass/_header.scss */
.header .header-wrapper + .header-wrapper .primary .megamenu > .sub {
  left: -1px;
  margin-left: 0;
}

/******************************************************************************
  Top Fixed Box
*******************************************************************************/
/* line 1048, ../sass/_header.scss */
.top-fixed-box {
  background: #fff;
  background: rgba(255, 255, 255, 0.97);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #e1e1e1;
  border: 1px solid rgba(0, 0, 0, 0.1);
  left: 0;
  margin-top: -60px;
  padding: 7px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  -webkit-transition: margin-top .3s linear;
          transition: margin-top .3s linear;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: translateZ(0);
}
/* line 1065, ../sass/_header.scss */
.top-fixed-box.fixed {
  margin-top: 0;
  -webkit-transition: margin-top .3s linear;
          transition: margin-top .3s linear;
}
/* line 1069, ../sass/_header.scss */
.top-fixed-box .social {
  display: inline-block;
  margin: 0 6px;
  vertical-align: top;
}
/* line 1074, ../sass/_header.scss */
.top-fixed-box .contact-box {
  color: #7f7f7f;
  font-size: 12px;
  margin-top: 11px;
}
/* line 1079, ../sass/_header.scss */
.top-fixed-box .contact-box .phone {
  margin-right: 20px;
}
/* line 1082, ../sass/_header.scss */
.top-fixed-box .contact-box .fa {
  font-size: 14px;
  margin-right: 3px;
}
/* line 1086, ../sass/_header.scss */
.top-fixed-box .contact-box .phone .fa {
  font-size: 20px;
  line-height: 17px;
  vertical-align: top;
}

/******************************************************************************
  Minimized Menu
*******************************************************************************/
/* line 1097, ../sass/_header.scss */
.fixed-header .minimized-menu {
  -webkit-backface-visibility: inherit;
  -webkit-transform: inherit;
}

/* line 1103, ../sass/_header.scss */
.minimized-menu .header-wrapper .right-box {
  float: right;
  width: 50%;
}
/* line 1107, ../sass/_header.scss */
.minimized-menu .header-wrapper .right-box-wrapper {
  text-align: right;
}
/* line 1110, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary {
  display: inline-block;
  float: none;
  margin: 0 0 0 -4px;
  text-align: left;
  vertical-align: top;
  z-index: inherit;
}
/* line 1118, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar {
  margin: 0;
  min-height: inherit;
  position: static;
}
/* line 1123, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .btn-navbar {
  background: #fff;
  border: 1px solid #ccc;
  border-bottom: 0 none;
  border-radius: 3px 3px 0 0;
  box-shadow: none;
  display: block;
  float: left;
  height: 46px;
  line-height: 15px;
  margin: 0;
  padding: 10px 15px;
  position: relative;
  text-shadow: none;
  z-index: 920;
}
/* line 1139, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .btn-navbar:before {
  border-left: 1px solid #ccc;
  border-left: 1px solid rgba(204, 204, 204, 0.8);
  bottom: 8px;
  content: "";
  display: none;
  left: -1px;
  position: absolute;
  top: 7px;
}
/* line 1149, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .btn-navbar.collapsed {
  background: none;
  border: 1px solid transparent;
  border-bottom: 0 none;
}
/* line 1154, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .btn-navbar.collapsed:before {
  display: block;
}
/* line 1158, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .btn-navbar .text {
  color: #1e1e1e;
  float: right;
  line-height: 16px;
  padding: 0 0 0 30px;
}
/* line 1164, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .btn-navbar .icon-bar {
  background: #1e1e1e;
  box-shadow: none;
  border-radius: 0;
  height: 3px;
  margin: 0;
  width: 22px;
}
/* line 1172, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .btn-navbar .icon-bar + .icon-bar {
  margin-top: 3px;
}
/* line 1177, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse {
  border: 0 none;
  box-shadow: none;
  left: auto;
  margin: 22px 0 0;
  max-height: inherit;
  overflow: hidden !important;
  padding: 0 15px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 200%;
  z-index: 919;
}
/* line 1191, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse.collapse {
  display: none !important;
}
/* line 1194, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse.collapse.in {
  display: block !important;
}
/* line 1198, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px 0 3px 3px;
  float: none;
  padding: 6px 6px 3px;
}
/* line 1205, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li {
  float: none;
  margin: 0 0 3px;
  padding: 0;
}
/* line 1210, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li > a {
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 0;
  font-weight: normal;
  margin: 0;
  padding: 7px 9px 7px 59px;
  text-decoration: none;
}
/* line 1219, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li:hover > a,
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li > a:hover {
  background: #e9e9e9;
  border: 1px solid #e9e9e9;
  border-radius: 0;
  box-shadow: none;
}
/* line 1227, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > .parent > a,
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.parent:hover > a {
  background: #f2f2f2;
  position: relative;
}
/* line 1232, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.parent:hover > a:after {
  display: none;
}
/* line 1235, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .parent .parent > a:after {
  display: none;
}
/* line 1238, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .open-sub {
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 55px;
}
/* line 1245, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .open-sub span {
  background: #1e1e1e;
  display: block;
  height: 2px;
  margin: 17px 0 0 15px;
  position: absolute;
  width: 16px;
}
/* line 1253, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .open-sub span + span {
  height: 16px;
  margin: 10px 0 0 22px;
  width: 2px;
}
/* line 1260, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .parent .parent > a .open-sub span,
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu .title .open-sub span {
  background: #7f7f7f;
  height: 2px;
  margin: 14px 0 0 38px;
  position: absolute;
  width: 12px;
}
/* line 1268, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .parent .parent > a .open-sub span + span,
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu .title .open-sub span + span {
  height: 12px;
  margin: 9px 0 0 43px;
  width: 2px;
}
/* line 1274, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .sub,
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .parent .sub,
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .parent:hover > .sub {
  background: #f7f7f7;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  display: none;
  font-size: 13px;
  left: 0;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 6px 0 5px;
  position: relative;
}
/* line 1290, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.parent:hover > a {
  background-color: #e9e9e9;
  border-radius: 0;
}
/* line 1294, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > .parent.active > a,
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > .parent.active:hover > a {
  background: #1e1e1e;
  border-color: #1e1e1e;
  color: #fff;
  -webkit-transition: all 0.2s linear;
          transition: all 0.2s linear;
}
/* line 1301, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > .parent.active > a .open-sub span {
  background: #fff;
}
/* line 1304, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .parent.active > a .open-sub span + span,
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu .box.active .title .open-sub span + span {
  display: none;
}
/* line 1308, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .parent:hover > .sub {
  -webkit-animation: fadeIn 0;
  animation: fadeIn 0;
}
/* line 1313, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .sub li {
  line-height: 22px;
}
/* line 1316, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .sub a {
  background: none;
  color: #7f7f7f;
  padding: 4px 9px 4px 60px;
  text-decoration: none;
  width: auto !important;
}
/* line 1323, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .sub a > i {
  color: #1e1e1e;
}
/* line 1327, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .sub .sub {
  left: 0;
  margin-left: 10px;
}
/* line 1333, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav li:hover > .sub a {
  background: none;
}
/* line 1338, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub {
  margin: 0;
  padding: 6px 0 5px;
  width: 100%;
}
/* line 1343, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .sub-wrapper {
  display: block;
}
/* line 1346, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .promo-block {
  display: none;
}
/* line 1349, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .sub-list {
  display: block;
  padding: 0;
  width: 100% !important;
}
/* line 1354, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .box {
  float: none;
  margin: 0;
  padding: 0;
  width: 100% !important;
}
/* line 1360, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .box.closed ul {
  display: none;
  margin-left: 10px;
}
/* line 1366, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu .title {
  color: #7f7f7f;
  cursor: pointer;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  padding: 4px 9px 4px 60px;
  position: relative;
  text-transform: none;
}
/* line 1377, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav a .item-new,
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu a .item-new,
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu .sub a .item-new {
  left: 0;
  float: right;
  position: static;
  top: 0;
}
/* line 1385, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav .item-new .before,
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav a .item-new:before {
  display: none;
}
/* line 1390, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-bg > a .open-sub span, .minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-inverse > a .open-sub span {
  background: #fff;
}
/* line 1394, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-bg > a, .minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-inverse > a, .minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-inverse:hover > a {
  color: #fff;
}
/* line 1399, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-primary > a {
  border-color: #037ac5;
}
/* line 1402, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-success > a {
  border-color: #738d00;
}
/* line 1405, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-info > a {
  border-color: #0098ca;
}
/* line 1408, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-warning > a {
  border-color: #f89406;
}
/* line 1411, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-danger > a {
  border-color: #c10841;
}
/* line 1414, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-primary.item-bg > a, .minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-primary.item-bg:hover > a, .minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-primary.item-inverse > a, .minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-primary.item-inverse:hover > a {
  background-color: #037ac5;
  border-color: #037ac5;
}
/* line 1421, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-success.item-bg > a, .minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-success.item-bg:hover > a, .minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-success.item-inverse > a, .minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-success.item-inverse:hover > a {
  background-color: #738d00;
  border-color: #738d00;
}
/* line 1428, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-info.item-bg > a, .minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-info.item-bg:hover > a, .minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-info.item-inverse > a, .minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-info.item-inverse:hover > a {
  background-color: #0098ca;
  border-color: #0098ca;
}
/* line 1435, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-warning.item-bg > a, .minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-warning.item-bg:hover > a, .minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-warning.item-inverse > a, .minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-warning.item-inverse:hover > a {
  background-color: #f89406;
  border-color: #f89406;
}
/* line 1442, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-danger.item-bg > a, .minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-danger.item-bg:hover > a, .minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-danger.item-inverse > a, .minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-danger.item-inverse:hover > a {
  background-color: #c10841;
  border-color: #c10841;
}
/* line 1450, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-bg .sub {
  background: #f7f7f7;
}
/* line 1453, ../sass/_header.scss */
.minimized-menu .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-bg .sub a {
  color: #7f7f7f;
}
/* line 1464, ../sass/_header.scss */
.minimized-menu .header-wrapper .cart-header .dropdown-menu {
  margin-top: -1px;
  margin-right: -98px;
}
/* line 1469, ../sass/_header.scss */
.minimized-menu .header-wrapper .cart-header.open .dropdown-toggle:after {
  left: 0;
}
/* line 1474, ../sass/_header.scss */
.minimized-menu .header-wrapper .header-icons {
  display: inline-block;
  float: none;
  margin: 0;
  vertical-align: top;
}
/* line 1480, ../sass/_header.scss */
.minimized-menu .header-wrapper .scroll-wrapper > .scroll-content {
  box-sizing: border-box !important;
  height: auto !important;
}
/* line 1485, ../sass/_header.scss */
.minimized-menu .header-wrapper .navbar-nav > .scroll-element, .minimized-menu .header-wrapper .navbar-nav > .scroll-element .navbar-nav > div {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 1000;
}
/* line 1492, ../sass/_header.scss */
.minimized-menu .header-wrapper .navbar-nav > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}
/* line 1500, ../sass/_header.scss */
.minimized-menu .header-wrapper .navbar-nav > .scroll-element.scroll-x, .minimized-menu .header-wrapper .navbar-nav > .scroll-element .navbar-nav > div.scroll-x {
  bottom: 2px;
  height: 8px;
  left: 0;
  width: 100%;
}
/* line 1506, ../sass/_header.scss */
.minimized-menu .header-wrapper .navbar-nav > .scroll-element.scroll-y, .minimized-menu .header-wrapper .navbar-nav > .scroll-element .navbar-nav > div.scroll-y {
  height: 100%;
  right: 0;
  top: 0;
  width: 4px;
}
/* line 1512, ../sass/_header.scss */
.minimized-menu .header-wrapper .navbar-nav > .scroll-element .scroll-element_outer {
  overflow: hidden;
}
/* line 1515, ../sass/_header.scss */
.minimized-menu .header-wrapper .navbar-nav > .scroll-element .scroll-element_outer,
.minimized-menu .header-wrapper .navbar-nav > .scroll-element .scroll-element_track,
.minimized-menu .header-wrapper .navbar-nav > .scroll-element .scroll-bar {
  border-radius: 0;
}
/* line 1522, ../sass/_header.scss */
.minimized-menu .header-wrapper .navbar-nav > .scroll-element .scroll-element_track {
  background-color: #ccc;
}
/* line 1525, ../sass/_header.scss */
.minimized-menu .header-wrapper .navbar-nav > .scroll-element .scroll-bar {
  background-color: rgba(31, 31, 31, 0.2);
}
/* line 1528, ../sass/_header.scss */
.minimized-menu .header-wrapper .navbar-nav > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track, .minimized-menu .header-wrapper .navbar-nav > .scroll-element .navbar-nav > div.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -12px;
}
/* line 1531, ../sass/_header.scss */
.minimized-menu .header-wrapper .navbar-nav > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track, .minimized-menu .header-wrapper .navbar-nav > .scroll-element .navbar-nav > div.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -12px;
}
/* line 1534, ../sass/_header.scss */
.minimized-menu .header-wrapper .navbar-nav > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size, .minimized-menu .header-wrapper .navbar-nav > .scroll-element .navbar-nav > div.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -12px;
}
/* line 1537, ../sass/_header.scss */
.minimized-menu .header-wrapper .navbar-nav > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size, .minimized-menu .header-wrapper .navbar-nav > .scroll-element .navbar-nav > div.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -12px;
}
/* line 1545, ../sass/_header.scss */
.minimized-menu.header-two .header-wrapper .phone-header {
  margin-right: 0;
}
/* line 1548, ../sass/_header.scss */
.minimized-menu.header-two .header-wrapper .phone-active,
.minimized-menu.header-two .header-wrapper .search-active {
  z-index: 1003;
}

/******************************************************************************
  Breadcrumb
*******************************************************************************/
/* line 4, ../sass/_breadcrumb.scss */
.breadcrumb-box {
  background: #f2f2f2;
  overflow: hidden;
}

/* line 8, ../sass/_breadcrumb.scss */
.breadcrumb {
  background: #f2f2f2;
  color: #7f7f7f;
  font-size: 11px;
  line-height: 18px;
  margin: 0;
  padding: 11px 0;
}
/* line 16, ../sass/_breadcrumb.scss */
.breadcrumb > li {
  line-height: 18px;
  text-shadow: none;
}
/* line 20, ../sass/_breadcrumb.scss */
.breadcrumb > li > a {
  color: #7f7f7f;
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 25, ../sass/_breadcrumb.scss */
.breadcrumb > li > a:hover {
  opacity: .8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 31, ../sass/_breadcrumb.scss */
.breadcrumb > li + li:before {
  padding: 0 6px;
  color: #7f7f7f;
}

/* line 37, ../sass/_breadcrumb.scss */
.breadcrumb-none {
  height: 10px;
}

/******************************************************************************
  Main
*******************************************************************************/
/* line 4, ../sass/_main.scss */
#main {
  padding: 80px 0;
}
/* line 7, ../sass/_main.scss */
#main.no-padding {
  padding: 0;
}

/* line 11, ../sass/_main.scss */
.page-header {
  border-bottom: 1px solid #e1e1e1;
  margin: 0 0 80px;
  padding: 0;
}
/* line 16, ../sass/_main.scss */
.page-header .title {
  font-weight: normal;
  margin: -4px 0 18px;
  padding: 0;
  position: relative;
}
/* line 22, ../sass/_main.scss */
.page-header .container {
  position: relative;
}
/* line 25, ../sass/_main.scss */
.page-header .container:before {
  background: #1e1e1e;
  bottom: 0px;
  content: "";
  display: block;
  height: 1px;
  left: 15px;
  margin: 0 0 -1px;
  position: absolute;
  width: 40px;
}
/* line 37, ../sass/_main.scss */
.page-header .page-description {
  padding: 0 0 18px;
}

/* line 41, ../sass/_main.scss */
.title-box {
  border-bottom-width: 1px;
  margin-bottom: 40px;
  position: relative;
}
/* line 46, ../sass/_main.scss */
.title-box:before {
  background: #1e1e1e;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  margin: 0 0 -1px;
  position: absolute;
  width: 40px;
}
/* line 57, ../sass/_main.scss */
.title-box.text-right:before {
  left: auto;
  right: 0;
}
/* line 61, ../sass/_main.scss */
.title-box.text-center:before {
  left: 50%;
  margin-left: -20px;
}
/* line 65, ../sass/_main.scss */
.title-box .title {
  color: #7f7f7f;
  font-size: 18px;
  font-weight: normal;
  margin: 0 0 12px 0;
  padding: 14px 0 0;
}
/* line 72, ../sass/_main.scss */
.title-box h1.title,
.title-box .h1.title {
  font-size: 24px;
}
/* line 76, ../sass/_main.scss */
.title-box .btn {
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  right: 0;
  top: 0;
}
/* line 84, ../sass/_main.scss */
.title-box .btn .glyphicon {
  margin: -1px 0 0 3px;
}
/* line 88, ../sass/_main.scss */
.title-box.title-white .title {
  color: #fff;
}

/* line 92, ../sass/_main.scss */
.title-box.no-margin,
.no-margin {
  margin-bottom: 0 !important;
}

/* line 96, ../sass/_main.scss */
.title-box.title-white,
.page-header.white {
  border-color: rgba(255, 255, 255, 0.3);
}

/* line 100, ../sass/_main.scss */
.title-box.title-white:before,
.page-header.white .container:before {
  background: #fff;
}

/******************************************************************************
  Carousels
*******************************************************************************/
/* line 5, ../sass/_carousels.scss */
.carousel .col-sm-1,
.carousel .col-sm-2,
.carousel .col-sm-3,
.carousel .col-sm-4,
.carousel .col-sm-5,
.carousel .col-sm-6,
.carousel .col-sm-7,
.carousel .col-sm-8,
.carousel .col-sm-9,
.carousel .col-sm-10,
.carousel .col-sm-11,
.carousel .col-sm-12 {
  display: block;
  float: left;
}
/* line 20, ../sass/_carousels.scss */
.carousel .col-md-1 {
  max-width: 80px;
}
@media (min-width: 1200px) {
  /* line 20, ../sass/_carousels.scss */
  .carousel .col-md-1 {
    max-width: 100px;
  }
}
/* line 26, ../sass/_carousels.scss */
.carousel .col-md-2 {
  max-width: 161px;
}
@media (min-width: 1200px) {
  /* line 26, ../sass/_carousels.scss */
  .carousel .col-md-2 {
    max-width: 200px;
  }
}
/* line 32, ../sass/_carousels.scss */
.carousel .col-md-3 {
  max-width: 242px;
}
@media (min-width: 1200px) {
  /* line 32, ../sass/_carousels.scss */
  .carousel .col-md-3 {
    max-width: 300px;
    width: 300px;
  }
}
/* line 39, ../sass/_carousels.scss */
.carousel .col-md-4 {
  max-width: 323px;
}
@media (min-width: 1200px) {
  /* line 39, ../sass/_carousels.scss */
  .carousel .col-md-4 {
    max-width: 400px;
  }
}
/* line 45, ../sass/_carousels.scss */
.carousel .col-md-5 {
  max-width: 404px;
}
@media (min-width: 1200px) {
  /* line 45, ../sass/_carousels.scss */
  .carousel .col-md-5 {
    max-width: 500px;
  }
}
/* line 51, ../sass/_carousels.scss */
.carousel .col-md-6 {
  max-width: 485px;
}
@media (min-width: 1200px) {
  /* line 51, ../sass/_carousels.scss */
  .carousel .col-md-6 {
    max-width: 600px;
  }
}
/* line 57, ../sass/_carousels.scss */
.carousel .col-md-7 {
  max-width: 565px;
}
@media (min-width: 1200px) {
  /* line 57, ../sass/_carousels.scss */
  .carousel .col-md-7 {
    max-width: 700px;
  }
}
/* line 63, ../sass/_carousels.scss */
.carousel .col-md-8 {
  max-width: 646px;
}
@media (min-width: 1200px) {
  /* line 63, ../sass/_carousels.scss */
  .carousel .col-md-8 {
    max-width: 800px;
  }
}
/* line 69, ../sass/_carousels.scss */
.carousel .col-md-9 {
  max-width: 727px;
}
@media (min-width: 1200px) {
  /* line 69, ../sass/_carousels.scss */
  .carousel .col-md-9 {
    max-width: 900px;
  }
}
/* line 75, ../sass/_carousels.scss */
.carousel .col-md-10 {
  max-width: 808px;
}
@media (min-width: 1200px) {
  /* line 75, ../sass/_carousels.scss */
  .carousel .col-md-10 {
    max-width: 1000px;
  }
}
/* line 81, ../sass/_carousels.scss */
.carousel .col-md-11 {
  max-width: 889px;
}
@media (min-width: 1200px) {
  /* line 81, ../sass/_carousels.scss */
  .carousel .col-md-11 {
    max-width: 1100px;
  }
}
/* line 87, ../sass/_carousels.scss */
.carousel .col-md-12 {
  max-width: 970px;
}
@media (min-width: 1200px) {
  /* line 87, ../sass/_carousels.scss */
  .carousel .col-md-12 {
    max-width: 1200px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 94, ../sass/_carousels.scss */
  .carousel .col-sm-1 {
    max-width: 62px;
  }
  /* line 97, ../sass/_carousels.scss */
  .carousel .col-sm-2 {
    max-width: 125px;
  }
  /* line 100, ../sass/_carousels.scss */
  .carousel .col-sm-3 {
    max-width: 187px;
  }
  /* line 103, ../sass/_carousels.scss */
  .carousel .col-sm-4 {
    max-width: 250px;
  }
  /* line 106, ../sass/_carousels.scss */
  .carousel .col-sm-5 {
    max-width: 31px;
  }
  /* line 109, ../sass/_carousels.scss */
  .carousel .col-sm-6 {
    max-width: 375px;
  }
  /* line 112, ../sass/_carousels.scss */
  .carousel .col-sm-7 {
    max-width: 437px;
  }
  /* line 115, ../sass/_carousels.scss */
  .carousel .col-sm-8 {
    max-width: 500px;
  }
  /* line 118, ../sass/_carousels.scss */
  .carousel .col-sm-9 {
    max-width: 562px;
  }
  /* line 121, ../sass/_carousels.scss */
  .carousel .col-sm-10 {
    max-width: 625px;
  }
  /* line 124, ../sass/_carousels.scss */
  .carousel .col-sm-11 {
    max-width: 687px;
  }
  /* line 127, ../sass/_carousels.scss */
  .carousel .col-sm-12 {
    max-width: 750px;
  }
}
@media (max-width: 767px) {
  /* line 132, ../sass/_carousels.scss */
  .carousel .col-sm-1,
  .carousel .col-sm-2,
  .carousel .col-sm-3,
  .carousel .col-sm-4 {
    min-width: 160px;
  }
  /* line 138, ../sass/_carousels.scss */
  .carousel .col-sm-5,
  .carousel .col-sm-6,
  .carousel .col-sm-7,
  .carousel .col-sm-8,
  .carousel .col-sm-9,
  .carousel .col-sm-10,
  .carousel .col-sm-11,
  .carousel .col-sm-12 {
    min-width: 300px;
  }
  /* line 148, ../sass/_carousels.scss */
  .carousel .col-md-1,
  .carousel .col-md-2,
  .carousel .col-md-3,
  .carousel .col-md-4,
  .carousel .col-xs-1,
  .carousel .col-xs-2,
  .carousel .col-xs-3,
  .carousel .col-xs-4,
  .carousel .col-xs-5,
  .carousel .col-xs-6,
  .carousel .col-xs-7,
  .carousel .col-xs-8,
  .carousel .col-xs-9,
  .carousel .col-xs-10,
  .carousel .col-xs-11,
  .carousel .col-xs-12 {
    max-width: 300px;
  }
}
/* line 168, ../sass/_carousels.scss */
.carousel [class*="span"].pull-right {
  float: right;
}

/* line 173, ../sass/_carousels.scss */
.carousel-box > * {
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 176, ../sass/_carousels.scss */
.carousel-box .next,
.carousel-box .prev {
  background-color: #ccc;
  border-radius: 3px;
  color: #fbfbfb;
  display: block;
  float: right;
  height: 40px;
  line-height: 40px;
  margin: 0 0 0 10px;
  text-align: center;
  text-decoration: none;
  width: 40px;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 193, ../sass/_carousels.scss */
.carousel-box .next:hover,
.carousel-box .prev:hover {
  background-color: #000;
  color: #efefef;
  text-decoration: none;
  -webkit-transition: color .2s linear, background-color .2s linear;
          transition: color .2s linear, background-color .2s linear;
}
/* line 201, ../sass/_carousels.scss */
.carousel-box .next:hover polygon,
.carousel-box .prev:hover polygon {
  fill: #efefef;
}
/* line 205, ../sass/_carousels.scss */
.carousel-box .next *,
.carousel-box .prev * {
  vertical-align: middle;
}
/* line 210, ../sass/_carousels.scss */
.carousel-box .next svg {
  margin-left: 2px;
}
/* line 215, ../sass/_carousels.scss */
.carousel-box .prev svg {
  margin-right: 2px;
}
/* line 220, ../sass/_carousels.scss */
.carousel-box.no-nav .next,
.carousel-box.no-nav .prev {
  display: none;
}
/* line 225, ../sass/_carousels.scss */
.carousel-box .pagination {
  margin-bottom: 0;
  text-align: center;
  width: 100%;
}
/* line 230, ../sass/_carousels.scss */
.carousel-box .pagination a {
  background: #ececec;
}
/* line 233, ../sass/_carousels.scss */
.carousel-box .pagination a:hover {
  background: #000;
}
/* line 236, ../sass/_carousels.scss */
.carousel-box .pagination a.selected {
  background: #ccc;
  background: rgba(0, 0, 0, 0.3);
}
/* line 242, ../sass/_carousels.scss */
.carousel-box.no-pagination .pagination {
  display: none;
}
/* line 245, ../sass/_carousels.scss */
.carousel-box.load {
  background-image: url("../img/svg/loader.svg"), none;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 40px;
}
/* line 251, ../sass/_carousels.scss */
.carousel-box.load > * {
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 256, ../sass/_carousels.scss */
.carousel-box.load .carousel > a + a,
.carousel-box.load .carousel > div + div {
  visibility: hidden;
}

/* line 263, ../sass/_carousels.scss */
.carousel-links a {
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 266, ../sass/_carousels.scss */
.carousel-links a:hover {
  opacity: .8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}

/* line 273, ../sass/_carousels.scss */
.caroufredsel_wrapper {
  margin-bottom: 0 !important;
}

/* line 276, ../sass/_carousels.scss */
.overflow {
  overflow: hidden;
}

/* line 280, ../sass/_carousels.scss */
.switches a {
  background: #fff;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 7px;
  display: inline-block;
  height: 14px;
  margin: 0 3px;
  width: 14px;
  -webkit-transition: all .3s linear;
          transition: all .3s linear;
  text-indent: -9999px;
  vertical-align: top;
}
/* line 292, ../sass/_carousels.scss */
.content .switches a {
  width: 11px;
  height: 11px;
}
/* line 296, ../sass/_carousels.scss */
.switches a:hover {
  background: #fff;
  -webkit-transition: all .3s linear;
          transition: all .3s linear;
}
/* line 300, ../sass/_carousels.scss */
.switches a.selected {
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
  -webkit-transition: all .3s linear;
          transition: all .3s linear;
}
/* line 305, ../sass/_carousels.scss */
.switches a span {
  display: none;
}

/******************************************************************************
  Sliders
*******************************************************************************/
/* line 4, ../sass/_sliders.scss */
.slider {
  background: #f2f2f2;
  padding: 54px 0;
}
/* line 8, ../sass/_sliders.scss */
.slider > .container {
  position: relative;
}
/* line 11, ../sass/_sliders.scss */
.slider .slid {
  position: relative;
}
/* line 14, ../sass/_sliders.scss */
.slider .slid .slid-img {
  max-height: 550px;
  z-index: 1;
}
/* line 19, ../sass/_sliders.scss */
.slider .slid-content {
  bottom: 93px;
  color: #7b7b7b;
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
  padding: 38px 40px 0;
  position: absolute;
  right: 15px;
  top: 0;
  z-index: 3;
}
@media (min-width: 992px) and (max-width: 1199px) {
  /* line 19, ../sass/_sliders.scss */
  .slider .slid-content {
    width: 400px;
  }
}
/* line 34, ../sass/_sliders.scss */
.content .slider .slid-content {
  bottom: 84px;
  padding: 28px 30px 0;
  right: 8px;
  width: 47.333333%;
}
/* line 40, ../sass/_sliders.scss */
.slider .slid-content .title {
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 34px;
}
@media (max-width: 480px) {
  /* line 40, ../sass/_sliders.scss */
  .slider .slid-content .title {
    max-height: 42px;
    overflow: hidden;
  }
}
/* line 50, ../sass/_sliders.scss */
.slider .slid-content .descriptions {
  margin: 0 0 37px 0;
}
/* line 53, ../sass/_sliders.scss */
.slider .slid-content .btn {
  background: #f8f8f8;
  color: #1e1e1e;
  font-weight: bold;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}
/* line 60, ../sass/_sliders.scss */
.slider .slid-content .btn:hover {
  background: #fff;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}
/* line 67, ../sass/_sliders.scss */
.slider .slider-nav {
  background-color: #1e1e1e;
  background-color: rgba(30, 30, 30, 0.97);
  bottom: 0;
  position: absolute;
  right: 15px;
  top: 0;
  z-index: 2;
}
@media (min-width: 992px) and (max-width: 1199px) {
  /* line 67, ../sass/_sliders.scss */
  .slider .slider-nav {
    width: 400px;
  }
}
/* line 79, ../sass/_sliders.scss */
.content .slider .slider-nav {
  right: 0;
  width: 47.333333%;
}
/* line 83, ../sass/_sliders.scss */
.slider .slider-nav .nav-box {
  bottom: 33px;
  left: 23px;
  position: absolute;
  right: 23px;
  text-align: center;
  z-index: 4;
}
/* line 91, ../sass/_sliders.scss */
.content .slider .slider-nav .nav-box {
  bottom: 30px;
}
/* line 95, ../sass/_sliders.scss */
.slider .slider-nav .prev,
.slider .slider-nav .next {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  color: #838282;
  display: block;
  height: 60px;
  left: 0;
  line-height: 60px;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  text-align: center;
  text-decoration: none;
  visibility: hidden;
  width: 60px;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}
/* line 117, ../sass/_sliders.scss */
.content .slider .slider-nav .prev, .content
.slider .slider-nav .next {
  height: 36px;
  line-height: 36px;
  width: 36px;
}
/* line 122, ../sass/_sliders.scss */
.slider .slider-nav .prev *,
.slider .slider-nav .next * {
  vertical-align: middle;
}
/* line 125, ../sass/_sliders.scss */
.slider .slider-nav .prev:hover,
.slider .slider-nav .next:hover {
  background-color: #fff;
  color: #a0a0a0;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}
/* line 131, ../sass/_sliders.scss */
.slider .slider-nav .prev:hover polygon,
.slider .slider-nav .next:hover polygon {
  fill: #9f9f9f;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}
/* line 138, ../sass/_sliders.scss */
.slider .slider-nav .next {
  left: auto;
  margin-left: 2px;
  right: 0;
}
/* line 143, ../sass/_sliders.scss */
.slider .slider-nav .prev {
  margin-right: 2px;
}
/* line 146, ../sass/_sliders.scss */
.slider .slider-nav .pagination {
  padding: 0 65px;
  text-align: center;
}
/* line 150, ../sass/_sliders.scss */
.content .slider .slider-nav .pagination {
  margin: 5px 0;
}
/* line 156, ../sass/_sliders.scss */
.slider.load .sliders-box > .col-md-12 + .col-md-12 {
  display: none;
}

/* line 161, ../sass/_sliders.scss */
.slider .container:hover .slider-nav .prev,
.slider .container:hover .slider-nav .next,
.page-slider:hover .slider-nav .prev,
.page-slider:hover .slider-nav .next {
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}

/* line 171, ../sass/_sliders.scss */
.progressive-slider .slider-nav .pagination a {
  vertical-align: middle;
}

/* line 174, ../sass/_sliders.scss */
.page-slider {
  background: none;
  padding: 0;
  position: relative;
}

/* line 180, ../sass/_sliders.scss */
.progressive-slider-two .sliders-container {
  margin: 0 -15px 0 15px;
  padding: 0;
  position: relative;
}
/* line 185, ../sass/_sliders.scss */
.progressive-slider-two .pagination {
  bottom: 0;
  left: 0;
  margin: 0 0 15px;
  position: absolute;
  right: 0;
  text-align: center;
}
/* line 193, ../sass/_sliders.scss */
.progressive-slider-two .pagination a {
  background: #1e1e1e;
  background: rgba(30, 30, 30, 0.5);
}
/* line 197, ../sass/_sliders.scss */
.progressive-slider-two .pagination a:hover, .progressive-slider-two .pagination a.selected {
  background: #1e1e1e;
}
/* line 203, ../sass/_sliders.scss */
.progressive-slider-two .slider-banners {
  display: table;
  height: 100%;
  table-layout: fixed;
}
/* line 208, ../sass/_sliders.scss */
.progressive-slider-two .slider-banners .banner {
  background: #f6a232;
  display: table-row;
  height: 33.33333333%;
}
/* line 213, ../sass/_sliders.scss */
.progressive-slider-two .slider-banners .banner:nth-child(1) {
  background: #ee485c;
}
/* line 216, ../sass/_sliders.scss */
.progressive-slider-two .slider-banners .banner:nth-child(2) {
  background: #65a93c;
}
/* line 222, ../sass/_sliders.scss */
.progressive-slider-two.load .sliders-box img + img {
  display: none;
}

/* line 227, ../sass/_sliders.scss */
.progressive-slider-three .slider-wrapper {
  border: solid 6px #fff;
  border-radius: 6px;
}

/* line 231, ../sass/_sliders.scss */
.progressive-slider-three.load .slider-wrapper img + img,
.progressive-slider-four.load .slider-wrapper img + img {
  display: none;
}

/* line 235, ../sass/_sliders.scss */
.progressive-slider-four {
  background: none;
  padding: 0;
  position: relative;
}
/* line 240, ../sass/_sliders.scss */
.progressive-slider-four .prev,
.progressive-slider-four .next {
  color: #fff;
  font-size: 40px;
  left: 30px;
  line-height: 1;
  margin-top: -20px;
  position: absolute;
  top: 50%;
}
/* line 250, ../sass/_sliders.scss */
.progressive-slider-four .next {
  left: auto;
  right: 30px;
}

/* line 255, ../sass/_sliders.scss */
.content .progressive-slider .container {
  padding: 0;
  width: 100% !important;
}

/* line 259, ../sass/_sliders.scss */
.content .slider.progressive-slider {
  padding: 0;
}

/* Slider Revolution */
/* line 263, ../sass/_sliders.scss */
.slider.rs-slider {
  background: none;
  border: 0 none;
  max-height: 500px;
  overflow: hidden;
  padding: 0;
  position: relative;
}
/* line 271, ../sass/_sliders.scss */
.slider.rs-slider.load {
  background-image: url("../img/svg/loader.svg"), none;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 40px;
  background-color: inherit;
  overflow: hidden;
}
/* line 279, ../sass/_sliders.scss */
.slider.rs-slider.load .tp-banner-container {
  visibility: hidden;
}
/* line 282, ../sass/_sliders.scss */
.slider.rs-slider .tp-revslider-slidesli {
  opacity: 0;
}
/* line 285, ../sass/_sliders.scss */
.slider.rs-slider .container {
  position: relative;
}
/* line 288, ../sass/_sliders.scss */
.slider.rs-slider .title {
  color: #fff;
  font-size: 40px;
  font-weight: normal;
  margin: 0;
  width: 800px;
}
/* line 295, ../sass/_sliders.scss */
.slider.rs-slider .description {
  color: #fff;
  font-size: 17px;
  line-height: 25px;
  margin: 0;
  padding: 0 15px;
  white-space: normal;
  width: 500px;
}
/* line 304, ../sass/_sliders.scss */
.slider.rs-slider .btn {
  color: #fff;
  white-space: nowrap;
}
/* line 308, ../sass/_sliders.scss */
.slider.rs-slider .btn.orang {
  background: #ea4e16;
  border-right: #ea4e16;
}
/* line 312, ../sass/_sliders.scss */
.slider.rs-slider .btn.cherry {
  background: #752033;
  border-color: #752033;
}
/* line 317, ../sass/_sliders.scss */
.slider.rs-slider .phone-text {
  color: #fff;
  font-size: 17px;
}
/* line 321, ../sass/_sliders.scss */
.slider.rs-slider .tp-bullets .tp-bullet {
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 7px;
  box-shadow: none;
  border: 0 none !important;
  display: inline-block;
  height: 14px;
  float: none;
  margin: 0 3px;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  vertical-align: top;
  width: 14px;
}
/* line 339, ../sass/_sliders.scss */
.slider.rs-slider .tp-bullets .tp-bullet:hover {
  background: #fff;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}
/* line 344, ../sass/_sliders.scss */
.slider.rs-slider .tp-bullets .tp-bullet.selected {
  background: rgba(255, 255, 255, 0.7);
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}
/* line 350, ../sass/_sliders.scss */
.slider.rs-slider .tparrows {
  background: #1e1e1e;
  background: rgba(30, 30, 30, 0.5);
  border-radius: 5px;
  cursor: pointer;
  height: 40px !important;
  width: 40px !important;
  -webkit-transition: background .3s linear;
  transition: background .3s linear;
  -webkit-transition: opacity 0.2s ease-out;
  -webkit-transform: inherit;
  z-index: 50 !important;
}
/* line 364, ../sass/_sliders.scss */
.slider.rs-slider .tparrows:before {
  font-family: 'revicons';
  color: #fff;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  margin-right: 0;
  margin-top: 1px;
  text-align: center;
  width: 40px;
  font-size: 19px;
}
/* line 378, ../sass/_sliders.scss */
.slider.rs-slider .tparrows:hover {
  background: #1e1e1e;
  -webkit-transition: background .3s linear;
  transition: background .3s linear;
}
/* line 384, ../sass/_sliders.scss */
.slider.rs-slider .tp-loader {
  -webkit-animation: none;
  animation: none;
  background-image: url("../img/svg/loader.svg"), none;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 40px;
  background-color: inherit;
  box-shadow: none;
  -webkit-box-shadow: none;
  height: 40px;
  margin: -20px 0 0 -20px;
  width: 40px;
  z-index: 700;
}
/* line 399, ../sass/_sliders.scss */
.slider.rs-slider.full-width {
  max-height: inherit;
}
/* line 402, ../sass/_sliders.scss */
.slider.rs-slider.full-width .title {
  font-size: 60px;
  line-height: 0.9;
}
/* line 406, ../sass/_sliders.scss */
.slider.rs-slider.full-width .btn-link {
  font-size: 14px !important;
  font-weight: bold;
  line-height: 31px;
  letter-spacing: 1px;
  padding: 0;
  text-decoration: none;
}
/* line 414, ../sass/_sliders.scss */
.slider.rs-slider.full-width .btn-link .fa {
  border: 2px solid #fff;
  border-radius: 50%;
  display: inline-block;
  font-size: 180%;
  height: 36px;
  line-height: 32px;
  margin-left: 10px;
  padding: 0 0 0 3px;
  text-align: center;
  vertical-align: middle;
  width: 36px;
}
/* line 429, ../sass/_sliders.scss */
.slider.rs-slider.full-width .btn-link:hover {
  opacity: .8;
}
/* line 433, ../sass/_sliders.scss */
.slider.rs-slider.full-width .tp-rightarrow,
.slider.rs-slider.full-width .tp-leftarrow {
  display: none !important;
}

/* line 439, ../sass/_sliders.scss */
.rs-slider ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* line 444, ../sass/_sliders.scss */
.tp-bannertimer {
  background: #777 !important;
  background: rgba(0, 0, 0, 0.1) !important;
  height: 5px !important;
}

/* line 449, ../sass/_sliders.scss */
.tp-bullets.simplebullets.navbar {
  height: 35px;
  padding: 0;
}

/* line 453, ../sass/_sliders.scss */
.tp-bullets.simplebullets .bullet.last {
  margin-right: 0px;
}

/* line 456, ../sass/_sliders.scss */
.tp-leftarrow:before {
  content: '\e824';
}

/* line 459, ../sass/_sliders.scss */
.tp-rightarrow:before {
  content: '\e825';
}

/* line 462, ../sass/_sliders.scss */
.slider4container .tparrows:before,
.slider4container .tparrows:hover,
.slider4container .tparrows {
  color: #000 !important;
}

/* line 467, ../sass/_sliders.scss */
.slider4container .tparrows {
  background: #fff !important;
  background: rgba(255, 255, 255, 0.5) !important;
}

/* line 471, ../sass/_sliders.scss */
.slider4container .tparrows:hover {
  background: #fff !important;
}

/* line 474, ../sass/_sliders.scss */
.layerslider {
  margin: 0 auto;
}

/* Slider Royal */
/* line 478, ../sass/_sliders.scss */
.royal-slider {
  background: none;
  padding: 0;
  width: 100%;
}
/* line 483, ../sass/_sliders.scss */
.royal-slider.load {
  background-image: url("../img/svg/loader.svg"), none;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 40px;
  background-color: inherit;
  overflow: hidden;
}

/* line 492, ../sass/_sliders.scss */
.rsMinW,
.rsMinW .rsOverflow,
.rsMinW .rsSlide,
.rsMinW .rsVideoFrameHolder,
.rsMinW .rsThumbs {
  background: #f2f2f2;
}

/* line 499, ../sass/_sliders.scss */
.coloredBlock {
  padding: 12px;
  background: rgba(255, 0, 0, 0.6);
  color: #FFF;
  width: 200px;
  left: 20%;
  top: 5%;
}

/* line 507, ../sass/_sliders.scss */
.infoBlock {
  position: absolute;
  top: 30px;
  right: 30px;
  left: auto;
  max-width: 25%;
  padding-bottom: 0;
  background: #FFF;
  background: rgba(255, 255, 255, 0.8);
  overflow: hidden;
  padding: 20px;
}
/* line 519, ../sass/_sliders.scss */
.infoBlock h4 {
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
  padding-bottom: 3px;
}
/* line 525, ../sass/_sliders.scss */
.infoBlock p {
  font-size: 14px;
  margin: 4px 0 0;
}
/* line 529, ../sass/_sliders.scss */
.infoBlock a {
  color: #FFF;
  text-decoration: underline;
}

/* line 534, ../sass/_sliders.scss */
.infoBlockLeftBlack {
  color: #FFF;
  background: #000;
  background: rgba(0, 0, 0, 0.75);
  left: 30px;
  right: auto;
}

.infoBlockRightBlack {
  color: #FFF;
  background: #000;
  background: rgba(0, 0, 0, 0.75);
  right: 30px;
  left: auto;
}

.infoBlockImage {
  padding: 5px 5px 5px 5px;
  background: rgba(0,0,0,0);
  bottom: 0px;
  top: auto;
}

/* line 541, ../sass/_sliders.scss */
.photosBy {
  position: absolute;
  line-height: 24px;
  font-size: 12px;
  background: #FFF;
  color: #000;
  padding: 0px 10px;
  position: absolute;
  left: 12px;
  bottom: 12px;
  top: auto;
  border-radius: 2px;
  z-index: 25;
}
/* line 555, ../sass/_sliders.scss */
.photosBy a {
  color: #000;
}

/* line 559, ../sass/_sliders.scss */
.fullWidth {
  max-width: 1400px;
  margin: 0 auto 24px;
}

/* line 563, ../sass/_sliders.scss */
.layer-slider {
  overflow: hidden;
}

/* Metro Box */
/* line 567, ../sass/_sliders.scss */
.metro-banners {
  background: #b3768e;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #b3768e), color-stop(100%, #54638e));
  background: -webkit-linear-gradient(left, #b3768e 0%, #54638e 100%);
  background: linear-gradient(to right, #b3768e 0%, #54638e 100%);
  padding: 54px 0 24px;
}
/* line 577, ../sass/_sliders.scss */
.metro-banners .banner {
  margin-bottom: 30px;
  text-align: center;
}
/* line 581, ../sass/_sliders.scss */
.metro-banners .banner img {
  box-shadow: 3px 3px 0px 0px rgba(50, 50, 50, 0.5);
}

/* line 588, ../sass/_sliders.scss */
.banner {
  display: block;
  text-align: center;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 594, ../sass/_sliders.scss */
.banner:hover {
  opacity: .8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

/******************************************************************************
  Sidebar
*******************************************************************************/
/* line 4, ../sass/_sidebar.scss */
.sidebar {
  /* Menu */
  /* Shop By */
  /* List */
  /* Specials */
  /* Community Poll */
  /* Compare Products */
  /* Newsletter */
  /* Recommended */
  /* Tags */
  /* Links */
  /* Calendar */
  /* Datepicker */
  /* Other */
}
/* line 5, ../sass/_sidebar.scss */
.sidebar .widget {
  margin: 0 0 80px;
}
/* line 8, ../sass/_sidebar.scss */
.sidebar .widget:last-child {
  margin-bottom: 0;
}
/* line 11, ../sass/_sidebar.scss */
.sidebar .widget ol,
.sidebar .widget ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
/* line 17, ../sass/_sidebar.scss */
.sidebar .widget header,
.sidebar .widget .title-block {
  border-bottom: 1px solid #e1e1e1;
  margin: 0 0 40px;
  padding: 0 0 15px;
  position: relative;
}
/* line 24, ../sass/_sidebar.scss */
.sidebar .widget header:before,
.sidebar .widget .title-block:before {
  background: #1e1e1e;
  bottom: 0px;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  margin: 0 0 -1px;
  position: absolute;
  width: 40px;
}
/* line 35, ../sass/_sidebar.scss */
.sidebar .widget header .title,
.sidebar .widget .title-block .title {
  color: #7f7f7f;
  font-size: 18px;
  font-weight: normal;
  line-height: 1;
  margin: 0;
}
/* line 43, ../sass/_sidebar.scss */
.sidebar .widget .subtitle {
  font-size: 14px;
  line-height: 1;
  margin: 0 0 25px;
}
/* line 49, ../sass/_sidebar.scss */
.sidebar .whats-news {
  margin-bottom: 40px;
  overflow: hidden;
}
/* line 53, ../sass/_sidebar.scss */
.sidebar .whats-news header {
  margin: 0;
}
/* line 58, ../sass/_sidebar.scss */
.sidebar .carousel-box header .title {
  line-height: 40px;
}
/* line 61, ../sass/_sidebar.scss */
.sidebar .carousel-box .product {
  display: block;
  float: left;
  margin: 40px 15px;
}
/* line 67, ../sass/_sidebar.scss */
.sidebar .action {
  overflow: hidden;
}
/* line 70, ../sass/_sidebar.scss */
.sidebar .action .clear-all {
  float: left;
  font-size: 14px;
  line-height: 40px;
  text-transform: none;
}
/* line 76, ../sass/_sidebar.scss */
.sidebar .action .clear-all.close {
  font-size: 20px;
  line-height: 38px;
}
/* line 80, ../sass/_sidebar.scss */
.sidebar .action .clear-all span {
  font-size: 14px;
  line-height: 39px;
  margin: 0 0 0 2px;
  vertical-align: middle;
}
/* line 87, ../sass/_sidebar.scss */
.sidebar .action .btn {
  float: right;
}
/* line 91, ../sass/_sidebar.scss */
.sidebar .more {
  color: #7f7f7f;
  font-size: 12px;
  margin: 5px 0 0;
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 98, ../sass/_sidebar.scss */
.sidebar .more:hover {
  opacity: .8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
  text-decoration: none;
}
/* line 107, ../sass/_sidebar.scss */
.sidebar .menu li {
  margin: 0 0 3px;
}
/* line 110, ../sass/_sidebar.scss */
.sidebar .menu li a {
  background-color: #f2f2f2;
  background-color: rgba(0, 0, 0, 0.05);
  color: #1e1e1e;
  display: block;
  padding: 9px 9px 9px 60px;
  position: relative;
  text-decoration: none;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 120, ../sass/_sidebar.scss */
.sidebar .menu li a:before {
  background: #e1e1e1;
  background: rgba(255, 255, 255, 0.4);
  bottom: 0;
  content: "";
  display: block;
  left: 45px;
  position: absolute;
  top: 0;
  width: 1px;
}
/* line 131, ../sass/_sidebar.scss */
.sidebar .menu li a:hover {
  background-color: #e9e9e9;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 137, ../sass/_sidebar.scss */
.sidebar .menu li > a .item-icon {
  left: 15px;
  position: absolute;
  top: 13px;
}
/* line 143, ../sass/_sidebar.scss */
.sidebar .menu li.active > a {
  background-color: #1e1e1e;
  color: #fff;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 148, ../sass/_sidebar.scss */
.sidebar .menu li.active > a:before {
  background: #444;
  background: rgba(255, 255, 255, 0.2);
}
/* line 156, ../sass/_sidebar.scss */
.sidebar .menu li.parent > a .open-sub {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}
/* line 164, ../sass/_sidebar.scss */
.sidebar .menu li.parent > a .open-sub:before, .sidebar .menu li.parent > a .open-sub:after {
  background: #1e1e1e;
  content: "";
  display: block;
  height: 2px;
  left: 0;
  margin: 19px 0 0 15px;
  position: absolute;
  top: 0;
  width: 16px;
}
/* line 176, ../sass/_sidebar.scss */
.sidebar .menu li.parent > a .open-sub:after {
  height: 16px;
  margin: 12px 0 0 22px;
  width: 2px;
}
/* line 186, ../sass/_sidebar.scss */
.sidebar .menu li.parent.active > a .open-sub:before, .sidebar .menu li.parent.active > a .open-sub:after {
  background: #fff;
}
/* line 190, ../sass/_sidebar.scss */
.sidebar .menu li.parent.active > a .open-sub:after {
  display: none;
}
/* line 198, ../sass/_sidebar.scss */
.sidebar .menu .sub {
  background-color: #f2f2f2;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 7px 0 4px 5px;
}
/* line 203, ../sass/_sidebar.scss */
.sidebar .menu .sub li {
  margin: 0;
}
/* line 206, ../sass/_sidebar.scss */
.sidebar .menu .sub li a {
  background: none;
  color: #7f7f7f;
  margin: 0;
  padding: 4px 9px 4px 60px;
}
/* line 212, ../sass/_sidebar.scss */
.sidebar .menu .sub li a:before {
  display: none;
}
/* line 215, ../sass/_sidebar.scss */
.sidebar .menu .sub li a:hover {
  background-color: #e9e9e9;
  background-color: rgba(0, 0, 0, 0.05);
}
/* line 221, ../sass/_sidebar.scss */
.sidebar .menu .sub li.parent > a .open-sub {
  left: 10px;
}
/* line 224, ../sass/_sidebar.scss */
.sidebar .menu .sub li.parent > a .open-sub:before, .sidebar .menu .sub li.parent > a .open-sub:after {
  background: #7f7f7f;
  margin: 14px 0 0 22px;
  width: 12px;
}
/* line 230, ../sass/_sidebar.scss */
.sidebar .menu .sub li.parent > a .open-sub:after {
  height: 12px;
  margin: 9px 0 0 27px;
  width: 2px;
}
/* line 237, ../sass/_sidebar.scss */
.sidebar .menu .sub li :not(.active) > a .item-icon {
  opacity: .5;
  filter: alpha(opacity=50);
}
/* line 242, ../sass/_sidebar.scss */
.sidebar .menu .sub .active > a {
  background: none;
  color: #000;
}
/* line 246, ../sass/_sidebar.scss */
.sidebar .menu .sub .sub {
  background: none;
}
/* line 253, ../sass/_sidebar.scss */
.sidebar .section + .section {
  border-top-width: 1px;
  margin: 20px 0 0;
  padding: 20px 0 0;
}
/* line 258, ../sass/_sidebar.scss */
.sidebar .section li {
  color: #7f7f7f;
  line-height: 18px;
  overflow: hidden;
}
/* line 263, ../sass/_sidebar.scss */
.sidebar .section li + li {
  margin: 12px 0 0;
}
/* line 266, ../sass/_sidebar.scss */
.sidebar .section li > a:not(.close) {
  color: #1e1e1e;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
  text-decoration: none;
}
/* line 271, ../sass/_sidebar.scss */
.sidebar .section li > a:not(.close):hover, .sidebar .section li > a:not(.close):focus {
  opacity: .8;
  text-decoration: none;
}
/* line 277, ../sass/_sidebar.scss */
.sidebar .section li .fa {
  color: #7f7f7f;
}
/* line 281, ../sass/_sidebar.scss */
.sidebar .section li > span:after {
  color: #7f7f7f;
  content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";
  display: inline-block;
  font-size: .5em;
  line-height: 40px;
  margin: -10px 16px 0 5px;
  pointer-events: none;
  position: absolute;
}
/* line 291, ../sass/_sidebar.scss */
.sidebar .section li > span span {
  color: #1e1e1e;
}
/* line 296, ../sass/_sidebar.scss */
.sidebar .section .selected li {
  margin: 0 0 8px;
  padding: 0 16px 0 0;
  position: relative;
}
/* line 301, ../sass/_sidebar.scss */
.sidebar .section .selected li .close {
  bottom: 2px;
  font-size: 20px;
  padding: 0 2px;
  position: absolute;
  right: 0px;
}
/* line 312, ../sass/_sidebar.scss */
.sidebar .list ul {
  margin-bottom: 0;
}
/* line 315, ../sass/_sidebar.scss */
.sidebar .list ul li {
  padding: 4px 0;
}
/* line 318, ../sass/_sidebar.scss */
.sidebar .list ul li a {
  color: #1e1e1e;
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 323, ../sass/_sidebar.scss */
.sidebar .list ul li a:hover, .sidebar .list ul li a:focus {
  opacity: .8;
  text-decoration: none;
}
/* line 329, ../sass/_sidebar.scss */
.sidebar .list ul li span {
  color: #7f7f7f;
}
/* line 337, ../sass/_sidebar.scss */
.sidebar .specials li {
  padding: 24px 0;
}
/* line 340, ../sass/_sidebar.scss */
.sidebar .specials li:first-child {
  padding-top: 0;
}
/* line 343, ../sass/_sidebar.scss */
.sidebar .specials li:last-child {
  padding-bottom: 0;
}
/* line 346, ../sass/_sidebar.scss */
.sidebar .specials li + li {
  border-top-width: 1px;
}
/* line 349, ../sass/_sidebar.scss */
.sidebar .specials li .product-image {
  background: #f7f7f7;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  float: left;
  height: 74px;
  margin: 0 15px 1px 0;
  overflow: hidden;
  width: 74px;
}
/* line 359, ../sass/_sidebar.scss */
.sidebar .specials li .product-name {
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  margin: 0 0 4px;
  padding-left: 85px;
}
/* line 366, ../sass/_sidebar.scss */
.sidebar .specials li .price-box {
  font-size: 16px;
  line-height: 22px;
  padding-left: 85px;
}
/* line 371, ../sass/_sidebar.scss */
.sidebar .specials li .rating-box {
  margin: 3px 0 14px 85px;
  overflow: hidden;
}
/* line 379, ../sass/_sidebar.scss */
.sidebar .poll strong {
  display: block;
  margin-bottom: 21px;
}
/* line 383, ../sass/_sidebar.scss */
.sidebar .poll ul {
  margin: 0;
  padding: 0 0 27px;
}
/* line 387, ../sass/_sidebar.scss */
.sidebar .poll li {
  margin-bottom: 11px;
}
/* line 393, ../sass/_sidebar.scss */
.sidebar .compare-products ul {
  margin: 0;
  padding: 0 0 17px;
}
/* line 397, ../sass/_sidebar.scss */
.sidebar .compare-products ul li {
  margin: 0 0 8px;
  overflow: hidden;
  padding: 0 16px 0 0;
  position: relative;
}
/* line 403, ../sass/_sidebar.scss */
.sidebar .compare-products ul li .title {
  color: #1e1e1e;
  padding-right: 2px;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
  text-decoration: none;
}
/* line 409, ../sass/_sidebar.scss */
.sidebar .compare-products ul li .title:hover, .sidebar .compare-products ul li .title:focus {
  opacity: .8;
  text-decoration: none;
}
/* line 414, ../sass/_sidebar.scss */
.sidebar .compare-products ul li .title:after {
  content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";
  display: inline-block;
  font-size: .5em;
  line-height: 40px;
  margin: -6px 16px 0 5px;
  pointer-events: none;
  position: absolute;
}
/* line 424, ../sass/_sidebar.scss */
.sidebar .compare-products ul li .close {
  bottom: 2px;
  font-size: 20px;
  padding: 0 2px;
  position: absolute;
  right: 0px;
}
/* line 436, ../sass/_sidebar.scss */
.sidebar .wishlist li {
  padding: 0 46px 0 0;
}
/* line 440, ../sass/_sidebar.scss */
.sidebar .wishlist li .title:after {
  margin-right: 40px !important;
}
/* line 445, ../sass/_sidebar.scss */
.sidebar .wishlist .add-cart {
  bottom: 3px;
  color: #7f7f7f;
  height: 16px;
  padding: 0 3px;
  position: absolute;
  right: 18px;
}
/* line 453, ../sass/_sidebar.scss */
.sidebar .wishlist .add-cart path {
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 457, ../sass/_sidebar.scss */
.sidebar .wishlist .add-cart:hover path {
  fill: #1e1e1e;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 466, ../sass/_sidebar.scss */
.sidebar .newsletter form {
  margin: 5px 0 0;
  position: relative;
}
/* line 470, ../sass/_sidebar.scss */
.sidebar .newsletter input {
  margin: 0;
  padding-right: 43px;
}
/* line 475, ../sass/_sidebar.scss */
.sidebar .newsletter input:focus + .submit {
  color: #000;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 481, ../sass/_sidebar.scss */
.sidebar .newsletter .submit {
  background: none;
  color: #ccc;
  height: 38px;
  outline: none;
  padding: 0;
  position: absolute;
  right: 1px;
  top: 1px;
  width: 38px;
}
/* line 492, ../sass/_sidebar.scss */
.sidebar .newsletter .submit .glyphicon {
  line-height: 20px;
}
/* line 498, ../sass/_sidebar.scss */
.sidebar .banners {
  overflow: hidden;
}
/* line 501, ../sass/_sidebar.scss */
.sidebar .banners .slide {
  float: left;
  max-width: 270px;
  margin-left: 15px;
  margin-right: 15px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}
@media (min-width: 992px) and (max-width: 1199px) {
  /* line 501, ../sass/_sidebar.scss */
  .sidebar .banners .slide {
    width: 220px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 501, ../sass/_sidebar.scss */
  .sidebar .banners .slide {
    width: 165px;
  }
}
/* line 516, ../sass/_sidebar.scss */
.sidebar .banners .banner-text {
  background: #1e1e1e;
  background: rgba(30, 30, 30, 0.65);
  bottom: 0;
  color: #fff;
  left: 0;
  min-height: 58px;
  padding: 20px;
  position: absolute;
  right: 0;
  text-align: right;
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 516, ../sass/_sidebar.scss */
  .sidebar .banners .banner-text {
    padding: 10px;
  }
}
/* line 531, ../sass/_sidebar.scss */
.sidebar .banners .banner-text .title {
  font-size: 20px;
  margin: 0;
}
/* line 535, ../sass/_sidebar.scss */
.sidebar .banners .banner-text p {
  line-height: 18px;
  margin: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 535, ../sass/_sidebar.scss */
  .sidebar .banners .banner-text p {
    display: none;
  }
}
/* line 546, ../sass/_sidebar.scss */
.sidebar .tags ul {
  margin: 0;
}
/* line 549, ../sass/_sidebar.scss */
.sidebar .tags li {
  float: left;
  margin: 0 3px 3px 0;
}
/* line 553, ../sass/_sidebar.scss */
.sidebar .tags a {
  background: #1e1e1e;
  background: rgba(30, 30, 30, 0.7);
  border-radius: 2px;
  color: #fff;
  display: block;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  padding: 0 5px;
  text-decoration: none;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 566, ../sass/_sidebar.scss */
.sidebar .tags a:hover {
  background: #1e1e1e;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 574, ../sass/_sidebar.scss */
.sidebar .links li {
  line-height: 21px;
}
/* line 577, ../sass/_sidebar.scss */
.sidebar .links a {
  color: #7f7f7f;
  text-decoration: none;
  -webkit-transition: color .2s linear;
          transition: color .2s linear;
}
/* line 582, ../sass/_sidebar.scss */
.sidebar .links a:hover {
  color: #000;
  -webkit-transition: color .2s linear;
          transition: color .2s linear;
}
/* line 589, ../sass/_sidebar.scss */
.sidebar .calendar-wrap {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  overflow: hidden;
  text-align: center;
}
/* line 595, ../sass/_sidebar.scss */
.sidebar .calendar-wrap table {
  margin: 0;
}
/* line 598, ../sass/_sidebar.scss */
.sidebar .calendar-wrap table td,
.sidebar .calendar-wrap table th {
  padding: 3px;
  text-align: center;
}
/* line 604, ../sass/_sidebar.scss */
.sidebar .calendar-wrap caption {
  background: #1e1e1e;
  color: #fff;
  font-size: 14px;
  padding: 5px;
  text-align: center;
}
/* line 612, ../sass/_sidebar.scss */
.sidebar .calendar-wrap tfoot td {
  border: 0 none;
}
/* line 615, ../sass/_sidebar.scss */
.sidebar .calendar-wrap tfoot a {
  text-decoration: none;
}
/* line 618, ../sass/_sidebar.scss */
.sidebar .calendar-wrap tfoot a:hover {
  opacity: .8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 629, ../sass/_sidebar.scss */
.sidebar .calendar .datepicker-box .datepicker {
  background: rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  padding: 0;
  width: 270px;
  max-width: 100%;
}
/* line 636, ../sass/_sidebar.scss */
.sidebar .calendar .datepicker-box .datepicker table {
  width: 100%;
  max-width: 100%;
}
/* line 644, ../sass/_sidebar.scss */
.sidebar .facebook-widget .fb-like-box {
  border: 1px solid #e1e1e1;
  max-width: 270px;
  width: 100%;
}
/* line 649, ../sass/_sidebar.scss */
.sidebar .facebook-widget .fb-like-box > span {
  max-width: 100% !important;
}

/* line 657, ../sass/_sidebar.scss */
.product-bottom .related-products header {
  border-bottom: 1px solid #e1e1e1;
  margin: 0 0 40px;
  padding: 0 0 15px;
  position: relative;
}
/* line 663, ../sass/_sidebar.scss */
.product-bottom .related-products header:before {
  background: #1e1e1e;
  bottom: 0px;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  margin: 0 0 -1px;
  position: absolute;
  width: 40px;
}
/* line 674, ../sass/_sidebar.scss */
.product-bottom .related-products header h3 {
  color: #7f7f7f;
  font-size: 18px;
  font-weight: normal;
  line-height: 1;
  margin: 0;
}

/* line 685, ../sass/_sidebar.scss */
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background: #037ac5;
}

/* line 711, ../sass/_sidebar.scss */
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled],
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background: #f89406;
  color: #fff;
}

/******************************************************************************
  Shop
*******************************************************************************/
/* line 5, ../sass/_shop.scss */
.product-name a {
  color: #1e1e1e;
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 10, ../sass/_shop.scss */
.product-name a:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}

/* line 17, ../sass/_shop.scss */
.product-image {
  background: #f2f2f2;
  border: 0 none;
  display: inline-block;
  line-height: 0;
  text-decoration: none;
}
/* line 24, ../sass/_shop.scss */
.product-image > img {
  width: 100%;
  -webkit-transition: opacity 0.2s linear;
          transition: opacity 0.2s linear;
}
/* line 29, ../sass/_shop.scss */
.product-image:hover > img {
  opacity: .9;
  filter: alpha(opacity=90);
  -webkit-transition: opacity 0.2s linear;
          transition: opacity 0.2s linear;
}
/* line 34, ../sass/_shop.scss */
.rotation .product-image:hover > img {
  opacity: 1;
  filter: alpha(opacity=100);
}

/* line 42, ../sass/_shop.scss */
.price-box .price-label {
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 14px;
}
/* line 48, ../sass/_shop.scss */
.price-box .emergence .price {
  display: none;
}
/* line 51, ../sass/_shop.scss */
.price-box .emergence .emergence-price {
  color: #7f7f7f;
  font-size: 12px;
  vertical-align: middle;
}

/* line 58, ../sass/_shop.scss */
.price-old {
  color: #7f7f7f;
  display: inline-block;
  margin-right: 6px;
  position: relative;
}
/* line 64, ../sass/_shop.scss */
.price-old:before {
  background: #f00;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
      transform: rotate(330deg);
  width: 100%;
}

/* line 78, ../sass/_shop.scss */
.sale {
  border-top: 56px solid #f89406;
  border-right: 56px solid transparent;
  display: block;
  height: 0;
  line-height: 22px;
  position: absolute;
  width: 0;
  z-index: 1;
  -webkit-transform: translateZ(0px);
}
/* line 89, ../sass/_shop.scss */
.sale:before {
  color: #fff;
  content: "Sale";
  display: block;
  font-size: 11px;
  font-weight: bold;
  margin: -46px 0 0 5px;
  position: absolute;
  text-transform: uppercase;
  -webkit-transform: rotate(315deg);
      -ms-transform: rotate(315deg);
          transform: rotate(315deg);
}
/* line 100, ../sass/_shop.scss */
.sale.top {
  border-top: 56px solid #738d00;
}
/* line 103, ../sass/_shop.scss */
.sale.top:before {
  content: "Top";
}
/* line 107, ../sass/_shop.scss */
.sale.new {
  border-top: 56px solid #c10841;
}
/* line 110, ../sass/_shop.scss */
.sale.new:before {
  content: "New";
}
/* line 114, ../sass/_shop.scss */
.sale.best {
  border-top: 56px solid #0098ca;
}
/* line 117, ../sass/_shop.scss */
.sale.best:before {
  content: "Best";
}

/* line 123, ../sass/_shop.scss */
.product-remove {
  color: #7f7f7f;
  height: 16px;
  text-decoration: none;
  width: 16px;
  -webkit-transition: color .2s linear;
          transition: color .2s linear;
}
/* line 130, ../sass/_shop.scss */
.product-remove:hover {
  color: #1e1e1e;
  -webkit-transition: color .2s linear;
          transition: color .2s linear;
}
/* line 134, ../sass/_shop.scss */
.product-remove path {
  fill: currentColor;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 138, ../sass/_shop.scss */
.product-remove i {
  font-size: 16px;
}

/* One product */
/* line 143, ../sass/_shop.scss */
.product {
  border-radius: 3px;
  display: inline-block;
  float: none;
  margin: 0 0 40px;
  min-width: 240px;
  overflow: hidden;
  position: relative;
  text-align: left;
  vertical-align: top;
}
@media (min-width: 992px) and (max-width: 1199px) {
  /* line 155, ../sass/_shop.scss */
  .product.col-md-3, .col-md-3 > .product {
    max-width: 242px;
  }
}
/* line 160, ../sass/_shop.scss */
.product.product-mini {
  min-width: 180px;
}
/* line 163, ../sass/_shop.scss */
.carousel .product.product-mini {
  width: 200px;
}
/* line 167, ../sass/_shop.scss */
.carousel .product.rotation {
  margin-top: 40px;
}
/* line 170, ../sass/_shop.scss */
.product .default {
  background: #f7f7f7;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}
/* line 176, ../sass/_shop.scss */
.product .product-image {
  border: 0 none;
  display: block;
  line-height: 0;
  margin: 0;
  text-decoration: none;
}
/* line 183, ../sass/_shop.scss */
.product .limit-offer {
  background: #1e1e1e;
  background: rgba(30, 30, 30, 0.9);
  bottom: 72px;
  color: #fff;
  font-size: 12px;
  left: 0;
  line-height: 1;
  padding: 10px 0 13px;
  position: absolute;
  right: 0;
  width: auto !important;
}
/* line 196, ../sass/_shop.scss */
.product .limit-offer .title {
  margin: 0 0 3px;
}
/* line 199, ../sass/_shop.scss */
.product .limit-offer .county-days-wrapper,
.product .limit-offer .county-hours-wrapper,
.product .limit-offer .county-minutes-wrapper,
.product .limit-offer .county-seconds-wrapper {
  box-sizing: content-box;
  display: inline-block;
  font-size: 30px;
  height: 26px;
  overflow: hidden;
  padding: 0 7px 2px 6px;
  position: relative;
  text-align: center;
}
/* line 212, ../sass/_shop.scss */
.product .limit-offer .county-days-wrapper span,
.product .limit-offer .county-hours-wrapper span,
.product .limit-offer .county-minutes-wrapper span,
.product .limit-offer .county-seconds-wrapper span {
  opacity: 1 !important;
}
/* line 216, ../sass/_shop.scss */
.product .limit-offer .county-days-wrapper:before,
.product .limit-offer .county-hours-wrapper:before,
.product .limit-offer .county-minutes-wrapper:before {
  background: #fff;
  background: rgba(255, 255, 255, 0.4);
  content: "";
  display: block;
  height: 11px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 1px;
}
/* line 229, ../sass/_shop.scss */
.product .limit-offer .county-days,
.product .limit-offer .county-hours,
.product .limit-offer .county-minutes,
.product .limit-offer .county-seconds {
  font-size: 30px;
  left: 0;
  right: 0;
}
/* line 237, ../sass/_shop.scss */
.product .limit-offer .county-label-days,
.product .limit-offer .county-label-hours,
.product .limit-offer .county-label-minutes,
.product .limit-offer .county-label-seconds {
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
  text-align: center;
  text-transform: lowercase;
}
/* line 246, ../sass/_shop.scss */
.product .limit-offer .county-label-days i,
.product .limit-offer .county-label-hours i,
.product .limit-offer .county-label-minutes i,
.product .limit-offer .county-label-seconds i {
  display: none;
}
/* line 250, ../sass/_shop.scss */
.product .limit-offer .loaded {
  box-sizing: content-box;
  padding-left: 7px;
  padding-right: 6px;
  position: absolute;
}
/* line 257, ../sass/_shop.scss */
.product .actions.not-rotation-actions {
  background: #1e1e1e;
  background: rgba(30, 30, 30, 0.9);
  bottom: 0;
  left: 0;
  right: 0;
  padding: 9px 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: bottom .2s linear;
          transition: bottom .2s linear;
  z-index: 1;
}
/* line 269, ../sass/_shop.scss */
.product .actions.not-rotation-actions + .product-description .limit-offer {
  -webkit-transition: bottom .2s linear;
          transition: bottom .2s linear;
}
/* line 274, ../sass/_shop.scss */
.product:hover .actions.not-rotation-actions {
  bottom: 72px;
  -webkit-transition: bottom .2s linear;
          transition: bottom .2s linear;
}
/* line 278, ../sass/_shop.scss */
.product:hover .actions.not-rotation-actions + .product-description .limit-offer {
  bottom: 144px;
  -webkit-transition: bottom .2s linear;
          transition: bottom .2s linear;
}
/* line 284, ../sass/_shop.scss */
.product .product-description {
  background: #f2f2f2;
  border-top: 1px solid #e1e1e1;
  color: #505050;
  display: table;
  font-size: 13px;
  height: 72px;
  line-height: 18px;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1;
}
/* line 297, ../sass/_shop.scss */
.product .product-description .vertical {
  display: table-cell;
  height: 72px;
  padding-left: 5px;
  padding-right: 5px;
  vertical-align: middle;
}
/* line 305, ../sass/_shop.scss */
.product .product-name {
  color: #1e1e1e;
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  margin: 0 0 4px;
}
/* line 312, ../sass/_shop.scss */
.product .product-name a {
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 316, ../sass/_shop.scss */
.product .product-name a:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 323, ../sass/_shop.scss */
.products-tab .product .product-name {
  text-transform: none;
}
/* line 327, ../sass/_shop.scss */
.product .product .price {
  font-weight: bold;
}
/* line 330, ../sass/_shop.scss */
.product .product-hover {
  background: #1e1e1e;
  border-radius: 3px;
  bottom: 0;
  color: #7f7f7f;
  display: none;
  font-size: 12px;
  line-height: 20px;
  left: 0;
  padding: 16px 20px 90px;
  position: absolute;
  right: 0;
  top: 0;
  width: auto !important;
}
/* line 345, ../sass/_shop.scss */
.product .product-hover .product-name {
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  margin: 0 0 7px;
}
/* line 351, ../sass/_shop.scss */
.product .product-hover .product-name a {
  color: #fff;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 355, ../sass/_shop.scss */
.product .product-hover .product-name a:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
  text-decoration: none;
}
/* line 363, ../sass/_shop.scss */
.product .product-hover .price {
  font-size: 18px;
  font-weight: normal;
  line-height: 1;
  margin: 0 0 24px;
}
/* line 369, ../sass/_shop.scss */
.product .product-hover .product-image {
  background: #f7f7f7;
  border: 0 none;
  border-radius: 2px;
  display: block;
  height: 70px;
  float: right;
  line-height: 0;
  margin: 4px 0 0 5px;
  padding: 0;
  position: relative;
  text-decoration: none;
  width: 70px;
  z-index: 1;
}
/* line 384, ../sass/_shop.scss */
.product .product-hover .product-image img {
  background: #f7f7f7;
  border-radius: 2px;
}
/* line 389, ../sass/_shop.scss */
.product .product-hover ul {
  margin: 0;
  max-height: 120px;
  overflow: hidden;
  padding: 0;
}
/* line 395, ../sass/_shop.scss */
.product .product-hover ul li {
  background-image: url("../img/svg/check-icon.svg"), none;
  background-repeat: no-repeat;
  background-position: 0 5px;
  line-height: 20px;
  list-style: none;
  padding: 0 0 0 15px;
  position: relative;
}
/* line 407, ../sass/_shop.scss */
.product:hover .product-hover {
  display: block;
  -webkit-animation: fadeIn 0.8s;
  animation: fadeIn 0.8s;
}
/* line 413, ../sass/_shop.scss */
.product.col-md-1 .product-hover, .product.col-md-2 .product-hover, .product.col-md-3 .product-hover, .product.col-md-4 .product-hover, .product.col-md-5 .product-hover, .product.col-md-6 .product-hover, .product.col-md-7 .product-hover, .product.col-md-8 .product-hover, .product.col-md-9 .product-hover, .product.col-md-10 .product-hover, .product.col-md-11 .product-hover, .product.col-md-11 .product-hover {
  left: 15px;
  right: 15px;
}
/* line 428, ../sass/_shop.scss */
.product .actions {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  bottom: 20px;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
/* line 436, ../sass/_shop.scss */
.product .actions a {
  background: #fff;
  display: inline-block;
  height: 54px;
  margin: 0 -2px;
  padding-top: 19px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  width: 54px;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 448, ../sass/_shop.scss */
.product .actions a + a {
  border-left-width: 1px;
}
/* line 451, ../sass/_shop.scss */
.product .actions a:hover {
  background: #f2f2f2;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 455, ../sass/_shop.scss */
.product .actions a i {
  color: #1e1e1e;
  line-height: 57px;
}
/* line 459, ../sass/_shop.scss */
.product .actions a i:before {
  font-size: 16px;
  width: auto;
}
/* line 465, ../sass/_shop.scss */
.product .actions .add-cart {
  border-radius: 3px 0 0 3px;
}
/* line 468, ../sass/_shop.scss */
.product .actions .add-compare {
  border-radius: 0 3px 3px 0;
}
/* line 473, ../sass/_shop.scss */
.product.product-danger .product-hover,
.product.product-danger .limit-offer,
.product.product-danger .actions.not-rotation-actions {
  background-color: #c10841;
}
/* line 478, ../sass/_shop.scss */
.product.product-danger .limit-offer,
.product.product-danger .actions.not-rotation-actions {
  background-color: rgba(193, 8, 65, 0.9);
}
/* line 482, ../sass/_shop.scss */
.product.product-danger .actions a svg path {
  fill: #c10841;
}
/* line 487, ../sass/_shop.scss */
.product.product-success .product-hover,
.product.product-success .limit-offer,
.product.product-success .actions.not-rotation-actions {
  background-color: #738d00;
}
/* line 492, ../sass/_shop.scss */
.product.product-success .limit-offer,
.product.product-success .actions.not-rotation-actions {
  background-color: rgba(115, 141, 0, 0.9);
}
/* line 496, ../sass/_shop.scss */
.product.product-success .actions a svg path {
  fill: #738d00;
}
/* line 501, ../sass/_shop.scss */
.product.product-info .product-hover,
.product.product-info .limit-offer,
.product.product-info .actions.not-rotation-actions {
  background-color: #0098ca;
}
/* line 506, ../sass/_shop.scss */
.product.product-info .limit-offer,
.product.product-info .actions.not-rotation-actions {
  background-color: rgba(0, 152, 202, 0.9);
}
/* line 510, ../sass/_shop.scss */
.product.product-info .actions a svg path {
  fill: #0098ca;
}
/* line 515, ../sass/_shop.scss */
.product.product-warning .product-hover,
.product.product-warning .limit-offer,
.product.product-warning .actions.not-rotation-actions {
  background-color: #f89406;
}
/* line 520, ../sass/_shop.scss */
.product.product-warning .limit-offer,
.product.product-warning .actions.not-rotation-actions {
  background-color: rgba(248, 148, 6, 0.9);
}
/* line 524, ../sass/_shop.scss */
.product.product-warning .actions a svg path {
  fill: #f89406;
}

/* line 530, ../sass/_shop.scss */
.double-product {
  min-width: 240px;
}
/* line 533, ../sass/_shop.scss */
.double-product .product.rotation {
  min-width: 210px;
}
/* line 536, ../sass/_shop.scss */
.carousel .double-product .product.rotation + .product.rotation {
  margin-top: 0;
}

/* line 541, ../sass/_shop.scss */
.county-label-days i,
.county-label-hours i,
.county-label-minutes i,
.county-label-seconds i {
  font-style: normal;
}

/* products list */
/* line 549, ../sass/_shop.scss */
.list .product {
  background: none;
  display: block;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  width: auto;
}
/* line 557, ../sass/_shop.scss */
.list .product .product-image {
  background: #f7f7f7;
  border-radius: 3px;
  margin: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
}
/* line 566, ../sass/_shop.scss */
.list .product .product-name {
  color: #1e1e1e;
  font-size: 20px;
  font-weight: normal;
  line-height: 30px;
  margin: 0 0 14px;
}
/* line 573, ../sass/_shop.scss */
.list .product .reviews-box {
  margin: 0 0 16px;
}
/* line 576, ../sass/_shop.scss */
.list .product .excerpt {
  color: #7f7f7f;
  font-size: 12px;
  line-height: 20px;
  margin: 0 0 15px;
}
/* line 582, ../sass/_shop.scss */
.list .product .price-box {
  margin: 0 0 30px;
}
/* line 585, ../sass/_shop.scss */
.list .product .price {
  font-size: 24px;
  font-weight: normal;
}
/* line 589, ../sass/_shop.scss */
.list .product .price-old {
  font-size: 18px;
}
/* line 592, ../sass/_shop.scss */
.list .product .actions {
  bottom: 0;
  position: relative;
  text-align: left;
}
/* line 597, ../sass/_shop.scss */
.list .product .actions a {
  background: #f2f2f2;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 601, ../sass/_shop.scss */
.list .product .actions a:hover {
  background: #1e1e1e;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 605, ../sass/_shop.scss */
.list .product .actions a:hover path {
  fill: #fff;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 610, ../sass/_shop.scss */
.list .product .actions a path {
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}

/* Products Tab */
/* line 619, ../sass/_shop.scss */
.products-tab .product.rotation {
  overflow: visible;
}
/* line 624, ../sass/_shop.scss */
.products-tab .nav-tabs > li > a {
  color: #7f7f7f;
  font-size: 18px;
  line-height: 33px;
}
/* line 631, ../sass/_shop.scss */
.products-tab .nav-tabs > .active > a,
.products-tab .nav-tabs > .active > a:hover,
.products-tab .nav-tabs > .active > a:focus {
  color: #7f7f7f;
  font-weight: normal;
}
/* line 639, ../sass/_shop.scss */
.products-tab .tab-content > .tab-pane {
  overflow: hidden;
  padding: 40px 0 0;
}

/* Product View */
/* line 645, ../sass/_shop.scss */
.zoomWindow {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

/* line 650, ../sass/_shop.scss */
.zoomContainer {
  height: auto !important;
}

/* line 654, ../sass/_shop.scss */
.product-page .image-box {
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}
/* line 659, ../sass/_shop.scss */
.product-page .image-box .general-img {
  background: #f7f7f7;
  border-radius: 3px;
  overflow: hidden;
}
/* line 664, ../sass/_shop.scss */
.product-page .image-box .thumblist-box {
  margin: 30px 0 0;
  max-height: 100px;
  overflow: hidden;
  padding: 0 54px;
  position: relative;
}
/* line 671, ../sass/_shop.scss */
.product-page .image-box .thumblist-box.load {
  opacity: 0;
}
/* line 674, ../sass/_shop.scss */
.product-page .image-box .thumblist-box .prev,
.product-page .image-box .thumblist-box .next {
  background-color: #ccc;
  border-radius: 3px;
  color: #fbfbfb;
  display: block;
  float: right;
  height: 40px;
  line-height: 40px;
  left: 0;
  margin: -20px 0 0 0;
  position: absolute;
  text-align: center;
  top: 50%;
  text-decoration: none;
  width: 40px;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 692, ../sass/_shop.scss */
.product-page .image-box .thumblist-box .prev:hover,
.product-page .image-box .thumblist-box .next:hover {
  background-color: #000;
  color: #efefef;
  text-decoration: none;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 698, ../sass/_shop.scss */
.product-page .image-box .thumblist-box .prev:hover polygon,
.product-page .image-box .thumblist-box .next:hover polygon {
  fill: #efefef;
}
/* line 702, ../sass/_shop.scss */
.product-page .image-box .thumblist-box .prev *,
.product-page .image-box .thumblist-box .next * {
  vertical-align: middle;
}
/* line 706, ../sass/_shop.scss */
.product-page .image-box .thumblist-box .next {
  left: auto;
  right: 0;
}
/* line 712, ../sass/_shop.scss */
.product-page .image-box #thumblist a {
  background: #f7f7f7;
  display: block;
  float: left;
  height: 100px;
  margin: 0 9px;
  width: 100px;
}
/* line 720, ../sass/_shop.scss */
.product-page .image-box #thumblist a img {
  border-radius: 2px;
  -webkit-transition: opacity 0.2s linear;
          transition: opacity 0.2s linear;
}
/* line 725, ../sass/_shop.scss */
.product-page .image-box #thumblist a:hover img {
  opacity: .8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity 0.2s linear;
          transition: opacity 0.2s linear;
}
/* line 734, ../sass/_shop.scss */
.product-page .reviews-box {
  margin: 0 0 38px;
}
/* line 737, ../sass/_shop.scss */
.product-page .description {
  color: #7f7f7f;
  font-size: 12px;
  line-height: 20px;
  margin: 0 0 32px;
}
/* line 744, ../sass/_shop.scss */
.product-page .col-md-7 .price-box {
  border-bottom-width: 1px;
  font-size: 48px;
  line-height: 1;
  margin: 0 0 40px;
  padding: 0 0 35px;
}
/* line 751, ../sass/_shop.scss */
.product-page .col-md-7 .price-box .price-old {
  font-size: 30px;
}
/* line 756, ../sass/_shop.scss */
.product-page .add-cart-form {
  margin: 0;
}
/* line 759, ../sass/_shop.scss */
.product-page .add-cart-form .add-cart {
  font-weight: bold;
  margin: 0 17px 40px 0;
  min-width: 290px;
  text-transform: uppercase;
}
/* line 765, ../sass/_shop.scss */
.product-page .add-cart-form .number {
  display: inline-block;
  margin-bottom: 40px;
  vertical-align: top;
}
/* line 770, ../sass/_shop.scss */
.product-page .add-cart-form .number label {
  color: #7f7f7f;
  font-weight: normal;
  line-height: 54px;
  margin: 0 7px 0 0;
  vertical-align: top;
}
/* line 777, ../sass/_shop.scss */
.product-page .add-cart-form .number input {
  color: #7f7f7f;
  display: inline-block;
  height: 54px;
  padding: 15px 12px;
  text-align: center;
  vertical-align: top;
  width: 80px;
}
/* line 786, ../sass/_shop.scss */
.product-page .add-cart-form .number .regulator {
  display: inline-block;
  vertical-align: top;
}
/* line 790, ../sass/_shop.scss */
.product-page .add-cart-form .number .regulator a {
  background: #ccc;
  border-radius: 2px;
  color: #fff;
  display: block;
  height: 24px;
  text-align: center;
  width: 24px;
  -webkit-transition: background-color .2s linear;
          transition: background-color .2s linear;
}
/* line 800, ../sass/_shop.scss */
.product-page .add-cart-form .number .regulator a:hover {
  background-color: #1e1e1e;
  background-color: rgba(30, 30, 30, 0.5);
  -webkit-transition: background-color .2s linear;
          transition: background-color .2s linear;
}
/* line 805, ../sass/_shop.scss */
.product-page .add-cart-form .number .regulator a .fa {
  line-height: 24px;
}
/* line 809, ../sass/_shop.scss */
.product-page .add-cart-form .number .regulator .number-down {
  margin: 6px 0 0 0;
}
/* line 815, ../sass/_shop.scss */
.product-page .availability-sku {
  font-size: 12px;
  line-height: 20px;
  margin: 0 0 36px;
}
/* line 820, ../sass/_shop.scss */
.product-page .availability-sku .availability span {
  color: #2ca073;
}
/* line 824, ../sass/_shop.scss */
.product-page .product-options-border {
  border-top: 1px solid #1e1e1e;
  padding: 40px 0 0;
}
/* line 828, ../sass/_shop.scss */
.product-page .product-options {
  font-size: 12px;
  line-height: 20px;
}
/* line 832, ../sass/_shop.scss */
.product-page .product-options .selectBox,
.product-page .product-options .select {
  margin: 0 0 10px;
}
/* line 836, ../sass/_shop.scss */
.product-page .product-options .selectBox:last-child,
.product-page .product-options .select:last-child {
  margin: 0;
}
/* line 842, ../sass/_shop.scss */
.product-page .product-options-table table {
  margin: 0 0 35px;
}
/* line 845, ../sass/_shop.scss */
.product-page .product-options-table table th,
.product-page .product-options-table table td {
  line-height: 20px;
  padding: 16px 25px 13px;
  text-align: left;
  vertical-align: middle;
}
/* line 852, ../sass/_shop.scss */
.product-page .product-options-table table .price {
  text-align: right;
  width: 150px;
}
/* line 856, ../sass/_shop.scss */
.product-page .product-options-table table td.price {
  font-size: 16px;
}
/* line 859, ../sass/_shop.scss */
.product-page .product-options-table table .qty {
  text-align: right;
  width: 70px;
}
/* line 863, ../sass/_shop.scss */
.product-page .product-options-table table .qty input {
  margin: 0;
  width: 50px;
}
/* line 868, ../sass/_shop.scss */
.product-page .product-options-table table td.qty {
  padding: 5px 25px;
}
/* line 873, ../sass/_shop.scss */
.product-page .product-options-samples {
  font-size: 12px;
  line-height: 20px;
  margin: 0 0 30px;
}
/* line 878, ../sass/_shop.scss */
.product-page .product-options-samples .title {
  display: block;
  font-size: 14px;
  line-height: 1;
  margin: 0 0 14px;
}
/* line 884, ../sass/_shop.scss */
.product-page .product-options-samples label {
  line-height: 20px;
  margin: 0;
}
/* line 888, ../sass/_shop.scss */
.product-page .product-options-samples label .new-checkbox {
  margin-top: 1px;
}
/* line 892, ../sass/_shop.scss */
.product-page .product-options-samples .required-box {
  font-size: 11px;
  line-height: 1;
  text-align: right;
}
/* line 897, ../sass/_shop.scss */
.product-page .product-options-samples .required {
  font-size: 11px;
  line-height: 1;
}
/* line 902, ../sass/_shop.scss */
.product-page .product-price-configured {
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 30px;
}
/* line 908, ../sass/_shop.scss */
.product-page .col-md-7 .actions {
  margin: 0 0 40px;
}
/* line 911, ../sass/_shop.scss */
.product-page .col-md-7 .actions a {
  background: #f2f2f2;
  display: inline-block;
  height: 54px;
  margin: 0 -2px;
  padding-top: 19px;
  text-align: center;
  text-decoration: none;
  width: 54px;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
}
/* line 923, ../sass/_shop.scss */
.product-page .col-md-7 .actions a path {
  fill: #1e1e1e;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 927, ../sass/_shop.scss */
.product-page .col-md-7 .actions a:hover {
  background: #1e1e1e;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 931, ../sass/_shop.scss */
.product-page .col-md-7 .actions a:hover path {
  fill: #fff;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 936, ../sass/_shop.scss */
.product-page .col-md-7 .actions a + a {
  border-left-width: 1px;
}
/* line 940, ../sass/_shop.scss */
.product-page .col-md-7 .actions .add-wishlist {
  border-radius: 3px 0 0 3px;
}
/* line 943, ../sass/_shop.scss */
.product-page .col-md-7 .actions .add-compare {
  border-radius: 0 3px 3px 0;
}
/* line 948, ../sass/_shop.scss */
/* changed margin-top from 80px to 30px TOSS-PRO */
.product-page .product-tab {
  margin: 80px 0;
  margin-top: 30px;
}
/* line 951, ../sass/_shop.scss */
.product-page .product-tab .tab-pane {
  padding: 36px 0 0;
}
/* line 954, ../sass/_shop.scss */
.product-page .product-tab .tab-pane .title {
  margin: 0 0 18px;
}

/* line 960, ../sass/_shop.scss */
.product-options-detail {
  background: #f2f2f2;
  font-size: 12px;
  line-height: 20px;
  padding: 27px 30px 30px;
}
/* line 966, ../sass/_shop.scss */
.product-options-detail .detail-box {
  margin: 0 0 25px;
}
/* line 969, ../sass/_shop.scss */
.product-options-detail .pull-right.required {
  font-size: 11px;
  line-height: 18px;
}
/* line 973, ../sass/_shop.scss */
.product-options-detail .title {
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 11px;
}
/* line 979, ../sass/_shop.scss */
.product-options-detail .checkbox,
.product-options-detail .radio {
  line-height: 20px;
  margin: 0;
}
/* line 984, ../sass/_shop.scss */
.product-options-detail .checkbox .new-checkbox,
.product-options-detail .checkbox .new-radio,
.product-options-detail .radio .new-checkbox,
.product-options-detail .radio .new-radio {
  margin-top: 0;
}
/* line 989, ../sass/_shop.scss */
.product-options-detail .qty {
  margin: 15px 0 0;
}
/* line 992, ../sass/_shop.scss */
.product-options-detail .qty label {
  float: left;
  font-size: 12px;
  line-height: 40px;
  margin: 0 12px 0 0;
}
/* line 998, ../sass/_shop.scss */
.product-options-detail .qty input {
  margin: 0;
  width: 48px;
}
/* line 1003, ../sass/_shop.scss */
.product-options-detail .price {
  font-size: 48px;
  line-height: 1;
}
/* line 1007, ../sass/_shop.scss */
.product-options-detail .add-cart {
  float: right;
  font-weight: bold;
  margin: -14px 17px 0 0;
  min-width: 290px;
  text-transform: uppercase;
}

/* Related Products */
/* line 1016, ../sass/_shop.scss */
.product-bottom {
  padding: 40px 0 0;
  overflow: hidden;
}
/* line 1021, ../sass/_shop.scss */
.product-bottom .related-products .select-all {
  margin: -4px 0 13px;
}
/* line 1024, ../sass/_shop.scss */
.product-bottom .related-products ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
/* line 1029, ../sass/_shop.scss */
.product-bottom .related-products ul li {
  padding: 24px 0;
}
/* line 1032, ../sass/_shop.scss */
.product-bottom .related-products ul li + li {
  border-top-width: 1px;
}
/* line 1035, ../sass/_shop.scss */
.product-bottom .related-products ul li .product-image {
  border: 1px solid #f5f5f5;
  background: #f7f7f7;
  float: left;
  height: 64px;
  margin: 0 15px 1px 0;
  padding: 2px;
  width: 64px;
}
/* line 1044, ../sass/_shop.scss */
.product-bottom .related-products ul li .product-name {
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  margin: 0 0 4px;
}
/* line 1050, ../sass/_shop.scss */
.product-bottom .related-products ul li .price-box {
  font-size: 16px;
  line-height: 22px;
}
/* line 1054, ../sass/_shop.scss */
.product-bottom .related-products ul li .button-box {
  margin: 10px 0 0;
  overflow: hidden;
  text-align: right;
}
/* line 1059, ../sass/_shop.scss */
.product-bottom .related-products ul li .button-box label,
.product-bottom .related-products ul li .button-box .wishlist {
  color: #ccc;
  float: right;
  margin: 0 0 0 10px;
}
/* line 1065, ../sass/_shop.scss */
.product-bottom .related-products ul li .button-box .new-checkbox {
  margin-top: 0;
}
/* line 1069, ../sass/_shop.scss */
.product-bottom .related-products ul li .button-box .wishlist svg path {
  fill: #ccc;
  -webkit-transition: fill .2s linear;
          transition: fill .2s linear;
}
/* line 1074, ../sass/_shop.scss */
.product-bottom .related-products ul li .button-box .wishlist:hover svg path {
  fill: #1e1e1e;
  -webkit-transition: fill .2s linear;
          transition: fill .2s linear;
}

/* Catalog */
/* line 1087, ../sass/_shop.scss */
#catalog .products {
  clear: both;
  margin-top: 20px;
}
/* line 1091, ../sass/_shop.scss */
#catalog .products.grid {
  text-align: center;
}
/* line 1095, ../sass/_shop.scss */
#catalog .category-img {
  background: #f2f2f2;
  margin: 0 0 80px;
  position: relative;
}
/* line 1100, ../sass/_shop.scss */
#catalog .category-img img {
  width: 100%;
}
/* line 1103, ../sass/_shop.scss */
#catalog .category-img .description {
  background: #1e1e1e;
  background: rgba(30, 30, 30, 0.7);
  bottom: 0;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  left: 0;
  padding: 35px 38px;
  position: absolute;
  right: 0;
}

/* line 1118, ../sass/_shop.scss */
.toolbar .grid-list {
  float: left;
}
/* line 1121, ../sass/_shop.scss */
.toolbar .grid-list .grid,
.toolbar .grid-list .list {
  background-color: #1e1e1e;
  background-color: rgba(30, 30, 30, 0.7);
  border-radius: 2px;
  display: block;
  float: left;
  height: 24px;
  margin: 0 7px 0 0;
  text-align: center;
  width: 24px;
}
/* line 1133, ../sass/_shop.scss */
.toolbar .grid-list .grid .glyphicon,
.toolbar .grid-list .list .glyphicon {
  color: #fff;
  font-size: 12px;
  margin: 5px 0 0;
  top: 0;
  vertical-align: top;
}
/* line 1141, ../sass/_shop.scss */
.toolbar .grid-list a.grid,
.toolbar .grid-list a.list {
  background: #ccc;
  -webkit-transition: background .2s linear;
          transition: background .2s linear;
}
/* line 1146, ../sass/_shop.scss */
.toolbar .grid-list a.grid:hover,
.toolbar .grid-list a.list:hover {
  background: #1e1e1e;
  -webkit-transition: background .2s linear;
          transition: background .2s linear;
}
/* line 1152, ../sass/_shop.scss */
.toolbar .sort-catalog {
  float: left;
  margin: 0 17px 0 13px;
}
/* line 1156, ../sass/_shop.scss */
.toolbar .sort-catalog .dropdown-toggle {
  background-color: #1e1e1e;
  background-color: rgba(30, 30, 30, 0.7);
  color: #ccc;
  z-index: 26;
}
/* line 1162, ../sass/_shop.scss */
.toolbar .sort-catalog .dropdown-toggle span {
  color: #f7f7f7;
}
/* line 1167, ../sass/_shop.scss */
.toolbar .sort-catalog .open .dropdown-toggle span {
  color: #7f7f7f;
}
/* line 1172, ../sass/_shop.scss */
.toolbar .up-down {
  background-color: #1e1e1e;
  background-color: rgba(30, 30, 30, 0.7);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  box-shadow: none;
  font-size: 10px;
  height: 24px;
  margin: 0 0 0 3px;
  padding: 0;
  vertical-align: top;
  width: 22px;
}
/* line 1186, ../sass/_shop.scss */
.toolbar .up-down:before {
  content: "\f062";
}
/* line 1190, ../sass/_shop.scss */
.toolbar .up-down.active:before {
  content: "\f063";
}
/* line 1195, ../sass/_shop.scss */
.toolbar .per-page {
  color: #7f7f7f;
  font-size: 12px;
  line-height: 24px;
  margin: 0 0 0 3px;
}
/* line 1201, ../sass/_shop.scss */
.toolbar .price-regulator {
  color: #7f7f7f;
  font-size: 12px;
  width: 323px;
}
/* line 1206, ../sass/_shop.scss */
.toolbar .price-regulator b {
  color: #1e1e1e;
  display: inline-block;
  margin-top: 5px;
}
/* line 1211, ../sass/_shop.scss */
.toolbar .price-regulator .layout-slider {
  float: right;
  margin-top: 5px;
  width: 270px;
}
/* line 1217, ../sass/_shop.scss */
.toolbar .grid-list,
.toolbar .sort-catalog {
  margin-bottom: 20px;
}

/* Shopping Cart */
/* line 1223, ../sass/_shop.scss */
.shopping-cart-table.table {
  border-bottom-width: 0;
  margin: 0;
}
/* line 1227, ../sass/_shop.scss */
.shopping-cart-table.table thead th {
  padding: 16px 30px 12px;
  text-align: left;
  vertical-align: top;
}
/* line 1232, ../sass/_shop.scss */
.shopping-cart-table.table tbody td {
  font-size: 12px;
  line-height: 20px;
  padding: 30px;
}
/* line 1237, ../sass/_shop.scss */
.shopping-cart-table.table .td-images {
  width: 131px;
}
/* line 1240, ../sass/_shop.scss */
.shopping-cart-table.table .product-image {
  background: #f2f2f2;
  border-radius: 3px;
  display: block;
  width: 70px;
}
/* line 1246, ../sass/_shop.scss */
.shopping-cart-table.table .td-name {
  text-align: left;
}
/* line 1249, ../sass/_shop.scss */
.shopping-cart-table.table .td-name .product-name {
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
}
/* line 1255, ../sass/_shop.scss */
.shopping-cart-table.table .td-edit {
  padding-left: 20px;
  padding-right: 20px;
  width: 92px;
}
/* line 1260, ../sass/_shop.scss */
.shopping-cart-table.table .td-price {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  width: 112px;
}
/* line 1266, ../sass/_shop.scss */
.shopping-cart-table.table .td-qty {
  min-width: 48px;
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
  width: 100px;
}
/* line 1273, ../sass/_shop.scss */
.shopping-cart-table.table .td-qty input {
  margin: 0;
  min-width: 40px;
  text-align: center;
}
/* line 1279, ../sass/_shop.scss */
.shopping-cart-table.table td.td-qty {
  padding-top: 19px;
}
/* line 1282, ../sass/_shop.scss */
.shopping-cart-table.table .td-total {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  width: 102px;
}
/* line 1288, ../sass/_shop.scss */
.shopping-cart-table.table .td-remove {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  width: 60px;
}

/* line 1295, ../sass/_shop.scss */
.shopping-cart-table-button.table {
  border-bottom-width: 1px;
  border-right-width: 0;
  margin: 0 0 40px;
}
/* line 1300, ../sass/_shop.scss */
.shopping-cart-table-button.table tbody td {
  border-left-width: 0;
  border-top-width: 0;
  height: 49px;
  padding: 0;
  text-align: left;
  vertical-align: middle;
}
/* line 1308, ../sass/_shop.scss */
.shopping-cart-table-button.table a {
  line-height: 22px;
}
/* line 1311, ../sass/_shop.scss */
.shopping-cart-table-button.table a i {
  font-size: 17px;
  line-height: 20px;
  vertical-align: top;
}
/* line 1317, ../sass/_shop.scss */
.shopping-cart-table-button.table .update {
  float: right;
}
/* line 1320, ../sass/_shop.scss */
.shopping-cart-table-button.table .update i {
  font-size: 12px;
}

/* line 1325, ../sass/_shop.scss */
#car-bottom {
  margin-bottom: 40px;
}
/* line 1328, ../sass/_shop.scss */
#car-bottom .car-bottom-box {
  font-size: 12px;
  line-height: 18px;
  margin: 0 auto 40px;
  max-width: 350px;
  padding: 27px 30px 30px;
}
/* line 1335, ../sass/_shop.scss */
#car-bottom .car-bottom-box h5 {
  margin: 0 0 17px;
}
/* line 1338, ../sass/_shop.scss */
#car-bottom .car-bottom-box p {
  line-height: 18px;
  margin: 0 0 18px;
}
/* line 1342, ../sass/_shop.scss */
#car-bottom .car-bottom-box label {
  line-height: 20px;
}
/* line 1345, ../sass/_shop.scss */
#car-bottom .car-bottom-box .btn {
  margin: 8px 0 0;
}
/* line 1349, ../sass/_shop.scss */
#car-bottom .total {
  padding-bottom: 26px;
  text-align: right;
}
/* line 1353, ../sass/_shop.scss */
#car-bottom .total table {
  margin: 0 0 32px auto;
}
/* line 1356, ../sass/_shop.scss */
#car-bottom .total table td {
  font-size: 13px;
  height: 35px;
  line-height: 24px;
  padding: 0 0 0 5px;
  vertical-align: top;
}
/* line 1363, ../sass/_shop.scss */
#car-bottom .total table .price {
  font-size: 18px;
}
/* line 1367, ../sass/_shop.scss */
#car-bottom .total table .tr-total td {
  font-size: 18px;
  line-height: 30px;
}
/* line 1371, ../sass/_shop.scss */
#car-bottom .total table .tr-total td:first-child {
  padding-right: 12px;
}
/* line 1375, ../sass/_shop.scss */
#car-bottom .total table .tr-total .price {
  font-size: 30px;
}
/* line 1380, ../sass/_shop.scss */
#car-bottom .total .checkout {
  font-weight: bold;
  margin: 0 0 19px;
  text-transform: uppercase;
}

/* Checkout */
/* line 1388, ../sass/_shop.scss */
#checkoutsteps {
  list-style: none;
  margin: 0;
  padding: 0;
}
/* line 1393, ../sass/_shop.scss */
#checkoutsteps > li {
  margin: 0 0 11px;
}
/* line 1396, ../sass/_shop.scss */
#checkoutsteps .step-title {
  background: #f2f2f2;
  border: 1px solid #e1e1e1;
  color: #1e1e1e;
  display: block;
  height: 50px;
  padding: 0 18px;
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 1406, ../sass/_shop.scss */
#checkoutsteps .step-title.collapsed {
  border-color: #f2f2f2;
  color: #7f7f7f;
}
/* line 1410, ../sass/_shop.scss */
#checkoutsteps .step-title .number {
  color: #7f7f7f;
  float: right;
  font-size: 16px;
  line-height: 49px;
}
/* line 1416, ../sass/_shop.scss */
#checkoutsteps .step-title h6 {
  font-size: 16px;
  font-weight: normal;
  line-height: 49px;
  margin: 0;
}
/* line 1424, ../sass/_shop.scss */
#checkoutsteps a.step-title:hover {
  opacity: 0.85;
  filter: alpha(opacity=85);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 1430, ../sass/_shop.scss */
#checkoutsteps .step-content {
  border: 1px solid #e1e1e1;
  border-top: 0 none;
  font-size: 12px;
  line-height: 20px;
  padding: 22px 18px 19px;
}
/* line 1437, ../sass/_shop.scss */
#checkoutsteps .step-content label {
  font-size: 12px;
  line-height: 22px;
}
/* line 1441, ../sass/_shop.scss */
#checkoutsteps .step-content .radio,
#checkoutsteps .step-content .checkbox {
  line-height: 18px;
}
/* line 1445, ../sass/_shop.scss */
#checkoutsteps .step-content .radio .new-radio,
#checkoutsteps .step-content .radio .new-checkbox,
#checkoutsteps .step-content .checkbox .new-radio,
#checkoutsteps .step-content .checkbox .new-checkbox {
  margin-top: 0;
}
/* line 1450, ../sass/_shop.scss */
#checkoutsteps .step-content .title {
  border-bottom: 1px solid #e1e1e1;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  margin: 0 0 17px;
  padding: 0 0 10px;
  position: relative;
}
/* line 1459, ../sass/_shop.scss */
#checkoutsteps .step-content .title:before {
  background: #1e1e1e;
  bottom: 0px;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  margin: 0 0 -1px;
  position: absolute;
  width: 40px;
}
/* line 1471, ../sass/_shop.scss */
#checkoutsteps .step-content .register-radio {
  margin: 0 0 22px 39px;
}
/* line 1474, ../sass/_shop.scss */
#checkoutsteps .step-content ul {
  list-style-type: disc;
}
/* line 1477, ../sass/_shop.scss */
#checkoutsteps .step-content input.last {
  margin-bottom: 14px;
}
/* line 1480, ../sass/_shop.scss */
#checkoutsteps .step-content .buttons-box {
  font-size: 11px;
  line-height: 1.5;
  margin-top: 6px;
  overflow: hidden;
  padding-top: 10px;
  text-align: right;
}
/* line 1488, ../sass/_shop.scss */
#checkoutsteps .step-content .buttons-box .btn {
  float: left;
  margin: -10px 10px 0 0;
}
/* line 1492, ../sass/_shop.scss */
#checkoutsteps .step-content .buttons-box .required {
  display: inline-block;
  font-size: 11px;
  margin-left: 12px;
}

/* line 1501, ../sass/_shop.scss */
.sidebar.checkout-progress header {
  margin: 0 0 13px;
}
/* line 1504, ../sass/_shop.scss */
.sidebar.checkout-progress .progress-list {
  margin: 0;
}
/* line 1507, ../sass/_shop.scss */
.sidebar.checkout-progress .progress-list li {
  color: #7f7f7f;
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  padding: 12px 0 7px;
}
/* line 1514, ../sass/_shop.scss */
.sidebar.checkout-progress .progress-list li + li {
  border-top-width: 1px;
}
/* line 1517, ../sass/_shop.scss */
.sidebar.checkout-progress .progress-list li a {
  color: #7f7f7f;
  float: right;
}
/* line 1521, ../sass/_shop.scss */
.sidebar.checkout-progress .progress-list li.active {
  color: #1e1e1e;
  font-weight: bold;
}
/* line 1525, ../sass/_shop.scss */
.sidebar.checkout-progress .progress-list li.active a {
  display: none;
}

/* My Account */
/* line 1534, ../sass/_shop.scss */
.my-account.margin-top {
  margin-top: -16px;
}
/* line 1537, ../sass/_shop.scss */
.my-account .subtitle {
  color: #7f7f7f;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 10px;
}
/* line 1543, ../sass/_shop.scss */
.my-account .info-box {
  margin-bottom: 40px;
}
/* line 1546, ../sass/_shop.scss */
.my-account .buttons-box .required {
  float: right;
  margin: 12px 0 0 10px;
}
/* line 1550, ../sass/_shop.scss */
.my-account .pagination-text {
  color: #999;
  font-size: 12px;
}
/* line 1554, ../sass/_shop.scss */
.my-account .btn.margin-top {
  margin-top: 10px;
}
/* line 1557, ../sass/_shop.scss */
.my-account .table {
  margin: 0;
}

/* Compare Product */
/* line 1562, ../sass/_shop.scss */
#compare-table {
  border: 0 none;
  margin: 0;
}
/* line 1567, ../sass/_shop.scss */
#compare-table tr th:last-child,
#compare-table tr td:last-child {
  border-right-width: 1px;
}
/* line 1572, ../sass/_shop.scss */
#compare-table .remove td {
  border-width: 0;
  height: 26px;
  padding: 0;
}
/* line 1577, ../sass/_shop.scss */
#compare-table .remove td:last-child {
  border-right-width: 0;
}
/* line 1581, ../sass/_shop.scss */
#compare-table .remove-bottom td {
  border-top-width: 1px;
  vertical-align: bottom;
}
/* line 1585, ../sass/_shop.scss */
#compare-table th {
  background: #f7f7f7;
  vertical-align: top;
}
/* line 1589, ../sass/_shop.scss */
#compare-table td {
  font-size: 12px;
  line-height: 20px;
  padding-top: 24px;
  padding-bottom: 24px;
  vertical-align: top;
}
/* line 1596, ../sass/_shop.scss */
#compare-table .first {
  font-size: 13px;
  min-width: inherit;
  padding: 19px 24px;
  text-align: left;
  width: 190px;
}
/* line 1603, ../sass/_shop.scss */
#compare-table .product-name {
  color: #1e1e1e;
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 1608, ../sass/_shop.scss */
#compare-table .product-name:hover {
  opacity: .8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 1614, ../sass/_shop.scss */
#compare-table .product-image {
  background: #f2f2f2;
  border-radius: 3px;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  width: 100px;
}
/* line 1624, ../sass/_shop.scss */
#compare-table .price-old {
  font-size: 11px;
  margin: 0 0 3px;
  text-decoration: line-through;
}
/* line 1629, ../sass/_shop.scss */
#compare-table .price-old:after {
  display: none;
}
/* line 1633, ../sass/_shop.scss */
#compare-table .price {
  font-size: 16px;
}
/* line 1636, ../sass/_shop.scss */
#compare-table .rating-box {
  margin: 0 auto;
}
/* line 1639, ../sass/_shop.scss */
#compare-table .description td {
  text-align: left;
}

/******************************************************************************
  Icons
*******************************************************************************/
/* line 4, ../sass/_icons.scss */
.icon {
  background: none;
  color: #1e1e1e;
  display: inline-block;
  font-size: 20px;
  height: 32px;
  line-height: 32px;
  margin: 0 0 4px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  width: 32px;
}
/* line 17, ../sass/_icons.scss */
.icon .fa {
  width: 100%;
}
/* line 20, ../sass/_icons.scss */
.icon.pull-left {
  float: left;
  margin-right: 10px;
}
/* line 24, ../sass/_icons.scss */
.icon.pull-right {
  float: right;
  margin-left: 10px;
}
/* line 28, ../sass/_icons.scss */
.icon.icon-24 {
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  width: 24px;
}
/* line 34, ../sass/_icons.scss */
.icon.icon-40 {
  font-size: 26px;
  height: 40px;
  line-height: 40px;
  width: 40px;
}
/* line 40, ../sass/_icons.scss */
.icon.icon-60 {
  font-size: 32px;
  height: 60px;
  line-height: 60px;
  width: 60px;
}
/* line 46, ../sass/_icons.scss */
.icon.icon-100 {
  font-size: 50px;
  height: 100px;
  line-height: 100px;
  width: 100px;
}
/* line 52, ../sass/_icons.scss */
.icon.rounded {
  border-radius: 3px;
}
/* line 55, ../sass/_icons.scss */
.icon.circle {
  border-radius: 400px;
}
/* line 58, ../sass/_icons.scss */
.icon.border {
  box-sizing: border-box;
  border-width: 1px;
}
/* line 62, ../sass/_icons.scss */
.icon.bg {
  background-color: #f2f2f2;
}

/* line 66, ../sass/_icons.scss */
.btn-icon .icon {
  color: #fff;
  font-size: 14px;
  margin: 2px 0;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  width: auto;
}
/* line 75, ../sass/_icons.scss */
.btn-icon .icon .fa {
  width: inherit;
}

/* Livicons */
/* line 80, ../sass/_icons.scss */
.livicon {
  display: inline-block;
  line-height: inherit;
  vertical-align: middle;
}
/* line 85, ../sass/_icons.scss */
.livicon svg {
  vertical-align: top;
}
/* line 88, ../sass/_icons.scss */
.livicon.metro-bg {
  box-sizing: content-box;
  margin-bottom: 4px;
  padding: 26px 25px 26px 27px;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

/* line 98, ../sass/_icons.scss */
.big-icon .livicon svg,
.icon .livicon svg {
  top: 25% !important;
}

/* line 103, ../sass/_icons.scss */
.color.icon {
  color: #fff;
  -webkit-transition: background-color .2s linear;
          transition: background-color .2s linear;
}
/* line 107, ../sass/_icons.scss */
.color.icon:hover {
  background-color: #000;
}
/* line 112, ../sass/_icons.scss */
.color.icon-adn {
  background-color: #504e54;
}
/* line 113, ../sass/_icons.scss */
.color.icon-apple {
  background-color: #aeb5c5;
}
/* line 114, ../sass/_icons.scss */
.color.icon-android {
  background-color: #A5C63B;
}
/* line 115, ../sass/_icons.scss */
.color.icon-bitbucket, .color.icon-bitbucket-square {
  background-color: #003366;
}
/* line 116, ../sass/_icons.scss */
.color.icon-bitcoin, .color.icon-btc {
  background-color: #F7931A;
}
/* line 117, ../sass/_icons.scss */
.color.icon-css3 {
  background-color: #1572B7;
}
/* line 118, ../sass/_icons.scss */
.color.icon-dribbble {
  background-color: #F46899;
}
/* line 119, ../sass/_icons.scss */
.color.icon-dropbox {
  background-color: #018BD3;
}
/* line 120, ../sass/_icons.scss */
.color.icon-facebook, .color.icon-facebook-square {
  background-color: #3C599F;
}
/* line 121, ../sass/_icons.scss */
.color.icon-flickr {
  background-color: #FF0084;
}
/* line 122, ../sass/_icons.scss */
.color.icon-foursquare {
  background-color: #0086BE;
}
/* line 123, ../sass/_icons.scss */
.color.icon-github, .color.icon-github-alt, .color.icon-github-square {
  background-color: #070709;
}
/* line 124, ../sass/_icons.scss */
.color.icon-google-plus, .color.icon-google-plus-square {
  background-color: #CF3D2E;
}
/* line 125, ../sass/_icons.scss */
.color.icon-html5 {
  background-color: #E54D26;
}
/* line 126, ../sass/_icons.scss */
.color.icon-instagram {
  background-color: #A1755C;
}
/* line 127, ../sass/_icons.scss */
.color.icon-linkedin, .color.icon-linkedin-square {
  background-color: #0085AE;
}
/* line 128, ../sass/_icons.scss */
.color.icon-linux {
  background-color: #FBC002;
}
/* line 129, ../sass/_icons.scss */
.color.icon-maxcdn {
  background-color: #F6AE1C;
}
/* line 130, ../sass/_icons.scss */
.color.icon-pagelines {
  background-color: #241E20;
  color: #3984EA;
}
/* line 131, ../sass/_icons.scss */
.color.icon-pinterest, .color.icon-pinterest-square {
  background-color: #CC2127;
}
/* line 132, ../sass/_icons.scss */
.color.icon-renren {
  background-color: #025DAC;
}
/* line 133, ../sass/_icons.scss */
.color.icon-skype {
  background-color: #01AEF2;
}
/* line 134, ../sass/_icons.scss */
.color.icon-stack-exchange {
  background-color: #245590;
}
/* line 135, ../sass/_icons.scss */
.color.icon-stack-overflow {
  background-color: #FF7300;
}
/* line 136, ../sass/_icons.scss */
.color.icon-trello {
  background-color: #265A7F;
}
/* line 137, ../sass/_icons.scss */
.color.icon-tumblr, .color.icon-tumblr-square {
  background-color: #314E6C;
}
/* line 138, ../sass/_icons.scss */
.color.icon-twitter, .color.icon-twitter-square {
  background-color: #32CCFE;
}
/* line 139, ../sass/_icons.scss */
.color.icon-vimeo-square {
  background-color: #229ACC;
}
/* line 140, ../sass/_icons.scss */
.color.icon-vk {
  background-color: #375474;
}
/* line 141, ../sass/_icons.scss */
.color.icon-weibo {
  background-color: #D72B2B;
}
/* line 142, ../sass/_icons.scss */
.color.icon-windows {
  background-color: #12B6F3;
}
/* line 143, ../sass/_icons.scss */
.color.icon-xing, .color.icon-xing-square {
  background-color: #00555C;
}
/* line 144, ../sass/_icons.scss */
.color.icon-youtube, .color.icon-youtube-play, .color.icon-youtube-square {
  background-color: #C52F30;
}

/******************************************************************************
  Media
*******************************************************************************/
/* Video */
/* line 5, ../sass/_media.scss */
embed,
iframe,
object,
video,
audio {
  max-width: 100%;
}

/* line 12, ../sass/_media.scss */
.video-box {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  height: 0;
  margin-bottom: 22px;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
}
/* line 22, ../sass/_media.scss */
.video-box.html5 {
  height: auto;
  padding: 0;
}
/* line 26, ../sass/_media.scss */
.video-box.html5 .video-js {
  height: auto !important;
  line-height: 0;
  width: 100% !important;
}
/* line 31, ../sass/_media.scss */
.video-box.html5 .video-js .vjs-tech {
  height: auto;
  margin: 0;
  position: relative;
  width: 100%;
}
/* line 38, ../sass/_media.scss */
.video-box.html5 .vjs-default-skin {
  color: #fff;
}
/* line 41, ../sass/_media.scss */
.video-box.html5 .vjs-default-skin .vjs-play-progress,
.video-box.html5 .vjs-default-skin .vjs-volume-level {
  background-color: #555;
}
/* line 45, ../sass/_media.scss */
.video-box.html5 .vjs-default-skin .vjs-control-bar,
.video-box.html5 .vjs-default-skin .vjs-big-play-button {
  background: rgba(0, 0, 0, 0.8);
}
/* line 49, ../sass/_media.scss */
.video-box.html5 .vjs-default-skin .vjs-slider {
  background: rgba(0, 0, 0, 0.8);
}
/* line 54, ../sass/_media.scss */
.video-box embed,
.video-box iframe,
.video-box object,
.video-box video {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* Audio */
/* line 67, ../sass/_media.scss */
.audio-box audio {
  width: 100%;
}

/* line 70, ../sass/_media.scss */
.jp-audio,
.jp-audio *,
.jp-jplayer,
.jp-jplayer * {
  box-sizing: content-box;
}

/* line 78, ../sass/_media.scss */
div.jp-audio {
  box-sizing: border-box;
  min-width: 300px;
  width: 100%;
}
/* line 85, ../sass/_media.scss */
div.jp-audio ul.jp-controls {
  box-sizing: border-box;
  width: 100%;
}

/* line 92, ../sass/_media.scss */
.jp-audio button {
  padding: 0;
}

/******************************************************************************
  Blog
*******************************************************************************/
/* Posts */
/* line 5, ../sass/_blog.scss */
.post {
  margin-bottom: 40px;
  padding: 0;
}
/* line 9, ../sass/_blog.scss */
.post + .post {
  border-top-width: 1px;
  padding-top: 37px;
}
/* line 13, ../sass/_blog.scss */
.post .entry-title {
  font-size: 26px;
  font-weight: normal;
  margin: 0 0 17px;
}
/* line 18, ../sass/_blog.scss */
.post .entry-title a {
  color: #1e1e1e;
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 23, ../sass/_blog.scss */
.post .entry-title a:hover {
  text-decoration: none;
  opacity: .8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 31, ../sass/_blog.scss */
.post .entry-content {
  margin: 0 0 25px;
}
/* line 34, ../sass/_blog.scss */
.post .entry-content p:last-child {
  margin-bottom: 0;
}
/* line 38, ../sass/_blog.scss */
.post .entry-meta {
  color: #7f7f7f;
  font-size: 12px;
  line-height: 1.3;
}
/* line 43, ../sass/_blog.scss */
.post .entry-meta .separator {
  margin: 0 10px;
}
/* line 46, ../sass/_blog.scss */
.post .entry-meta .comments-link {
  padding: 0;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 50, ../sass/_blog.scss */
.post .entry-meta .comments-link:hover {
  opacity: .8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 55, ../sass/_blog.scss */
.post .entry-meta .comments-link a {
  color: #7f7f7f;
  text-decoration: none;
}
/* line 61, ../sass/_blog.scss */
.blog .post {
  margin-bottom: 76px;
  margin-top: -3px;
}
/* line 65, ../sass/_blog.scss */
.carousel .post {
  border: 0 none;
  float: left;
  line-height: 22px;
  margin: 0 15px;
  padding: 0;
}

/* Comments */
/* line 74, ../sass/_blog.scss */
.commentlist {
  list-style: none;
  margin: 0;
  padding: 0 0 33px;
}
/* line 79, ../sass/_blog.scss */
.commentlist li {
  padding: 0 0 25px;
}
/* line 82, ../sass/_blog.scss */
.commentlist li + li {
  border-top-width: 1px;
  padding: 25px 0 0;
}
/* line 86, ../sass/_blog.scss */
.commentlist li .avatar {
  border-radius: 42px;
  float: left;
  height: 84px;
  margin: 0 25px 0 0;
  width: 84px;
}
/* line 94, ../sass/_blog.scss */
.commentlist .meta {
  color: #7f7f7f;
  font-size: 11px;
  line-height: 18px;
  margin: 0 0 4px;
}
/* line 100, ../sass/_blog.scss */
.blog-post .commentlist {
  margin: 0 0 54px;
  padding: 0;
}
/* line 104, ../sass/_blog.scss */
.blog-post .commentlist li {
  padding: 22px 0;
}
/* line 107, ../sass/_blog.scss */
.blog-post .commentlist li:first-child {
  padding-top: 10px;
}
/* line 110, ../sass/_blog.scss */
.blog-post .commentlist li .meta {
  margin: 0 0 10px;
}
/* line 113, ../sass/_blog.scss */
.blog-post .commentlist li .description {
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  overflow: hidden;
}

/* line 122, ../sass/_blog.scss */
.evaluation-box {
  overflow: hidden;
}

/* line 125, ../sass/_blog.scss */
.evaluation {
  float: left;
  height: 15px;
  font-size: 12px;
  line-height: 15px;
  margin: 0 10px 14px 0;
}
/* line 132, ../sass/_blog.scss */
.evaluation + .evaluation {
  border-left-width: 1px;
  padding-left: 10px;
}
/* line 136, ../sass/_blog.scss */
.evaluation .rating-box {
  display: inline-block;
  margin: 0 0 0 7px;
}
/* line 140, ../sass/_blog.scss */
.evaluation .add-rating {
  float: left;
  margin: 0 0 0 10px;
  overflow: hidden;
}
/* line 145, ../sass/_blog.scss */
.evaluation .add-rating label {
  float: left;
  height: 30px;
  margin: 0 1px;
  padding: 0 0 0 14px;
  position: relative;
  width: 2px;
}
/* line 153, ../sass/_blog.scss */
.evaluation .add-rating label .new-radio {
  margin: 0px 0 0 -14px;
}
/* line 156, ../sass/_blog.scss */
.evaluation .add-rating label .number {
  bottom: 0;
  color: #7f7f7f;
  font-size: 11px;
  line-height: 1;
  left: 3px;
  position: absolute;
}

/* line 168, ../sass/_blog.scss */
.comments-form .evaluation-box .evaluation {
  margin-bottom: 30px;
}
/* line 172, ../sass/_blog.scss */
.comments-form .row label {
  font-weight: bold;
  margin: 0 0 4px;
}
/* line 176, ../sass/_blog.scss */
.comments-form .row textarea {
  box-sizing: border-box;
  height: 128px;
  margin: 0 0 10px;
  width: 100%;
}
/* line 184, ../sass/_blog.scss */
/*.comments-form .row i {
  color: #999;
  font-size: 12px;
}*/
/* line 188, ../sass/_blog.scss */
.comments-form .row .btn {
  margin: 34px 0 0 0;
}
/* line 192, ../sass/_blog.scss */
.blog-post .comments-form {
  margin: 6px 0 0;
}
/* line 195, ../sass/_blog.scss */
.blog-post .comments-form .comment-box {
  margin: 0;
  text-align: right;
}
/* line 199, ../sass/_blog.scss */
.blog-post .comments-form .comment-box textarea {
  margin: 0 0 15px;
}
/* line 202, ../sass/_blog.scss */
/*.blog-post .comments-form .comment-box i {
  color: #7f7f7f;
  font-size: 11px;
  line-height: 12px;
  vertical-align: top;
}*/
/* line 209, ../sass/_blog.scss */
.blog-post .comments-form .button-set {
  margin: 19px 0 0;
}
/* line 212, ../sass/_blog.scss */
.blog-post .comments-form .button-set .required {
  font-size: 11px;
  line-height: 40px;
}
/* line 216, ../sass/_blog.scss */
.blog-post .comments-form .button-set .required b {
  font-size: 14px;
}

/* Posts Variants */
/* line 224, ../sass/_blog.scss */
.post.carousel {
  line-height: 22px;
}
/* line 227, ../sass/_blog.scss */
.post.carousel .col-xs-12 {
  max-width: inherit;
}
/* line 230, ../sass/_blog.scss */
.post.carousel .images-box {
  overflow: hidden;
}
@media (max-width: 600px) {
  /* line 233, ../sass/_blog.scss */
  .post.carousel .images-box, .post.carousel .images-box.pull-right {
    display: block;
    float: none !important;
    margin: 0 auto 20px;
  }
}
/* line 241, ../sass/_blog.scss */
.post.carousel .images-box img,
.post.carousel .images-box iframe {
  border-radius: 5px;
}
/* line 246, ../sass/_blog.scss */
.post.carousel .carousel-box {
  margin-bottom: 42px;
  position: relative;
}
/* line 250, ../sass/_blog.scss */
.post.carousel .carousel-box.load {
  max-height: 300px;
}
/* line 253, ../sass/_blog.scss */
.post.carousel .carousel-box.no-pagination {
  margin-bottom: 8px;
}
/* line 256, ../sass/_blog.scss */
.post.carousel .carousel-box .next,
.post.carousel .carousel-box .prev {
  left: 0;
  margin: -20px 5px 0;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  top: 50%;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 266, ../sass/_blog.scss */
.post.carousel .carousel-box .next {
  left: auto;
  right: 0;
}
/* line 271, ../sass/_blog.scss */
.post.carousel .carousel-box:hover .next,
.post.carousel .carousel-box:hover .prev {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: opacity .2s linear, background-color .2s linear;
          transition: opacity .2s linear, background-color .2s linear;
}
/* line 279, ../sass/_blog.scss */
.post.carousel .carousel-box:hover .next:hover,
.post.carousel .carousel-box:hover .prev:hover {
  -webkit-transition: background-color .2s linear;
          transition: background-color .2s linear;
}
/* line 284, ../sass/_blog.scss */
.post.carousel .carousel-box .pagination {
  left: 0;
  position: absolute;
  right: 0;
}

/******************************************************************************
  Footer
*******************************************************************************/
/* line 4, ../sass/_footer.scss */
#footer {
  color: #7f7f7f;
  margin: 0;
  overflow-x: hidden;
}
/* line 9, ../sass/_footer.scss */
#footer.footer-two {
  bottom: 0;
  position: absolute;
  width: 100%;
}
/* line 14, ../sass/_footer.scss */
#footer.footer-two .footer-top {
  padding-bottom: 43px;
}
/* line 17, ../sass/_footer.scss */
#footer.footer-two .social {
  text-align: center;
}
/* line 20, ../sass/_footer.scss */
#footer.footer-two .social .icon {
  display: inline-block;
  float: none;
  margin: 0 3px;
}
/* line 27, ../sass/_footer.scss */
#footer .footer-top {
  background: #f2f2f2;
  font-size: 12px;
  line-height: 21px;
  padding: 50px 0 13px;
}
/* line 33, ../sass/_footer.scss */
#footer .footer-bottom {
  background: #f7f7f7;
  border-top-width: 1px;
  font-size: 11px;
  line-height: 17px;
  padding: 21px 0 20px;
}
/* line 41, ../sass/_footer.scss */
#footer .sidebar .widget {
  margin-bottom: 30px;
}
/* line 44, ../sass/_footer.scss */
#footer .sidebar .widget:nth-child(4n+1) {
  clear: left;
}
/* line 47, ../sass/_footer.scss */
#footer .sidebar .widget p {
  margin: 0 0 16px;
}
/* line 50, ../sass/_footer.scss */
#footer .sidebar .widget header,
#footer .sidebar .widget .title-block {
  margin: 0 0 15px;
  padding: 0 0 12px;
}
/* line 55, ../sass/_footer.scss */
#footer .sidebar .widget header:before, #footer .sidebar .widget header:before,
#footer .sidebar .widget .title-block:before,
#footer .sidebar .widget .title-block:before {
  display: none;
}
/* line 59, ../sass/_footer.scss */
#footer .sidebar .widget header .title,
#footer .sidebar .widget .title-block .title {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.3;
  text-transform: uppercase;
}
/* line 67, ../sass/_footer.scss */
#footer .sidebar .widget .next,
#footer .sidebar .widget .prev {
  height: 26px;
  line-height: 26px;
  width: 26px;
}
/* line 73, ../sass/_footer.scss */
#footer .sidebar .widget .next svg,
#footer .sidebar .widget .prev svg {
  height: 14px;
}
/* line 78, ../sass/_footer.scss */
#footer .sidebar .compare-products li,
#footer .sidebar .compare-products li .title,
#footer .sidebar .compare-products li .close,
#footer .sidebar .wishlist .add-cart {
  background-color: #f2f2f2;
}
/* line 85, ../sass/_footer.scss */
#footer .phone,
#footer .address {
  padding-left: 34px;
  position: relative;
}
/* line 90, ../sass/_footer.scss */
#footer .footer-icon {
  color: #c6c6c6;
  left: 16px;
  position: absolute;
  top: 0;
}
/* line 96, ../sass/_footer.scss */
#footer .footer-icon:before {
  font-size: 20px;
  line-height: 18px;
  width: auto;
}
/* line 102, ../sass/_footer.scss */
#footer .up {
  background: #c6c6c6;
  border-radius: 3px;
  display: block;
  float: right;
  height: 40px;
  text-align: center;
  width: 40px;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 112, ../sass/_footer.scss */
#footer .up:hover {
  background: #000;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 116, ../sass/_footer.scss */
#footer .up .glyphicon {
  color: #fff;
  margin: 14px 0 0 -.5px;
  vertical-align: top;
}

/******************************************************************************
  Fancybox
*******************************************************************************/
/* line 4, ../sass/_fancybox.scss */
.fancybox-margin .fixed-header .header,
.fancybox-margin .fixed-header #top-box {
  left: -7.5px;
}

/* line 8, ../sass/_fancybox.scss */
.fancybox-close {
  background: #fff;
  border: 1px solid #7f7f7f;
  border-radius: 50%;
  color: #1e1e1e;
  font-size: 25px;
  font-weight: normal;
  height: 30px;
  line-height: 29px;
  right: -15px;
  text-align: center;
  text-decoration: none;
  top: -15px;
  width: 30px;
  -webkit-transition: color .2s linear;
          transition: color .2s linear;
}
/* line 24, ../sass/_fancybox.scss */
.fancybox-close:hover {
  color: #7f7f7f;
  text-decoration: none;
  -webkit-transition: color .2s linear;
          transition: color .2s linear;
}

/* line 30, ../sass/_fancybox.scss */
.fancybox-nav span {
  background: #ccc;
  border: 0 none;
  border-radius: 3px;
  color: #fbfbfb;
  display: block;
  float: right;
  height: 40px;
  line-height: 40px;
  margin: -20px 0 0 0;
  text-align: center;
  text-decoration: none;
  width: 40px;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 45, ../sass/_fancybox.scss */
.fancybox-nav span:hover {
  background-color: #000;
  color: #efefef;
  text-decoration: none;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 51, ../sass/_fancybox.scss */
.fancybox-nav span svg {
  vertical-align: middle;
}

/* line 55, ../sass/_fancybox.scss */
.fontawesome-icon-list {
  color: #7f7f7f;
}
/* line 58, ../sass/_fancybox.scss */
.fontawesome-icon-list .fa {
  color: #1e1e1e;
  width: 22px;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}

/******************************************************************************
  Boxed
*******************************************************************************/
/* line 4, ../sass/_boxed.scss */
.boxed {
  background: #fff;
  margin: 0 auto;
  max-width: 1300px;
  padding-top: 20px;
}
@media (max-width: 1300px) {
  /* line 4, ../sass/_boxed.scss */
  .boxed {
    padding-top: 0;
  }
}
/* line 13, ../sass/_boxed.scss */
.boxed .page-box {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 18px;
  overflow: hidden;
  position: relative;
}
/* line 21, ../sass/_boxed.scss */
.boxed.loaded .page-box {
  overflow: visible;
}
/* line 26, ../sass/_boxed.scss */
.boxed.fixed-header .header {
  margin-top: 0;
  position: absolute;
}
/* line 31, ../sass/_boxed.scss */
.boxed.fixed-header.fixed .header {
  position: fixed;
  top: 0;
}
/* line 36, ../sass/_boxed.scss */
.boxed.fixed-header.fixed.fixed-top #top-box {
  position: fixed;
  top: 0;
}
/* line 40, ../sass/_boxed.scss */
.boxed.fixed-header.fixed.fixed-top .header {
  top: 40px;
}
/* line 45, ../sass/_boxed.scss */
.boxed.fixed-header.hidden-top #top-box {
  margin-bottom: 104px;
}
/* line 50, ../sass/_boxed.scss */
.boxed.hidden-top .page-box {
  padding-top: 0;
  -webkit-transition: none;
          transition: none;
}
/* line 54, ../sass/_boxed.scss */
.boxed.hidden-top #top-box {
  left: 0;
  margin-top: 0;
  position: static;
  -webkit-transition: none;
          transition: none;
}
/* line 60, ../sass/_boxed.scss */
.boxed.hidden-top .header {
  top: 40px;
}
/* line 63, ../sass/_boxed.scss */
.boxed.hidden-top.fixed .header {
  top: 0;
}
/* line 67, ../sass/_boxed.scss */
.boxed .header-wrapper {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  max-width: 1300px;
}
/* line 74, ../sass/_boxed.scss */
.boxed .top-box-wrapper {
  margin: 0 auto;
  max-width: 1300px;
}
/* line 78, ../sass/_boxed.scss */
.boxed .banner-set.banner-set-mini.banner-set-no-pagination .pagination {
  display: inline-block !important;
}
/* line 81, ../sass/_boxed.scss */
.boxed .banner-set.banner-set-mini.banner-set-no-pagination .prev,
.boxed .banner-set.banner-set-mini.banner-set-no-pagination .next {
  margin: 15px 0 0 !important;
  position: static;
}
/* line 86, ../sass/_boxed.scss */
.boxed #footer {
  min-width: 100%;
  padding-bottom: 20px;
  position: relative;
}
@media (max-width: 1300px) {
  /* line 86, ../sass/_boxed.scss */
  .boxed #footer {
    padding-bottom: 0;
  }
}
/* line 94, ../sass/_boxed.scss */
.boxed #footer.footer-two {
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  /* line 99, ../sass/_boxed.scss */
  .boxed.padding-top {
    padding-top: 420px;
  }
}

/******************************************************************************
  One Page
*******************************************************************************/
/* line 5, ../sass/_one-page.scss */
.one-page #main {
  padding-top: 0;
  padding-bottom: 0;
}
/* line 9, ../sass/_one-page.scss */
.one-page .page-header {
  margin-bottom: 40px;
}
/* line 12, ../sass/_one-page.scss */
.one-page .full-width-box:last-child {
  padding-bottom: 0;
}
/* line 15, ../sass/_one-page.scss */
.one-page .header .primary .navbar .nav .active {
  color: #c10841;
}

/******************************************************************************
  Other
*******************************************************************************/
/* Promo Partners */
/* line 5, ../sass/_other.scss */
.promo-partners {
  font-size: 13px;
  line-height: 20px;
}
/* line 9, ../sass/_other.scss */
.promo-partners .col-md-2 {
  margin-bottom: 37px;
}

/* About Us */
/* line 14, ../sass/_other.scss */
.about-us {
  font-size: 13px;
}
/* line 17, ../sass/_other.scss */
.about-us strong {
  font-size: 14px;
}

/* line 21, ../sass/_other.scss */
.body-bg-img {
  background: url("../content/img/coming-soon.jpg") 50% 0 no-repeat;
}
/* line 24, ../sass/_other.scss */
.body-bg-img.under-construction {
  background: url("../content/img/under-construction.jpg") 50% 0 no-repeat;
}
/* line 27, ../sass/_other.scss */
.body-bg-img .page-box {
  display: table;
  height: 100% !important;
  table-layout: fixed;
  width: 100%;
}
/* line 33, ../sass/_other.scss */
.body-bg-img .header-three {
  min-height: 1px;
  position: absolute;
  width: 100%;
}
/* line 38, ../sass/_other.scss */
.body-bg-img #main {
  display: table-cell;
  padding: 119px 0 192px;
  vertical-align: middle;
  width: 100%;
}

/* line 45, ../sass/_other.scss */
.count-down-box {
  float: right;
}

/* line 48, ../sass/_other.scss */
#count-down {
  text-align: right;
  width: auto !important;
}
@media (max-width: 991px) {
  /* line 48, ../sass/_other.scss */
  #count-down {
    text-align: center;
  }
}
/* line 55, ../sass/_other.scss */
#count-down .county-days-wrapper,
#count-down .county-hours-wrapper,
#count-down .county-minutes-wrapper,
#count-down .county-seconds-wrapper {
  background: #c10841;
  background: rgba(193, 8, 65, 0.5);
  display: inline-block;
  height: 135px;
  margin-left: 2px;
  margin-right: 2px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 23% !important;
}
/* line 70, ../sass/_other.scss */
#count-down .county-hours-wrapper {
  background: #f89406;
  background: rgba(248, 148, 6, 0.5);
}
/* line 74, ../sass/_other.scss */
#count-down .county-minutes-wrapper {
  background: #0098ca;
  background: rgba(0, 152, 202, 0.5);
}
/* line 78, ../sass/_other.scss */
#count-down .county-seconds-wrapper {
  background: #738d00;
  background: rgba(115, 141, 0, 0.5);
}
/* line 82, ../sass/_other.scss */
#count-down .county-days,
#count-down .county-hours,
#count-down .county-minutes,
#count-down .county-seconds {
  font-size: 60px;
  color: #fff;
  line-height: 135px;
  display: inline-block !important;
  opacity: 1 !important;
  width: auto !important;
}
/* line 93, ../sass/_other.scss */
#count-down .county-label-days,
#count-down .county-label-hours,
#count-down .county-label-minutes,
#count-down .county-label-seconds {
  margin-left: 2px;
  margin-right: 2px;
  padding-top: 10px;
  font-size: 18px;
  display: inline-block;
  text-align: center;
  width: 23% !important;
}

/* line 106, ../sass/_other.scss */
.coming-text {
  margin-top: -4px;
}
/* line 109, ../sass/_other.scss */
.coming-text form {
  position: relative;
}
/* line 112, ../sass/_other.scss */
.coming-text .success {
  bottom: 100%;
  margin-bottom: 5px;
  position: absolute;
}
/* line 117, ../sass/_other.scss */
.coming-text .form-control {
  padding-right: 100px;
}
/* line 120, ../sass/_other.scss */
.coming-text .btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 90px;
}

/* line 128, ../sass/_other.scss */
.under-contact .phone,
.under-contact .address {
  padding: 0 0 0 34px;
  position: relative;
}
/* line 133, ../sass/_other.scss */
.under-contact .footer-icon {
  color: #000;
  left: 16px;
  position: absolute;
  top: 0;
}
/* line 139, ../sass/_other.scss */
.under-contact .footer-icon:before {
  font-size: 20px;
  line-height: 18px;
  width: auto;
}

/* line 146, ../sass/_other.scss */
.list-images > .col-md-4 {
  margin-bottom: 30px;
}

/* line 149, ../sass/_other.scss */
[data-appear-animation] {
  opacity: 0;
  -webkit-transition: .5s opacity .1s linear;
          transition: .5s opacity .1s linear;
}
@media (max-width: 767px) {
  /* line 149, ../sass/_other.scss */
  [data-appear-animation] {
    opacity: 1;
  }
}
/* line 156, ../sass/_other.scss */
[data-appear-animation].animated {
  opacity: 1;
}

/******************************************************************************
  Banner Set
*******************************************************************************/
/* line 4, ../sass/elements/_banner-set.scss */
.banner-set {
  border-top-width: 1px;
  padding-bottom: 16px;
  overflow: hidden;
}
/* line 9, ../sass/elements/_banner-set.scss */
.banner-set:before {
  border-top: 1px solid #e1e1e1;
  content: "";
  display: block;
  left: 0;
  margin-top: -1px;
  position: absolute;
  right: 0;
}
/* line 19, ../sass/elements/_banner-set.scss */
.banner-set > .container:after {
  border-top: 1px solid #e1e1e1;
  content: "";
  display: block;
  left: 0;
  margin-top: -1px;
  position: absolute;
  right: 0;
}
/* line 30, ../sass/elements/_banner-set.scss */
.banner-set .container > * {
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 34, ../sass/elements/_banner-set.scss */
.banner-set .banner {
  border-right-width: 1px;
  color: #7f7f7f;
  display: block;
  float: left;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  width: 292px;
  padding: 19px;
  text-align: left;
  text-decoration: none;
  -webkit-transition: color .2s linear, box-shadow .2s linear;
          transition: color .2s linear, box-shadow .2s linear;
}
@media (max-width: 1199px) {
  /* line 34, ../sass/elements/_banner-set.scss */
  .banner-set .banner {
    padding: 19px 9px;
    width: 235px;
  }
}
@media (max-width: 991px) {
  /* line 34, ../sass/elements/_banner-set.scss */
  .banner-set .banner {
    width: 180px;
  }
}
@media (max-width: 767px) {
  /* line 34, ../sass/elements/_banner-set.scss */
  .banner-set .banner {
    width: 290px;
  }
}
/* line 58, ../sass/elements/_banner-set.scss */
.banner-set .banner:first-child {
  border-left-width: 1px;
}
/* line 61, ../sass/elements/_banner-set.scss */
.banner-set .banner img {
  margin: 0 0 28px 0;
  max-height: 158px;
  width: 100%;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 67, ../sass/elements/_banner-set.scss */
.banner-set .banner .title {
  color: #7f7f7f;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.2;
  margin: 0;
  padding: 0 0 12px;
  text-decoration: none;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
@media (max-width: 991px) {
  /* line 67, ../sass/elements/_banner-set.scss */
  .banner-set .banner .title {
    font-size: 18px;
  }
}
/* line 80, ../sass/elements/_banner-set.scss */
.banner-set .banner .description {
  height: 54px;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* line 85, ../sass/elements/_banner-set.scss */
.banner-set .banner:hover {
  color: #1e1e1e;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.13);
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: color .2s linear, box-shadow .2s linear;
          transition: color .2s linear, box-shadow .2s linear;
}
/* line 92, ../sass/elements/_banner-set.scss */
.banner-set .banner:hover img {
  opacity: .9;
  filter: alpha(opacity=90);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 97, ../sass/elements/_banner-set.scss */
.banner-set .banner:hover .title {
  color: #1e1e1e;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 103, ../sass/elements/_banner-set.scss */
.banner-set .nav-box {
  padding: 0;
  text-align: center;
  vertical-align: top;
}
/* line 108, ../sass/elements/_banner-set.scss */
.banner-set .next,
.banner-set .prev,
.banner-set .pagination {
  display: inline-block;
  margin-top: 15px;
  vertical-align: top;
}
/* line 115, ../sass/elements/_banner-set.scss */
.banner-set .next,
.banner-set .prev {
  color: #ccc;
  font-size: 13px;
  height: 14px;
  line-height: 14px;
  vertical-align: top;
  -webkit-transition: color .2s linear;
          transition: color .2s linear;
}
/* line 124, ../sass/elements/_banner-set.scss */
.banner-set .next:hover,
.banner-set .prev:hover {
  color: #1e1e1e;
  -webkit-transition: color .2s linear;
          transition: color .2s linear;
}
/* line 128, ../sass/elements/_banner-set.scss */
.banner-set .next.disabled,
.banner-set .prev.disabled {
  cursor: default;
  opacity: .12;
  filter: alpha(opacity=12);
}
/* line 133, ../sass/elements/_banner-set.scss */
.banner-set .next .glyphicon,
.banner-set .prev .glyphicon {
  margin: 0;
  top: 0;
  vertical-align: top;
}
/* line 139, ../sass/elements/_banner-set.scss */
.banner-set .pagination {
  height: 14px;
  margin: 15px 7px 0;
}
/* line 143, ../sass/elements/_banner-set.scss */
.banner-set .pagination a {
  background: #ececec;
}
/* line 146, ../sass/elements/_banner-set.scss */
.banner-set .pagination a:hover {
  background: #000;
}
/* line 149, ../sass/elements/_banner-set.scss */
.banner-set .pagination a.selected {
  background: #ccc;
}
/* line 154, ../sass/elements/_banner-set.scss */
.container .banner-set .container {
  padding: 0;
  width: auto;
}
/* line 158, ../sass/elements/_banner-set.scss */
.banner-set.bottom-padding {
  padding-bottom: 0;
}
/* line 162, ../sass/elements/_banner-set.scss */
.banner-set.load .container {
  background-image: url("../img/svg/loader.svg"), none;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 40px;
  height: 314px;
  overflow: hidden;
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 162, ../sass/elements/_banner-set.scss */
  .banner-set.load .container {
    height: 258px;
  }
}
/* line 173, ../sass/elements/_banner-set.scss */
.banner-set.load .container > * {
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 179, ../sass/elements/_banner-set.scss */
.banner-set.load .nav-box .container, .banner-set.load.banner-set-mini .nav-box .container {
  background: none;
  height: auto;
}
/* line 185, ../sass/elements/_banner-set.scss */
.banner-set.banner-set-mini {
  overflow: visible;
  padding-bottom: 0;
}
/* line 189, ../sass/elements/_banner-set.scss */
.banner-set.banner-set-mini .banner {
  padding: 9px;
  width: 146px;
}
@media (max-width: 1199px) {
  /* line 189, ../sass/elements/_banner-set.scss */
  .banner-set.banner-set-mini .banner {
    width: 144px;
  }
}
/* line 196, ../sass/elements/_banner-set.scss */
.banner-set.banner-set-mini .banner img {
  margin-bottom: 14px;
}
/* line 199, ../sass/elements/_banner-set.scss */
.banner-set.banner-set-mini .banner .title {
  font-size: 14px;
  padding: 0;
}
@media (max-width: 1199px) {
  /* line 205, ../sass/elements/_banner-set.scss */
  .banner-set.banner-set-mini .prev {
    margin-left: 0;
  }
  /* line 208, ../sass/elements/_banner-set.scss */
  .banner-set.banner-set-mini .next {
    margin-right: 0;
  }
}
/* line 212, ../sass/elements/_banner-set.scss */
.banner-set.banner-set-mini .pagination {
  margin-bottom: 15px;
}
@media (max-width: 1199px) {
  /* line 216, ../sass/elements/_banner-set.scss */
  .banner-set.banner-set-mini.banner-set-no-pagination .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 991px) {
  /* line 216, ../sass/elements/_banner-set.scss */
  .banner-set.banner-set-mini.banner-set-no-pagination .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
/* line 227, ../sass/elements/_banner-set.scss */
.banner-set.banner-set-mini.banner-set-no-pagination .nav-box {
  position: relative;
}
/* line 230, ../sass/elements/_banner-set.scss */
.banner-set.banner-set-mini.banner-set-no-pagination .nav-box .container {
  position: relative;
}
/* line 234, ../sass/elements/_banner-set.scss */
.banner-set.banner-set-mini.banner-set-no-pagination .pagination {
  display: none !important;
}
@media (min-width: 1200px) and (max-width: 1270px) {
  /* line 234, ../sass/elements/_banner-set.scss */
  .banner-set.banner-set-mini.banner-set-no-pagination .pagination {
    display: inline-block !important;
  }
}
@media (max-width: 800px) {
  /* line 234, ../sass/elements/_banner-set.scss */
  .banner-set.banner-set-mini.banner-set-no-pagination .pagination {
    display: inline-block !important;
  }
}
/* line 243, ../sass/elements/_banner-set.scss */
.banner-set.banner-set-mini.banner-set-no-pagination .prev {
  left: 0;
  margin: -70.5px 0 0 -15px;
  position: absolute;
  top: 0;
}
@media (min-width: 1200px) and (max-width: 1270px) {
  /* line 243, ../sass/elements/_banner-set.scss */
  .banner-set.banner-set-mini.banner-set-no-pagination .prev {
    display: inline-block !important;
    margin: 15px 0 0 !important;
    position: static;
  }
}
@media (max-width: 1199px) {
  /* line 243, ../sass/elements/_banner-set.scss */
  .banner-set.banner-set-mini.banner-set-no-pagination .prev {
    margin-left: 15px;
  }
}
@media (max-width: 991px) {
  /* line 243, ../sass/elements/_banner-set.scss */
  .banner-set.banner-set-mini.banner-set-no-pagination .prev {
    margin-left: -15px;
  }
}
@media (max-width: 800px) {
  /* line 243, ../sass/elements/_banner-set.scss */
  .banner-set.banner-set-mini.banner-set-no-pagination .prev {
    display: inline-block !important;
    margin: 15px 0 0 !important;
    position: static;
  }
}
/* line 265, ../sass/elements/_banner-set.scss */
.banner-set.banner-set-mini.banner-set-no-pagination .next {
  margin: -70.5px -15px 0 0;
  position: absolute;
  top: 0;
  right: 0;
}
@media (min-width: 1200px) and (max-width: 1270px) {
  /* line 265, ../sass/elements/_banner-set.scss */
  .banner-set.banner-set-mini.banner-set-no-pagination .next {
    display: inline-block !important;
    margin: 15px 0 0 !important;
    position: static;
  }
}
@media (max-width: 1199px) {
  /* line 265, ../sass/elements/_banner-set.scss */
  .banner-set.banner-set-mini.banner-set-no-pagination .next {
    margin-right: 15px;
  }
}
@media (max-width: 991px) {
  /* line 265, ../sass/elements/_banner-set.scss */
  .banner-set.banner-set-mini.banner-set-no-pagination .next {
    margin-right: -15px;
  }
}
@media (max-width: 800px) {
  /* line 265, ../sass/elements/_banner-set.scss */
  .banner-set.banner-set-mini.banner-set-no-pagination .next {
    display: inline-block !important;
    margin: 15px 0 0 !important;
    position: static;
  }
}
@media (max-width: 991px) {
  /* line 288, ../sass/elements/_banner-set.scss */
  .container .banner-set.banner-set-mini.banner-set-no-pagination .container {
    padding-left: 0;
    padding-right: 0;
  }
}
/* line 294, ../sass/elements/_banner-set.scss */
.container .banner-set.banner-set-mini.banner-set-no-pagination .prev {
  margin-left: -30px;
}
@media (max-width: 1199px) {
  /* line 294, ../sass/elements/_banner-set.scss */
  .container .banner-set.banner-set-mini.banner-set-no-pagination .prev {
    margin-left: 0;
  }
}
@media (max-width: 991px) {
  /* line 294, ../sass/elements/_banner-set.scss */
  .container .banner-set.banner-set-mini.banner-set-no-pagination .prev {
    margin-left: -30px;
  }
}
/* line 303, ../sass/elements/_banner-set.scss */
.container .banner-set.banner-set-mini.banner-set-no-pagination .next {
  margin-right: -30px;
}
@media (max-width: 1199px) {
  /* line 303, ../sass/elements/_banner-set.scss */
  .container .banner-set.banner-set-mini.banner-set-no-pagination .next {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  /* line 303, ../sass/elements/_banner-set.scss */
  .container .banner-set.banner-set-mini.banner-set-no-pagination .next {
    margin-right: -30px;
  }
}
/* line 315, ../sass/elements/_banner-set.scss */
.banner-set.banner-set-mini.load .container {
  height: 127px;
  overflow: hidden;
}
/* line 319, ../sass/elements/_banner-set.scss */
.banner-set.banner-set-mini.load .container .banners {
  height: 127px;
  overflow: hidden;
}
/* line 327, ../sass/elements/_banner-set.scss */
.touch-device .banner-set .carousel-box .pagination a:hover,
.touch-device .banner-set .banner-set .pagination a:hover {
  background: #ececec;
}
/* line 331, ../sass/elements/_banner-set.scss */
.touch-device .banner-set .carousel-box .pagination a.selected,
.touch-device .banner-set .banner-set .pagination a.selected {
  background: #ccc;
  background: rgba(0, 0, 0, 0.3);
}
/* line 336, ../sass/elements/_banner-set.scss */
.touch-device .banner-set .banner-set .next:hover,
.touch-device .banner-set .banner-set .prev:hover {
  color: #ccc;
}

/******************************************************************************
  Blur Pages
*******************************************************************************/
/* line 4, ../sass/elements/_blur-pages.scss */
.blur-page {
  background-image: url("../img/svg/loader.svg"), none;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 40px;
}
/* line 10, ../sass/elements/_blur-pages.scss */
.blur-page .page-box {
  opacity: 0;
}
/* line 13, ../sass/elements/_blur-pages.scss */
.blur-page .blur-box {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  bottom: 0;
  display: block !important;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  text-align: center;
  z-index: -1;
}
/* line 28, ../sass/elements/_blur-pages.scss */
.blur-page.blur-load {
  background-image: inherit;
  -webkit-transition: background-image .2s linear;
  transition: background-image .2s linear;
}
/* line 33, ../sass/elements/_blur-pages.scss */
.blur-page.blur-load .page-box {
  opacity: 1;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 38, ../sass/elements/_blur-pages.scss */
.blur-page.blur-load .blur-box {
  opacity: 1;
  -webkit-transition: opacity .4s linear;
  transition: opacity .4s linear;
}
/* line 43, ../sass/elements/_blur-pages.scss */
.blur-page.blur-load .blur-box img,
.blur-page.blur-load .blur-box canvas {
  background-size: cover;
  left: 0;
  max-width: inherit;
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  vertical-align: top;
  width: auto;
  z-index: 0;
}
/* line 57, ../sass/elements/_blur-pages.scss */
.blur-page.blur-load .blur-box img {
  visibility: hidden;
}

/******************************************************************************
  Charts
*******************************************************************************/
/* line 4, ../sass/elements/_charts.scss */
.chart {
  position: relative;
  text-align: center;
}

/* line 8, ../sass/elements/_charts.scss */
.jqstooltip {
  box-sizing: content-box;
}

/* line 13, ../sass/elements/_charts.scss */
.graph text {
  overflow: hidden !important;
}

/* line 16, ../sass/elements/_charts.scss */
.morris-hover {
  z-index: 700;
}

/******************************************************************************
  Clients
*******************************************************************************/
/* line 4, ../sass/elements/_clients.scss */
.client {
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  display: inline-block;
  margin: 0 auto 20px;
  max-width: 100%;
  text-align: center;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
  width: 200px;
}
/* line 18, ../sass/elements/_clients.scss */
.client:hover {
  border-color: #e1e1e1;
  -webkit-transition: border-color .2s ease-in;
  transition: border-color .2s ease-in;
}
/* line 23, ../sass/elements/_clients.scss */
.client + .tooltip {
  font-size: 14px;
  z-index: 800;
}
/* line 27, ../sass/elements/_clients.scss */
.client + .tooltip .tooltip-inner {
  padding: 10px;
}
/* line 31, ../sass/elements/_clients.scss */
.carousel .client {
  margin-bottom: 0;
}

/******************************************************************************
  Contact Us
*******************************************************************************/
/* line 4, ../sass/elements/_contact-us-page.scss */
.contact-info {
  margin-top: 30px;
}
/* line 7, ../sass/elements/_contact-us-page.scss */
.contact-info .title {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.3;
  margin: 0 0 22px;
}
/* line 13, ../sass/elements/_contact-us-page.scss */
.contact-info address {
  margin-top: 5px;
  margin-bottom: 35px;
}
/* line 17, ../sass/elements/_contact-us-page.scss */
.contact-info hr {
  margin: 0 0 36px;
}
/* line 20, ../sass/elements/_contact-us-page.scss */
.contact-info .buttons-box {
  margin: 8px 0 0;
}

/* line 24, ../sass/elements/_contact-us-page.scss */
.map-box {
  position: relative;
}
/* line 27, ../sass/elements/_contact-us-page.scss */
.map-box .contact-info {
  background: #fff;
  margin: 0;
  padding: 5px 10px;
  position: absolute;
  top: 150px;
  z-index: 1;
}
/* line 35, ../sass/elements/_contact-us-page.scss */
.map-box .contact-info address {
  margin-bottom: 15px;
}

/******************************************************************************
  Content Block
*******************************************************************************/
/* line 4, ../sass/elements/_content-block.scss */
.content-block {
  box-sizing: border-box;
  padding: 20px;
}
/* line 11, ../sass/elements/_content-block.scss */
.content-block .btn + .btn {
  margin-left: 14px;
}
/* line 14, ../sass/elements/_content-block.scss */
.content-block .btn.pull-left {
  margin: 0 10px 0 0;
}
/* line 17, ../sass/elements/_content-block.scss */
.content-block .btn.pull-right {
  margin: 0 0 0 10px;
}

/******************************************************************************
  Features
*******************************************************************************/
/* line 5, ../sass/elements/_features.scss */
.features-block .header-box {
  display: block;
  min-height: 30px;
  margin: 0 0 13px;
  overflow: hidden;
}
/* line 11, ../sass/elements/_features.scss */
.features-block .header-box .icon-box {
  background: none;
  color: #1e1e1e;
  float: left;
  height: 30px;
  margin: 0 10px 0 0;
  text-align: center;
  width: 24px;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 22, ../sass/elements/_features.scss */
.features-block .header-box .icon-box i:before {
  font-size: 20px;
  line-height: 30px;
}
/* line 28, ../sass/elements/_features.scss */
.features-block .header-box a.icon-box:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 35, ../sass/elements/_features.scss */
.features-block .header-box h6 {
  margin: 6px 0 0;
}

/******************************************************************************
  Frames
*******************************************************************************/
/* line 4, ../sass/elements/_frames.scss */
.frame {
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
/* line 8, ../sass/elements/_frames.scss */
.frame > img {
  width: 100%;
}

/* line 12, ../sass/elements/_frames.scss */
.frame-padding {
  background: #fff;
  padding: 4px;
}

/* line 16, ../sass/elements/_frames.scss */
.frame-border {
  border-radius: 5px;
}

/* line 21, ../sass/elements/_frames.scss */
.frame-shadow {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

/* line 26, ../sass/elements/_frames.scss */
[class*="frame-shadow"] {
  background: #fff;
  position: relative;
}

/* line 31, ../sass/elements/_frames.scss */
.frame-shadow-lifted:before, .frame-shadow-lifted:after {
  bottom: 15px;
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  height: 20px;
  left: 10px;
  position: absolute;
  width: 50%;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
  z-index: -1;
}
/* line 50, ../sass/elements/_frames.scss */
.frame-shadow-lifted:after {
  right: 10px;
  left: auto;
  -webkit-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
}

/* line 60, ../sass/elements/_frames.scss */
.frame-shadow-perspective:before {
  bottom: 5px;
  box-shadow: -80px 0 8px rgba(0, 0, 0, 0.4);
  content: "";
  height: 35%;
  left: 80px;
  max-width: 200px;
  max-height: 50px;
  position: absolute;
  width: 50%;
  -webkit-transform: skew(50deg);
  -ms-transform: skew(50deg);
  transform: skew(50deg);
  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
  z-index: -1;
}

/* line 84, ../sass/elements/_frames.scss */
.frame-shadow-curved:before {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
  border-radius: 10px/100px;
  bottom: 10px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 10px;
  z-index: -1;
}

/* line 99, ../sass/elements/_frames.scss */
.frame-shadow-raised {
  box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

/* line 113, ../sass/elements/_frames.scss */
.rotated-box {
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}

/* line 120, ../sass/elements/_frames.scss */
.rotated-right-box {
  -webkit-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
}

/******************************************************************************
  Full Width Box
*******************************************************************************/
/* line 4, ../sass/elements/_full-width-box.scss */
.full-width-box {
  overflow: hidden;
  padding: 76px 0;
  position: relative;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
@media (max-width: 767px) {
  /* line 4, ../sass/elements/_full-width-box.scss */
  .full-width-box {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
/* line 14, ../sass/elements/_full-width-box.scss */
.full-width-box .auto-width {
  margin-left: -15px;
  margin-right: -15px;
  opacity: 0;
}
/* line 19, ../sass/elements/_full-width-box.scss */
.full-width-box .auto-width.loaded {
  margin-left: 0;
  margin-right: 0;
  opacity: 1;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 26, ../sass/elements/_full-width-box.scss */
.container .full-width-box {
  margin-left: -15px;
  margin-right: -15px;
}
/* line 30, ../sass/elements/_full-width-box.scss */
.full-width-box .fwb-bg {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 0;
}
/* line 43, ../sass/elements/_full-width-box.scss */
.full-width-box .fwb-bg .overlay {
  background: #000;
  height: 100%;
  opacity: .2;
  filter: alpha(opacity=20);
  width: 100%;
}
@media (max-width: 767px) {
  /* line 52, ../sass/elements/_full-width-box.scss */
  .full-width-box .container {
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
}
/* line 59, ../sass/elements/_full-width-box.scss */
.full-width-box .container,
.full-width-box .page-header {
  position: relative;
}
/* line 63, ../sass/elements/_full-width-box.scss */
.full-width-box .fwb-fixed,
.full-width-box .fwb-paralax {
  background-attachment: fixed;
}
/* line 67, ../sass/elements/_full-width-box.scss */
.touch-device .full-width-box .fwb-paralax, .touch-device .full-width-box .fwb-fixed {
  background-attachment: scroll;
  background-position: 50% 50% !important;
}
/* line 72, ../sass/elements/_full-width-box.scss */
.full-width-box .fwb-blur {
  background-position: center top !important;
}
/* line 75, ../sass/elements/_full-width-box.scss */
.full-width-box .fwb-blur .blur-box {
  bottom: 0;
  display: block !important;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -100;
}
/* line 84, ../sass/elements/_full-width-box.scss */
.full-width-box .fwb-blur .blur-box canvas {
  background-size: cover;
  background-attachment: fixed;
  max-width: inherit;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  vertical-align: top;
}
/* line 95, ../sass/elements/_full-width-box.scss */
.full-width-box .fwb-video {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
/* line 100, ../sass/elements/_full-width-box.scss */
.full-width-box .fwb-video video,
.full-width-box .fwb-video iframe,
.full-width-box .fwb-video img {
  background-attachment: fixed;
  vertical-align: top;
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: inherit;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
}

/******************************************************************************
  Galleries
*******************************************************************************/
/* line 4, ../sass/elements/_galleries.scss */
.gallery .images-box {
  margin-bottom: 30px;
}

/* line 7, ../sass/elements/_galleries.scss */
.gallery-images {
  border-radius: 5px;
  display: block;
  line-height: 0;
  max-width: 100%;
  position: relative;
}
/* line 16, ../sass/elements/_galleries.scss */
.gallery-images img {
  border-radius: 5px;
}
/* line 19, ../sass/elements/_galleries.scss */
.gallery-images .bg-images {
  border-radius: 4px;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
}
/* line 29, ../sass/elements/_galleries.scss */
.gallery-images .bg-images i {
  margin: 5px 0 0 5px;
}
/* line 32, ../sass/elements/_galleries.scss */
.gallery-images .bg-images i:before {
  color: #fff;
  font-size: 20px;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
}
/* line 41, ../sass/elements/_galleries.scss */
.gallery-images:hover > .bg-images {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
}
/* line 47, ../sass/elements/_galleries.scss */
.gallery-images:hover .bg-images i:before {
  color: #000;
  font-size: 30px;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
}

/******************************************************************************
  Latest Posts
*******************************************************************************/
/* line 4, ../sass/elements/_latest-posts.scss */
.latest-posts {
  list-style: none;
  margin: 0;
  padding: 0;
}
/* line 9, ../sass/elements/_latest-posts.scss */
.latest-posts li {
  overflow: hidden;
  padding: 22px 0;
}
/* line 13, ../sass/elements/_latest-posts.scss */
.latest-posts li + li {
  border-top-width: 1px;
}
/* line 16, ../sass/elements/_latest-posts.scss */
.latest-posts li:first-child {
  padding-top: 0;
}
/* line 19, ../sass/elements/_latest-posts.scss */
.latest-posts li:last-child {
  padding-bottom: 0;
}
/* line 22, ../sass/elements/_latest-posts.scss */
.latest-posts li .image {
  float: left;
  height: 84px;
  margin: 0 25px 0 0;
  width: 84px;
}
/* line 28, ../sass/elements/_latest-posts.scss */
.latest-posts li .meta {
  color: #7f7f7f;
  font-size: 11px;
  line-height: 18px;
  margin: 0 0 10px;
}
/* line 34, ../sass/elements/_latest-posts.scss */
.latest-posts li .description {
  overflow: hidden;
}
/* line 37, ../sass/elements/_latest-posts.scss */
.latest-posts li a {
  color: #1e1e1e;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  -webkit-transition: opacity linear .2s;
          transition: opacity linear .2s;
}
/* line 44, ../sass/elements/_latest-posts.scss */
.latest-posts li a:hover {
  opacity: .8;
  filter: alpha(opacity=80);
  text-decoration: none;
}

/* line 52, ../sass/elements/_latest-posts.scss */
.latest-posts-white li {
  border-color: rgba(255, 255, 255, 0.3);
}
/* line 55, ../sass/elements/_latest-posts.scss */
.latest-posts-white li .meta {
  color: rgba(255, 255, 255, 0.6);
}
/* line 58, ../sass/elements/_latest-posts.scss */
.latest-posts-white li a {
  color: rgba(255, 255, 255, 0.8);
}

/******************************************************************************
  Login Page
*******************************************************************************/
/* Login Page 1 */
/* line 5, ../sass/elements/_login-page.scss */
#main.login-register {
  overflow: hidden;
  padding-bottom: 40px;
}

/* line 9, ../sass/elements/_login-page.scss */
.form-box.forgot-form {
  margin-bottom: 0;
}

/* line 12, ../sass/elements/_login-page.scss */
.info-box {
  margin-bottom: 40px;
  padding: 27px 0 0;
}

/* line 16, ../sass/elements/_login-page.scss */
.info-box .title,
.form-box .title {
  font-weight: normal;
  margin: 0 0 17px;
}

/* line 21, ../sass/elements/_login-page.scss */
.info-box .descriptions,
.form-box .descriptions {
  margin: 0 0 26px;
}

/* line 25, ../sass/elements/_login-page.scss */
.form-box {
  background: #f2f2f2;
  border-radius: 5px;
  margin: 0 0 40px 0;
  padding: 27px 30px 25px;
}
/* line 33, ../sass/elements/_login-page.scss */
.form-box .checkbox {
  font-size: 12px;
  margin: 0px 0 28px;
}
/* line 37, ../sass/elements/_login-page.scss */
.form-box .checkbox .new-checkbox {
  margin-top: 0;
}
/* line 41, ../sass/elements/_login-page.scss */
.form-box .buttons-box {
  font-size: 11px;
  line-height: 1.5;
}
/* line 45, ../sass/elements/_login-page.scss */
.form-box .buttons-box .btn {
  margin: 3px 8px 5px 0;
}
/* line 48, ../sass/elements/_login-page.scss */
.form-box .buttons-box .required {
  font-size: 11px;
  margin: 3px 8px 5px 0;
}
/* line 52, ../sass/elements/_login-page.scss */
.form-box .buttons-box .forgot {
  display: inline-block;
  margin: 3px 8px 5px 0;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 58, ../sass/elements/_login-page.scss */
.form-box .buttons-box .forgot:hover {
  text-decoration: none;
  opacity: 0.8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 67, ../sass/elements/_login-page.scss */
.form-box .form-group {
  position: relative;
}
/* line 70, ../sass/elements/_login-page.scss */
.form-box .help-block {
  bottom: -20px;
  margin: 0;
  position: absolute;
}

/* Login Page 2 */
/* line 78, ../sass/elements/_login-page.scss */
.page-login-promo .page-box {
  display: table;
  height: 100% !important;
  width: 100%;
}
/* line 83, ../sass/elements/_login-page.scss */
.page-login-promo .header {
  min-height: 1px;
  position: absolute;
  width: 100%;
}
/* line 88, ../sass/elements/_login-page.scss */
.page-login-promo .form-box {
  margin: 0;
}
/* line 92, ../sass/elements/_login-page.scss */
.page-login-promo .form-box .title small {
  color: #c1c1c2;
  font-size: 24px;
}
/* line 96, ../sass/elements/_login-page.scss */
.page-login-promo .form-box .title a {
  color: #c1c1c2;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 101, ../sass/elements/_login-page.scss */
.page-login-promo .form-box .title a:hover {
  text-decoration: none;
  opacity: 0.8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 111, ../sass/elements/_login-page.scss */
.page-login-promo #main {
  display: table-cell;
  padding: 134px 0 30px;
  vertical-align: middle;
}
/* line 116, ../sass/elements/_login-page.scss */
.page-login-promo .rotation {
  position: relative;
}
/* line 119, ../sass/elements/_login-page.scss */
.page-login-promo .form-content {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}
/* line 125, ../sass/elements/_login-page.scss */
.page-login-promo .front-end,
.page-login-promo .back-end {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: table;
  table-layout: fixed;
  -webkit-transition: .3s opacity 0s ease-in-out, -webkit-transform .6s ease-in-out;
          transition: .3s opacity 0s ease-in-out, transform .6s ease-in-out;
  width: 100%;
}
/* line 135, ../sass/elements/_login-page.scss */
.page-login-promo .back-end {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}
/* line 142, ../sass/elements/_login-page.scss */
.page-login-promo .hover .back-end {
  opacity: 1;
}
/* line 145, ../sass/elements/_login-page.scss */
.page-login-promo .hover .front-end,
.page-login-promo .hover .back-end {
  -webkit-transition: .3s opacity .3s ease-in-out, -webkit-transform .6s ease-in-out;
          transition: .3s opacity .3s ease-in-out, transform .6s ease-in-out;
}

/******************************************************************************
  Manufactures
*******************************************************************************/
/* line 5, ../sass/elements/_manufactures.scss */
.manufactures .make-wrapper {
  float: left;
  height: 164px;
  margin: 0 15px;
  width: 164px;
}
/* line 11, ../sass/elements/_manufactures.scss */
.manufactures .make {
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 4px;
  display: table-cell;
  height: 164px;
  line-height: 0;
  max-height: 100%;
  max-width: 164px;
  opacity: .6;
  filter: alpha(opacity=60);
  padding: 0 20px;
  text-align: center;
  vertical-align: middle;
  -webkit-transition: background .2s linear, border .2s linear, opacity .2s linear;
  transition: background .2s linear, border .2s linear, opacity .2s linear;
}
/* line 34, ../sass/elements/_manufactures.scss */
.manufactures a.make:hover {
  background: #f2f2f2;
  border: 1px solid #ccc;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: background .2s linear, border .2s linear, opacity .2s linear;
  transition: background .2s linear, border .2s linear, opacity .2s linear;
}

/* line 49, ../sass/elements/_manufactures.scss */
.manufactures-list {
  text-align: center;
}
/* line 52, ../sass/elements/_manufactures.scss */
.manufactures-list .make-wrapper {
  display: inline-block;
  float: none;
  margin-bottom: 30px;
}

/******************************************************************************
  News
*******************************************************************************/
/* line 5, ../sass/elements/_news.scss */
.news .news-item {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 35px;
}
/* line 10, ../sass/elements/_news.scss */
.news .news-item .time {
  color: #7f7f7f;
  margin-bottom: 2px;
}
/* line 14, ../sass/elements/_news.scss */
.news .news-item .title {
  font-size: 14px;
  margin-bottom: 7px;
}
/* line 18, ../sass/elements/_news.scss */
.news .news-item .title a {
  color: #1e1e1e;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 22, ../sass/elements/_news.scss */
.news .news-item .title a:hover {
  opacity: .75;
  filter: alpha(opacity=75);
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 29, ../sass/elements/_news.scss */
.news .news-item .more {
  color: #7f7f7f;
  margin: 5px 0 0;
  text-decoration: none;
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 35, ../sass/elements/_news.scss */
.news .news-item .more:hover {
  opacity: .8;
  filter: alpha(opacity=80);
  -webkit-transition: all .2s linear;
          transition: all .2s linear;
}
/* line 40, ../sass/elements/_news.scss */
.news .news-item .more span {
  font-size: 16px;
  line-height: 20px;
  vertical-align: top;
}

/******************************************************************************
  Packages
*******************************************************************************/
/* line 4, ../sass/elements/_packages.scss */
.package {
  background: #f7f7f7;
  margin: 0 auto 40px;
  max-width: 370px;
}
/* line 9, ../sass/elements/_packages.scss */
.package .title {
  line-height: 32px;
  padding: 20px 20px 15px;
}
/* line 13, ../sass/elements/_packages.scss */
.package .title a {
  color: #1e1e1e;
  font-size: 28px;
  font-weight: bold;
  line-height: 32px;
  text-decoration: none;
}
/* line 21, ../sass/elements/_packages.scss */
.package .price-box {
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
  padding: 0 20px 20px;
}
/* line 27, ../sass/elements/_packages.scss */
.package .price-box .icon {
  color: #505050;
  height: 96px;
  text-align: center;
  width: 96px;
}
/* line 33, ../sass/elements/_packages.scss */
.package .price-box .icon i {
  background: none;
  font-size: 50px;
  height: auto;
  line-height: 100px;
  margin: 0;
  width: auto;
}
/* line 42, ../sass/elements/_packages.scss */
.package .price-box .description {
  margin: 0 120px 17px 0;
}
/* line 45, ../sass/elements/_packages.scss */
.package .price-box .price {
  font-size: 36px;
  font-weight: bold;
  line-height: 1;
  margin: 7px 0 0;
}
/* line 51, ../sass/elements/_packages.scss */
.package .price-box .price span {
  font-size: 12px;
}
/* line 56, ../sass/elements/_packages.scss */
.package .bottom-box {
  border-top-width: 1px;
  overflow: hidden;
  padding: 19px 19px 20px;
}
/* line 61, ../sass/elements/_packages.scss */
.package .bottom-box .more {
  color: #7f7f7f;
  display: block;
  float: left;
  font-size: 12px;
  line-height: 1;
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 71, ../sass/elements/_packages.scss */
.package .bottom-box .more:hover {
  opacity: .65;
  filter: alpha(opacity=65);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 77, ../sass/elements/_packages.scss */
.package .bottom-box .more span {
  font-size: 17px;
  line-height: 12px;
  margin: 0 0 0 3px;
  vertical-align: top;
}
/* line 84, ../sass/elements/_packages.scss */
.package .bottom-box .rating-box {
  float: right;
}

/******************************************************************************
  404
*******************************************************************************/
/* line 4, ../sass/elements/_page-404.scss */
.box-404 {
  border-radius: 177px;
  font-weight: normal;
  height: 354px;
  margin: 0 auto;
  padding: 92px 0 0;
  text-align: center;
  width: 354px;
}
/* line 15, ../sass/elements/_page-404.scss */
.box-404 h1 {
  font-size: 150px;
  font-weight: normal;
  line-height: 0.9;
  margin: 0;
}
/* line 21, ../sass/elements/_page-404.scss */
.box-404 h2 {
  color: #7f7f7f;
  font-size: 18px;
  font-weight: normal;
  line-height: 1;
  margin: 0;
}

/* line 30, ../sass/elements/_page-404.scss */
.page-404-promo .page-box {
  background: #2b8cd6;
  background: rgba(43, 140, 214, 0.9);
  display: table;
  height: 100% !important;
  width: 100%;
}
/* line 37, ../sass/elements/_page-404.scss */
.page-404-promo #main {
  display: table-cell;
  padding: 20px 0;
  vertical-align: middle;
}
/* line 42, ../sass/elements/_page-404.scss */
.page-404-promo #main .title {
  border-bottom: 1px solid #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  padding: 0 10px;
  text-align: center;
}
/* line 49, ../sass/elements/_page-404.scss */
.page-404-promo #main .title h1 {
  display: inline-block;
  font-size: 200px;
  font-weight: normal;
  line-height: 1;
  margin: 0;
  position: relative;
}
/* line 57, ../sass/elements/_page-404.scss */
.page-404-promo #main .title h1:before {
  background: #fff;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
}
/* line 69, ../sass/elements/_page-404.scss */
.page-404-promo #main .content {
  color: #fff;
  padding: 35px 10px 0;
  text-align: center;
}
/* line 74, ../sass/elements/_page-404.scss */
.page-404-promo #main .content h2 {
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 38px;
}
/* line 79, ../sass/elements/_page-404.scss */
.page-404-promo #main .content .back-home {
  color: #2b8cd6;
  font-size: 18px;
  padding-right: 30px;
  padding-left: 20px;
}
/* line 85, ../sass/elements/_page-404.scss */
.page-404-promo #main .content .back-home .fa {
  font-size: 22px;
  margin-right: 8px;
}

/******************************************************************************
  Pricings
*******************************************************************************/
/* line 4, ../sass/elements/_pricings.scss */
.pricing {
  background-color: #fff;
  margin: 0 auto 40px;
  max-width: 270px;
  position: relative;
  text-align: left;
}
/* line 11, ../sass/elements/_pricings.scss */
.pricing * {
  position: relative;
}
/* line 14, ../sass/elements/_pricings.scss */
.pricing:before {
  background: #f7f7f7;
  background: rgba(30, 30, 30, 0.06);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
/* line 24, ../sass/elements/_pricings.scss */
.pricing .title {
  line-height: 32px;
  padding: 17px 20px 21px;
}
/* line 28, ../sass/elements/_pricings.scss */
.pricing .title a {
  color: #1e1e1e;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  text-decoration: none;
}
/* line 36, ../sass/elements/_pricings.scss */
.pricing .price-box {
  font-size: 12px;
  line-height: 1;
  overflow: hidden;
  padding: 0 20px 20px;
}
/* line 42, ../sass/elements/_pricings.scss */
.pricing .price-box .icon {
  background: #fff;
  color: #505050;
  height: 60px;
  text-align: center;
  width: 60px;
  z-index: 1;
}
/* line 50, ../sass/elements/_pricings.scss */
.pricing .price-box .icon i,
.pricing .price-box .icon .livicon {
  background: none;
  font-size: 30px;
  height: auto;
  line-height: 52px;
  margin: 0;
  width: auto;
}
/* line 59, ../sass/elements/_pricings.scss */
.pricing .price-box .icon i svg,
.pricing .price-box .icon .livicon svg {
  top: 0 !important;
  vertical-align: middle;
}
/* line 65, ../sass/elements/_pricings.scss */
.pricing .price-box .price {
  font-size: 36px;
  font-weight: bold;
  margin: 13px 0 0;
}
/* line 70, ../sass/elements/_pricings.scss */
.pricing .price-box .price span {
  font-size: 12px;
}
/* line 75, ../sass/elements/_pricings.scss */
.pricing .options {
  list-style: none;
  margin: 0;
  padding: 0;
}
/* line 80, ../sass/elements/_pricings.scss */
.pricing .options li {
  border-top: 1px solid rgba(30, 30, 30, 0.1);
  color: #999;
  color: rgba(30, 30, 30, 0.4);
  line-height: 22px;
  padding: 13px 10px 13px 45px;
  position: relative;
}
/* line 88, ../sass/elements/_pricings.scss */
.pricing .options li span {
  color: #1e1e1e;
  display: none;
  left: 25px;
  line-height: 1;
  position: absolute;
  top: 16px;
}
/* line 96, ../sass/elements/_pricings.scss */
.pricing .options li.active {
  color: #1e1e1e;
}
/* line 99, ../sass/elements/_pricings.scss */
.pricing .options li.active span {
  display: block;
}
/* line 105, ../sass/elements/_pricings.scss */
.pricing .bottom-box {
  border-top: 1px solid rgba(30, 30, 30, 0.1);
  background: rgba(30, 30, 30, 0.05);
  overflow: hidden;
  padding: 19px 19px 20px;
}
/* line 111, ../sass/elements/_pricings.scss */
.pricing .bottom-box .clearfix {
  clear: both;
}
/* line 114, ../sass/elements/_pricings.scss */
.pricing .bottom-box .more {
  color: #7f7f7f;
  color: rgba(30, 30, 30, 0.7);
  display: block;
  float: left;
  font-size: 12px;
  line-height: 1;
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 125, ../sass/elements/_pricings.scss */
.pricing .bottom-box .more:hover {
  opacity: .65;
  filter: alpha(opacity=65);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 131, ../sass/elements/_pricings.scss */
.pricing .bottom-box .more span {
  font-size: 17px;
  line-height: 12px;
  margin: 0 0 0 3px;
  vertical-align: top;
}
/* line 138, ../sass/elements/_pricings.scss */
.pricing .bottom-box .rating-box {
  float: right;
}
/* line 141, ../sass/elements/_pricings.scss */
.pricing .bottom-box .btn {
  font-weight: bold;
  margin: 19px 0 0;
  width: 100%;
}
/* line 159, ../sass/elements/_pricings.scss */
.pricing.pricing-error:before {
  background: rgba(193, 8, 65, 0.06);
}
/* line 162, ../sass/elements/_pricings.scss */
.pricing.pricing-error .title a {
  color: #c10841;
}
/* line 165, ../sass/elements/_pricings.scss */
.pricing.pricing-error .price-box .icon {
  color: #c10841;
  border-color: #c10841;
}
/* line 169, ../sass/elements/_pricings.scss */
.pricing.pricing-error .options li,
.pricing.pricing-error .bottom-box {
  border-color: rgba(193, 8, 65, 0.1);
  color: rgba(193, 8, 65, 0.4);
}
/* line 174, ../sass/elements/_pricings.scss */
.pricing.pricing-error .bottom-box {
  border-top: 1px solid rgba(193, 8, 65, 0.1);
  background: rgba(193, 8, 65, 0.05);
}
/* line 178, ../sass/elements/_pricings.scss */
.pricing.pricing-error .options li span,
.pricing.pricing-error .bottom-box .more,
.pricing.pricing-error .options li.active {
  color: #c10841;
}
/* line 159, ../sass/elements/_pricings.scss */
.pricing.pricing-warning:before {
  background: rgba(248, 148, 6, 0.06);
}
/* line 162, ../sass/elements/_pricings.scss */
.pricing.pricing-warning .title a {
  color: #f89406;
}
/* line 165, ../sass/elements/_pricings.scss */
.pricing.pricing-warning .price-box .icon {
  color: #f89406;
  border-color: #f89406;
}
/* line 169, ../sass/elements/_pricings.scss */
.pricing.pricing-warning .options li,
.pricing.pricing-warning .bottom-box {
  border-color: rgba(248, 148, 6, 0.1);
  color: rgba(248, 148, 6, 0.4);
}
/* line 174, ../sass/elements/_pricings.scss */
.pricing.pricing-warning .bottom-box {
  border-top: 1px solid rgba(248, 148, 6, 0.1);
  background: rgba(248, 148, 6, 0.05);
}
/* line 178, ../sass/elements/_pricings.scss */
.pricing.pricing-warning .options li span,
.pricing.pricing-warning .bottom-box .more,
.pricing.pricing-warning .options li.active {
  color: #f89406;
}
/* line 159, ../sass/elements/_pricings.scss */
.pricing.pricing-danger:before {
  background: rgba(193, 8, 65, 0.06);
}
/* line 162, ../sass/elements/_pricings.scss */
.pricing.pricing-danger .title a {
  color: #c10841;
}
/* line 165, ../sass/elements/_pricings.scss */
.pricing.pricing-danger .price-box .icon {
  color: #c10841;
  border-color: #c10841;
}
/* line 169, ../sass/elements/_pricings.scss */
.pricing.pricing-danger .options li,
.pricing.pricing-danger .bottom-box {
  border-color: rgba(193, 8, 65, 0.1);
  color: rgba(193, 8, 65, 0.4);
}
/* line 174, ../sass/elements/_pricings.scss */
.pricing.pricing-danger .bottom-box {
  border-top: 1px solid rgba(193, 8, 65, 0.1);
  background: rgba(193, 8, 65, 0.05);
}
/* line 178, ../sass/elements/_pricings.scss */
.pricing.pricing-danger .options li span,
.pricing.pricing-danger .bottom-box .more,
.pricing.pricing-danger .options li.active {
  color: #c10841;
}
/* line 159, ../sass/elements/_pricings.scss */
.pricing.pricing-info:before {
  background: rgba(0, 152, 202, 0.06);
}
/* line 162, ../sass/elements/_pricings.scss */
.pricing.pricing-info .title a {
  color: #0098ca;
}
/* line 165, ../sass/elements/_pricings.scss */
.pricing.pricing-info .price-box .icon {
  color: #0098ca;
  border-color: #0098ca;
}
/* line 169, ../sass/elements/_pricings.scss */
.pricing.pricing-info .options li,
.pricing.pricing-info .bottom-box {
  border-color: rgba(0, 152, 202, 0.1);
  color: rgba(0, 152, 202, 0.4);
}
/* line 174, ../sass/elements/_pricings.scss */
.pricing.pricing-info .bottom-box {
  border-top: 1px solid rgba(0, 152, 202, 0.1);
  background: rgba(0, 152, 202, 0.05);
}
/* line 178, ../sass/elements/_pricings.scss */
.pricing.pricing-info .options li span,
.pricing.pricing-info .bottom-box .more,
.pricing.pricing-info .options li.active {
  color: #0098ca;
}
/* line 159, ../sass/elements/_pricings.scss */
.pricing.pricing-success:before {
  background: rgba(115, 141, 0, 0.06);
}
/* line 162, ../sass/elements/_pricings.scss */
.pricing.pricing-success .title a {
  color: #738d00;
}
/* line 165, ../sass/elements/_pricings.scss */
.pricing.pricing-success .price-box .icon {
  color: #738d00;
  border-color: #738d00;
}
/* line 169, ../sass/elements/_pricings.scss */
.pricing.pricing-success .options li,
.pricing.pricing-success .bottom-box {
  border-color: rgba(115, 141, 0, 0.1);
  color: rgba(115, 141, 0, 0.4);
}
/* line 174, ../sass/elements/_pricings.scss */
.pricing.pricing-success .bottom-box {
  border-top: 1px solid rgba(115, 141, 0, 0.1);
  background: rgba(115, 141, 0, 0.05);
}
/* line 178, ../sass/elements/_pricings.scss */
.pricing.pricing-success .options li span,
.pricing.pricing-success .bottom-box .more,
.pricing.pricing-success .options li.active {
  color: #738d00;
}

/******************************************************************************
  Respond
*******************************************************************************/
/* line 4, ../sass/elements/_respond.scss */
.respond {
  background: none;
  border: 0 none;
}
/* line 8, ../sass/elements/_respond.scss */
.respond .description {
  background: #fff;
  border-radius: 5px;
  color: #1e1e1e;
  font-style: italic;
  font-size: 90%;
  line-height: 1.4;
  margin-bottom: 10px;
  padding: 20px;
  position: relative;
}
/* line 19, ../sass/elements/_respond.scss */
.respond .name {
  color: #7f7f7f;
  min-height: 22px;
  padding: 5px 5px 5px 5px;
}
/* line 24, ../sass/elements/_respond.scss */
.respond .name .icon {
  color: #7f7f7f;
  font-size: 30px;
  margin: -5px 0 0;
}
/* line 31, ../sass/elements/_respond.scss */
.respond.bg .description {
  background-color: #a5e573;
}
/* line 34, ../sass/elements/_respond.scss */
.respond.bg .description:before {
  border: 10px solid transparent;
  border-top-color: #a5e573;
  bottom: -20px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
}
/* line 47, ../sass/elements/_respond.scss */
.respond.border .description {
  border-color: #a5e573;
  border-width: 1px;
}
/* line 51, ../sass/elements/_respond.scss */
.respond.border .description:before {
  border: 10px solid transparent;
  border-top-color: #a5e573;
  bottom: -20px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
}
/* line 61, ../sass/elements/_respond.scss */
.respond.border .description:after {
  border: 10px solid transparent;
  border-top-color: #fff;
  bottom: -18px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
}
/* line 72, ../sass/elements/_respond.scss */
.respond.border.respond-blockquote blockquote:before, .respond.border.respond-blockquote blockquote:after {
  color: #a5e573;
}
/* line 78, ../sass/elements/_respond.scss */
.respond.white .name {
  color: #fff;
}
/* line 81, ../sass/elements/_respond.scss */
.respond.white .name .icon {
  color: #fff;
}
/* line 87, ../sass/elements/_respond.scss */
.respond.respond-blockquote blockquote {
  border: none;
  margin: 0;
  padding-left: 30px;
}
/* line 92, ../sass/elements/_respond.scss */
.respond.respond-blockquote blockquote:before {
  content: open-quote;
  font-size: 50px;
  font-weight: bold;
  left: 16px;
  line-height: 1;
  position: absolute;
  top: 14px;
}
/* line 101, ../sass/elements/_respond.scss */
.respond.respond-blockquote blockquote:after {
  content: close-quote;
  display: inline-block;
  font-size: 50px;
  font-weight: bold;
  line-height: 0;
  margin: 0 0 -5px 0;
  vertical-align: bottom;
}
/* line 112, ../sass/elements/_respond.scss */
.respond.respond-blockquote .name .icon {
  float: left;
  height: 50px;
  margin: 0 10px 0 0;
  width: 50px;
}
/* line 118, ../sass/elements/_respond.scss */
.respond.respond-blockquote .name strong {
  font-size: 120%;
}

/* line 124, ../sass/elements/_respond.scss */
.respond-carousel {
  overflow: hidden;
}
/* line 127, ../sass/elements/_respond.scss */
.respond-carousel .pagination {
  margin: 0;
}

/* line 143, ../sass/elements/_respond.scss */
.respond .description.bg-primary {
  color: #fff;
}
/* line 146, ../sass/elements/_respond.scss */
.respond .description.bg-primary:before,
.respond .description.border-primary:before {
  border-top-color: #037ac5;
}
/* line 151, ../sass/elements/_respond.scss */
.respond.respond-blockquote.border .border-primary blockquote:before, .respond.respond-blockquote.border .border-primary blockquote:after {
  color: #0098ca;
}

/* line 143, ../sass/elements/_respond.scss */
.respond .description.bg-warning {
  color: #fff;
}
/* line 146, ../sass/elements/_respond.scss */
.respond .description.bg-warning:before,
.respond .description.border-warning:before {
  border-top-color: #f89406;
}
/* line 151, ../sass/elements/_respond.scss */
.respond.respond-blockquote.border .border-warning blockquote:before, .respond.respond-blockquote.border .border-warning blockquote:after {
  color: #0098ca;
}

/* line 143, ../sass/elements/_respond.scss */
.respond .description.bg-danger {
  color: #fff;
}
/* line 146, ../sass/elements/_respond.scss */
.respond .description.bg-danger:before,
.respond .description.border-danger:before {
  border-top-color: #c10841;
}
/* line 151, ../sass/elements/_respond.scss */
.respond.respond-blockquote.border .border-danger blockquote:before, .respond.respond-blockquote.border .border-danger blockquote:after {
  color: #0098ca;
}

/* line 143, ../sass/elements/_respond.scss */
.respond .description.bg-info {
  color: #fff;
}
/* line 146, ../sass/elements/_respond.scss */
.respond .description.bg-info:before,
.respond .description.border-info:before {
  border-top-color: #0098ca;
}
/* line 151, ../sass/elements/_respond.scss */
.respond.respond-blockquote.border .border-info blockquote:before, .respond.respond-blockquote.border .border-info blockquote:after {
  color: #0098ca;
}

/* line 143, ../sass/elements/_respond.scss */
.respond .description.bg-success {
  color: #fff;
}
/* line 146, ../sass/elements/_respond.scss */
.respond .description.bg-success:before,
.respond .description.border-success:before {
  border-top-color: #738d00;
}
/* line 151, ../sass/elements/_respond.scss */
.respond.respond-blockquote.border .border-success blockquote:before, .respond.respond-blockquote.border .border-success blockquote:after {
  color: #0098ca;
}

/* line 158, ../sass/elements/_respond.scss */
.respond .description.bg-white:before,
.respond .description.border-white:before {
  border-top-color: #fff;
}

/* line 162, ../sass/elements/_respond.scss */
.respond.border .description.border-white {
  border-color: #fff;
}

/* line 165, ../sass/elements/_respond.scss */
.respond.respond-blockquote.border .border-white blockquote:before,
.respond.respond-blockquote.border .border-white blockquote:after {
  color: #1e1e1e;
}

/******************************************************************************
  Search Result
*******************************************************************************/
/* line 5, ../sass/elements/_search-result.scss */
.search-result .search-form {
  margin-bottom: 40px;
  position: relative;
}
/* line 9, ../sass/elements/_search-result.scss */
.search-result .search-form .search-string, .search-result .header .search-form .search-string:hover, .header .search-result .search-form .search-string:hover {
  box-sizing: border-box;
  box-shadow: none;
  color: #1e1e1e;
  font-size: 24px;
  height: 60px;
  line-height: 30px;
  margin: 0;
  padding: 14px 50px 14px 18px;
}
/* line 23, ../sass/elements/_search-result.scss */
.search-result .search-form .search-string:focus {
  border-color: #ccc;
}
/* line 27, ../sass/elements/_search-result.scss */
.search-result .search-form .search-submit {
  background: none;
  border-left: 1px solid #e1e1e1;
  height: 30px;
  line-height: 1;
  position: absolute;
  right: 0;
  top: 15px;
  text-align: center;
  width: 46px;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 40, ../sass/elements/_search-result.scss */
.search-result .search-form .search-submit svg {
  margin-top: 2px;
}
/* line 43, ../sass/elements/_search-result.scss */
.search-result .search-form .search-submit:hover {
  opacity: .5;
  filter: alpha(opacity=50);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 51, ../sass/elements/_search-result.scss */
.search-result .filter-buttons,
.search-result .text-results {
  margin-bottom: 30px;
}
/* line 55, ../sass/elements/_search-result.scss */
.search-result .text-results {
  color: #999;
  font-size: 12px;
  padding-top: 9px;
  padding-bottom: 10px;
  text-align: right;
}
/* line 62, ../sass/elements/_search-result.scss */
.search-result .item {
  background: none;
  border: 0 none;
  border-top: 1px solid #e1e1e1;
  margin-top: 0 -15px 40px;
  padding: 37px 0 0;
}
/* line 69, ../sass/elements/_search-result.scss */
.search-result .pagination {
  margin-top: 0;
}

/******************************************************************************
  Services
*******************************************************************************/
/* line 5, ../sass/elements/_services.scss */
.service .icon {
  border-radius: 35px;
  color: #1e1e1e;
  display: block;
  height: 70px;
  float: left;
  margin: 0 30px 0 0;
  text-align: center;
  text-decoration: none;
  width: 70px;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 18, ../sass/elements/_services.scss */
.service .icon:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 24, ../sass/elements/_services.scss */
.service .icon i:before {
  font-size: 30px;
  line-height: 70px;
}
/* line 29, ../sass/elements/_services.scss */
.service .title {
  margin: 2px 0 17px;
}
/* line 32, ../sass/elements/_services.scss */
.service .text-small {
  overflow: hidden;
}

/* line 36, ../sass/elements/_services.scss */
.services .service,
.services .features-block {
  margin-bottom: 40px;
}

/* line 40, ../sass/elements/_services.scss */
.services-box-three-left {
  margin-bottom: 40px;
  padding: 20px;
}

/* line 47, ../sass/elements/_services.scss */
.service a:not(.btn),
.big-services-box a:not(.btn),
.features-block a:not(.btn) {
  color: #1e1e1e;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 51, ../sass/elements/_services.scss */
.service a:not(.btn):hover,
.big-services-box a:not(.btn):hover,
.features-block a:not(.btn):hover {
  text-decoration: none;
  opacity: 0.8;
  filter: alpha(opacity=80);
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
}
/* line 59, ../sass/elements/_services.scss */
.service > a,
.big-services-box > a,
.features-block > a {
  display: inline-block;
}

/* line 63, ../sass/elements/_services.scss */
.white .service a,
.white .big-services-box a {
  color: #fff;
}

/* line 67, ../sass/elements/_services.scss */
.big-services-box {
  margin-bottom: 40px;
  text-align: center;
}

/* line 71, ../sass/elements/_services.scss */
.big-icon {
  border-radius: 50px;
  color: #1e1e1e;
  display: block;
  height: 100px;
  margin: 0 auto 20px;
  text-align: center;
  width: 100px;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

/* line 82, ../sass/elements/_services.scss */
.big-icon i:before {
  font-size: 50px;
  line-height: 100px;
}

/* line 86, ../sass/elements/_services.scss */
a.big-icon:hover {
  opacity: .8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

/* line 92, ../sass/elements/_services.scss */
.big-icon.pull-left {
  margin: 0 20px 20px 0;
}

/******************************************************************************
  Sitemap
*******************************************************************************/
/* line 4, ../sass/elements/_sitemap.scss */
.sitemap, .sitemap ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
/* line 9, ../sass/elements/_sitemap.scss */
.sitemap ul {
  margin: 0 0 0 15px;
}
/* line 13, ../sass/elements/_sitemap.scss */
.sitemap li,
.sitemap .li {
  overflow: hidden;
  position: relative;
}
/* line 18, ../sass/elements/_sitemap.scss */
.sitemap li:after,
.sitemap .li:after {
  content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ";
  display: inline-block;
  font-size: .5em;
  line-height: 40px;
  margin: -6px 0 0 0;
  pointer-events: none;
  position: absolute;
}
/* line 28, ../sass/elements/_sitemap.scss */
.sitemap a {
  background: #fff;
  color: #1e1e1e;
  font-size: 12px;
  line-height: 1;
  padding: 0 5px 0 12px;
  position: relative;
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 39, ../sass/elements/_sitemap.scss */
.sitemap a:hover {
  opacity: .8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 45, ../sass/elements/_sitemap.scss */
.sitemap a:before {
  content: "\f105";
  display: block;
  line-height: 12px;
  left: 0;
  position: absolute;
  top: 2px;
}

/******************************************************************************
  Social Feed
*******************************************************************************/
/* line 4, ../sass/elements/_social-feed.scss */
.social-feed {
  background-image: url("../img/svg/loader.svg"), none;
  background-repeat: no-repeat;
  background-position: 50% 100px;
  background-size: 40px;
}
/* line 10, ../sass/elements/_social-feed.scss */
.social-feed > * {
  opacity: 0;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 15, ../sass/elements/_social-feed.scss */
.social-feed .isotope-item {
  margin-bottom: 20px;
}
/* line 18, ../sass/elements/_social-feed.scss */
.social-feed .post {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 0;
  padding: 19px;
}
/* line 25, ../sass/elements/_social-feed.scss */
.social-feed .post.bg {
  background: #ccc;
  color: #fff;
}
/* line 29, ../sass/elements/_social-feed.scss */
.social-feed .post.bg-danger {
  border-color: #c10841;
}
/* line 32, ../sass/elements/_social-feed.scss */
.social-feed .post.bg-warning {
  border-color: #f89406;
}
/* line 35, ../sass/elements/_social-feed.scss */
.social-feed .post.bg-info {
  border-color: #0098ca;
}
/* line 38, ../sass/elements/_social-feed.scss */
.social-feed .post.bg-success {
  border-color: #738d00;
}
/* line 41, ../sass/elements/_social-feed.scss */
.social-feed .post.bg-primary {
  border-color: #037ac5;
}
/* line 44, ../sass/elements/_social-feed.scss */
.social-feed .post.number {
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
  text-align: center;
}
/* line 50, ../sass/elements/_social-feed.scss */
.social-feed .post.number .post-icon {
  font-size: 24px;
  left: 10px;
  opacity: .8;
  filter: alpha(opacity=80);
  position: absolute;
  top: 10px;
}
/* line 58, ../sass/elements/_social-feed.scss */
.social-feed .post.number .social-data {
  display: inline-block;
  font-style: italic;
  line-height: 1;
  vertical-align: middle;
}
/* line 64, ../sass/elements/_social-feed.scss */
.social-feed .post.number .social-data .count {
  font-size: 60px;
  font-style: normal;
}
/* line 71, ../sass/elements/_social-feed.scss */
.social-feed .add-post {
  background: none;
  border: 0 none;
  padding: 0;
}
/* line 76, ../sass/elements/_social-feed.scss */
.social-feed .add-post .nav-tabs {
  border: 0 none;
}
/* line 80, ../sass/elements/_social-feed.scss */
.social-feed .add-post .nav-tabs > li > a {
  border-radius: 3px 3px 0 0;
  padding: 6px 10px 7px;
}
/* line 84, ../sass/elements/_social-feed.scss */
.social-feed .add-post .nav-tabs > li > a .fa {
  color: #7f7f7f;
}
/* line 89, ../sass/elements/_social-feed.scss */
.social-feed .add-post .nav-tabs > li.active > a,
.social-feed .add-post .nav-tabs > li.active > a:hover,
.social-feed .add-post .nav-tabs > li.active > a:focus {
  color: #555;
  font-weight: normal;
}
/* line 98, ../sass/elements/_social-feed.scss */
.social-feed .add-post .tab-content {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 20px;
}
/* line 104, ../sass/elements/_social-feed.scss */
.social-feed .add-post .tab-content .tab-pane {
  padding: 0;
}
/* line 107, ../sass/elements/_social-feed.scss */
.social-feed .add-post .tab-content .tab-pane form {
  margin: 0;
}
/* line 112, ../sass/elements/_social-feed.scss */
.social-feed .add-post .tab-pane input {
  margin-bottom: 22px;
}
/* line 116, ../sass/elements/_social-feed.scss */
.social-feed .author-info {
  margin-bottom: 15px;
}
/* line 119, ../sass/elements/_social-feed.scss */
.social-feed .author-info .avatar {
  border-radius: 50%;
  float: left;
  height: 50px;
  margin: 0 20px 0 0;
  overflow: hidden;
  width: 50px;
}
/* line 127, ../sass/elements/_social-feed.scss */
.social-feed .author-info .user-name {
  font-size: 16px;
}
/* line 130, ../sass/elements/_social-feed.scss */
.social-feed .author-info .user-name a {
  color: #1e1e1e;
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 136, ../sass/elements/_social-feed.scss */
.social-feed .author-info .user-name a:hover {
  opacity: .8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 144, ../sass/elements/_social-feed.scss */
.social-feed .author-info time {
  color: #7f7f7f;
  font-style: italic;
}
/* line 149, ../sass/elements/_social-feed.scss */
.social-feed .preview {
  margin: 0 -20px 20px;
  text-align: center;
}
/* line 153, ../sass/elements/_social-feed.scss */
.social-feed .preview img {
  display: block;
  margin: 0 auto;
}
/* line 158, ../sass/elements/_social-feed.scss */
.social-feed .buttons {
  margin-bottom: 20px;
  text-align: right;
}
/* line 162, ../sass/elements/_social-feed.scss */
.social-feed .buttons .btn {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 169, ../sass/elements/_social-feed.scss */
.social-feed .buttons .btn:hover {
  opacity: .8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 177, ../sass/elements/_social-feed.scss */
.social-feed .comments {
  background: rgba(0, 0, 0, 0.1);
  margin: 0 -20px -20px;
  padding: 20px;
}
/* line 182, ../sass/elements/_social-feed.scss */
.social-feed .comments .comment {
  margin: 0 0 25px;
}
/* line 185, ../sass/elements/_social-feed.scss */
.social-feed .comments .comment .avatar {
  border-radius: 50%;
  float: left;
  height: 40px;
  margin: 0 10px 5px 0;
  overflow: hidden;
  width: 40px;
}
/* line 193, ../sass/elements/_social-feed.scss */
.social-feed .comments .comment .author-details {
  margin-bottom: 5px;
}
/* line 196, ../sass/elements/_social-feed.scss */
.social-feed .comments .comment .user-name a {
  color: #1e1e1e;
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 202, ../sass/elements/_social-feed.scss */
.social-feed .comments .comment .user-name a:hover {
  opacity: .8;
  filter: alpha(opacity=80);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 209, ../sass/elements/_social-feed.scss */
.social-feed .comments .comment time {
  color: #7f7f7f;
  font-size: 12px;
  font-style: italic;
}
/* line 215, ../sass/elements/_social-feed.scss */
.social-feed .comments .form {
  margin: 0;
}
/* line 218, ../sass/elements/_social-feed.scss */
.social-feed .comments .form .form-control {
  height: 62px;
  margin: 0;
}
/* line 222, ../sass/elements/_social-feed.scss */
.social-feed .comments .form textarea {
  resize: none;
}
/* line 227, ../sass/elements/_social-feed.scss */
.social-feed.loaded {
  background: none;
}
/* line 230, ../sass/elements/_social-feed.scss */
.social-feed.loaded > * {
  opacity: 1;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 237, ../sass/elements/_social-feed.scss */
.social-feed .bg .author-info .user-name a,
.social-feed .bg .comments .comment .user-name a {
  color: #fff;
}
/* line 241, ../sass/elements/_social-feed.scss */
.social-feed .bg .author-info time,
.social-feed .bg .comments .comment time {
  color: rgba(255, 255, 255, 0.8);
}
/* line 245, ../sass/elements/_social-feed.scss */
.social-feed .bg .buttons .btn {
  background: #fff;
  border-color: #fff;
  color: #1e1e1e;
}

/* line 252, ../sass/elements/_social-feed.scss */
.social-feed .bg-danger .buttons .btn {
  color: #c10841;
}

/* line 255, ../sass/elements/_social-feed.scss */
.social-feed .bg-warning .buttons .btn {
  color: #f89406;
}

/* line 258, ../sass/elements/_social-feed.scss */
.social-feed .bg-info .buttons .btn {
  color: #0098ca;
}

/* line 261, ../sass/elements/_social-feed.scss */
.social-feed .bg-success .buttons .btn {
  color: #738d00;
}

/* line 264, ../sass/elements/_social-feed.scss */
.social-feed .bg-primary .buttons .btn {
  color: #037ac5;
}

/* line 267, ../sass/elements/_social-feed.scss */
.social-feed .post.number.twitter {
  background: #00aced;
  border-color: #00aced;
}

/* line 271, ../sass/elements/_social-feed.scss */
.social-feed .post.number.facebook {
  background: #3B5998;
  border-color: #3B5998;
}

/* line 275, ../sass/elements/_social-feed.scss */
.social-feed .post.number.google {
  background: #dd4b39;
  border-color: #dd4b39;
}

/* line 279, ../sass/elements/_social-feed.scss */
.social-feed .post.number.pinterest {
  background: #cb2027;
  border-color: #cb2027;
}

/* line 283, ../sass/elements/_social-feed.scss */
.social-feed .post.number.linkedin {
  background: #007bb6;
  border-color: #007bb6;
}

/* line 287, ../sass/elements/_social-feed.scss */
.social-feed .post.number.youtube {
  background: #bb0000;
  border-color: #bb0000;
}

/******************************************************************************
  Steps
*******************************************************************************/
/* line 4, ../sass/elements/_steps.scss */
.steps {
  display: table;
  table-layout: fixed;
  width: 100%;
}
/* line 9, ../sass/elements/_steps.scss */
.steps .step {
  background: #fff;
  border: 1px solid #e1e1e1;
  display: table-cell;
  padding: 15px;
  position: relative;
  vertical-align: middle;
}
/* line 17, ../sass/elements/_steps.scss */
.steps .step .step-wrapper {
  position: relative;
}
/* line 20, ../sass/elements/_steps.scss */
.steps .step .step-wrapper:after, .steps .step .step-wrapper:before {
  content: '';
  position: absolute;
  top: 0;
  right: -29px;
  border: 7px solid transparent;
  border-left-color: #e1e1e1;
  z-index: 1;
}
/* line 30, ../sass/elements/_steps.scss */
.steps .step .step-wrapper:before {
  border-left-color: #fff;
  right: -27px;
  z-index: 2;
}
/* line 36, ../sass/elements/_steps.scss */
.steps .step + .step {
  border-left: 0 none;
}
/* line 39, ../sass/elements/_steps.scss */
.steps .step:first-child {
  border-radius: 3px 0 0 3px;
}
/* line 42, ../sass/elements/_steps.scss */
.steps .step:last-child {
  border-radius: 0 3px 3px 0;
}
/* line 46, ../sass/elements/_steps.scss */
.steps .step:last-child .step-wrapper:after, .steps .step:last-child .step-wrapper:before {
  display: none;
}
/* line 52, ../sass/elements/_steps.scss */
.steps .step .bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  bottom: 0;
  left: 0;
  opacity: .1;
  filter: alpha(opacity=10);
  position: absolute;
  right: 0;
  top: 0;
}
/* line 64, ../sass/elements/_steps.scss */
.steps .step .bg-image ~ * {
  position: relative;
}
/* line 81, ../sass/elements/_steps.scss */
.steps .step.border-error .step-wrapper:after {
  border-left-color: #c10841;
}
/* line 84, ../sass/elements/_steps.scss */
.steps .step.bg-error .step-wrapper:before {
  border-left-color: #c10841;
}
/* line 81, ../sass/elements/_steps.scss */
.steps .step.border-danger .step-wrapper:after {
  border-left-color: #c10841;
}
/* line 84, ../sass/elements/_steps.scss */
.steps .step.bg-danger .step-wrapper:before {
  border-left-color: #c10841;
}
/* line 81, ../sass/elements/_steps.scss */
.steps .step.border-info .step-wrapper:after {
  border-left-color: #0098ca;
}
/* line 84, ../sass/elements/_steps.scss */
.steps .step.bg-info .step-wrapper:before {
  border-left-color: #0098ca;
}
/* line 81, ../sass/elements/_steps.scss */
.steps .step.border-warning .step-wrapper:after {
  border-left-color: #f89406;
}
/* line 84, ../sass/elements/_steps.scss */
.steps .step.bg-warning .step-wrapper:before {
  border-left-color: #f89406;
}
/* line 81, ../sass/elements/_steps.scss */
.steps .step.border-success .step-wrapper:after {
  border-left-color: #738d00;
}
/* line 84, ../sass/elements/_steps.scss */
.steps .step.bg-success .step-wrapper:before {
  border-left-color: #738d00;
}
/* line 81, ../sass/elements/_steps.scss */
.steps .step.border-grey .step-wrapper:after {
  border-left-color: #777;
}
/* line 84, ../sass/elements/_steps.scss */
.steps .step.bg-grey .step-wrapper:before {
  border-left-color: #777;
}
/* line 88, ../sass/elements/_steps.scss */
.steps .step.bg-grey {
  background: #777;
}
/* line 92, ../sass/elements/_steps.scss */
.steps.steps-apart {
  display: block;
  width: auto;
}
/* line 96, ../sass/elements/_steps.scss */
.steps.steps-apart .step {
  border-radius: 3px;
  display: block;
}
/* line 100, ../sass/elements/_steps.scss */
.steps.steps-apart .step:last-child .step-wrapper:after, .steps.steps-apart .step:last-child .step-wrapper:before {
  display: block;
}
/* line 105, ../sass/elements/_steps.scss */
.steps.steps-apart > [class*="col-md"]:last-child > .step .step-wrapper:after,
.steps.steps-apart > [class*="col-md"]:last-child > .step .step-wrapper:before {
  display: none;
}
/* line 110, ../sass/elements/_steps.scss */
.steps.progress-steps {
  display: block;
}
/* line 113, ../sass/elements/_steps.scss */
.steps.progress-steps .step {
  background: none !important;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  display: block;
  margin: 0 0 40px;
  min-height: 185px;
  padding: 0;
}
/* line 122, ../sass/elements/_steps.scss */
.steps.progress-steps .step .step-wrapper {
  padding: 20px 20px 20px 260px;
  position: static;
}
/* line 126, ../sass/elements/_steps.scss */
.steps.progress-steps .step .number {
  background: #e1e1e1;
  bottom: 0;
  color: #fff;
  font-size: 150px;
  left: 0;
  line-height: 1.2;
  position: absolute;
  text-align: center;
  top: 0;
  width: 240px;
}
/* line 138, ../sass/elements/_steps.scss */
.steps.progress-steps .step .number:after, .steps.progress-steps .step .number:before {
  border: 7px solid transparent;
  border-top-color: #e1e1e1;
  bottom: -15px;
  content: '';
  left: 50%;
  margin-left: -7px;
  position: absolute;
  z-index: 1;
}
/* line 149, ../sass/elements/_steps.scss */
.steps.progress-steps .step .number:before {
  bottom: -13px;
  z-index: 2;
}
/* line 155, ../sass/elements/_steps.scss */
.steps.progress-steps .step.step-right .step-wrapper {
  padding: 20px 260px 20px 20px;
}
/* line 158, ../sass/elements/_steps.scss */
.steps.progress-steps .step.step-right .number {
  left: auto;
  right: 0;
}
/* line 165, ../sass/elements/_steps.scss */
.steps.progress-steps .step .step-wrapper:after, .steps.progress-steps .step .step-wrapper:before, .steps.progress-steps .step:last-child .number:after, .steps.progress-steps .step:last-child .number:before {
  display: none;
}
/* line 182, ../sass/elements/_steps.scss */
.steps.progress-steps .step.border-error .number {
  color: #c10841;
}
/* line 185, ../sass/elements/_steps.scss */
.steps.progress-steps .step.border-error .number:after {
  border-top-color: #c10841;
}
/* line 189, ../sass/elements/_steps.scss */
.steps.progress-steps .step.bg-error .number {
  background: #c10841;
  color: #fff;
}
/* line 193, ../sass/elements/_steps.scss */
.steps.progress-steps .step.bg-error .number:before {
  border-top-color: #c10841;
}
/* line 182, ../sass/elements/_steps.scss */
.steps.progress-steps .step.border-danger .number {
  color: #c10841;
}
/* line 185, ../sass/elements/_steps.scss */
.steps.progress-steps .step.border-danger .number:after {
  border-top-color: #c10841;
}
/* line 189, ../sass/elements/_steps.scss */
.steps.progress-steps .step.bg-danger .number {
  background: #c10841;
  color: #fff;
}
/* line 193, ../sass/elements/_steps.scss */
.steps.progress-steps .step.bg-danger .number:before {
  border-top-color: #c10841;
}
/* line 182, ../sass/elements/_steps.scss */
.steps.progress-steps .step.border-info .number {
  color: #0098ca;
}
/* line 185, ../sass/elements/_steps.scss */
.steps.progress-steps .step.border-info .number:after {
  border-top-color: #0098ca;
}
/* line 189, ../sass/elements/_steps.scss */
.steps.progress-steps .step.bg-info .number {
  background: #0098ca;
  color: #fff;
}
/* line 193, ../sass/elements/_steps.scss */
.steps.progress-steps .step.bg-info .number:before {
  border-top-color: #0098ca;
}
/* line 182, ../sass/elements/_steps.scss */
.steps.progress-steps .step.border-warning .number {
  color: #f89406;
}
/* line 185, ../sass/elements/_steps.scss */
.steps.progress-steps .step.border-warning .number:after {
  border-top-color: #f89406;
}
/* line 189, ../sass/elements/_steps.scss */
.steps.progress-steps .step.bg-warning .number {
  background: #f89406;
  color: #fff;
}
/* line 193, ../sass/elements/_steps.scss */
.steps.progress-steps .step.bg-warning .number:before {
  border-top-color: #f89406;
}
/* line 182, ../sass/elements/_steps.scss */
.steps.progress-steps .step.border-success .number {
  color: #738d00;
}
/* line 185, ../sass/elements/_steps.scss */
.steps.progress-steps .step.border-success .number:after {
  border-top-color: #738d00;
}
/* line 189, ../sass/elements/_steps.scss */
.steps.progress-steps .step.bg-success .number {
  background: #738d00;
  color: #fff;
}
/* line 193, ../sass/elements/_steps.scss */
.steps.progress-steps .step.bg-success .number:before {
  border-top-color: #738d00;
}
/* line 182, ../sass/elements/_steps.scss */
.steps.progress-steps .step.border-grey .number {
  color: #777;
}
/* line 185, ../sass/elements/_steps.scss */
.steps.progress-steps .step.border-grey .number:after {
  border-top-color: #777;
}
/* line 189, ../sass/elements/_steps.scss */
.steps.progress-steps .step.bg-grey .number {
  background: #777;
  color: #fff;
}
/* line 193, ../sass/elements/_steps.scss */
.steps.progress-steps .step.bg-grey .number:before {
  border-top-color: #777;
}

/******************************************************************************
  Team
*******************************************************************************/
/* line 4, ../sass/elements/_team.scss */
.employee {
  border-radius: 3px;
  display: inline-block;
  float: none;
  margin: 0 0 40px;
  min-width: 260px;
  overflow: hidden;
  position: relative;
  text-align: left;
  vertical-align: top;
}
/* line 17, ../sass/elements/_team.scss */
.carousel .employee {
  margin-top: 40px;
}
/* line 20, ../sass/elements/_team.scss */
.employee .image {
  background: #f2f2f2;
  display: block;
  line-height: 0;
  margin: 0;
}
/* line 26, ../sass/elements/_team.scss */
.employee .image > img {
  width: 100%;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
/* line 32, ../sass/elements/_team.scss */
.employee .description {
  background: #f2f2f2;
  border-top-width: 1px;
  color: #505050;
  display: table;
  font-size: 13px;
  height: 72px;
  line-height: 18px;
  text-align: center;
  width: 100%;
}
/* line 43, ../sass/elements/_team.scss */
.employee .description .vertical {
  display: table-cell;
  height: 72px;
  padding-left: 5px;
  padding-right: 5px;
  vertical-align: middle;
}
/* line 51, ../sass/elements/_team.scss */
.employee .name {
  color: #1e1e1e;
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  margin: 0 0 4px;
}
/* line 58, ../sass/elements/_team.scss */
.employee .role {
  font-size: 11px;
}
/* line 61, ../sass/elements/_team.scss */
.employee .employee-hover {
  background: #1e1e1e;
  background: rgba(30, 30, 30, 0.9);
  border-radius: 3px;
  box-sizing: border-box;
  bottom: 0;
  color: #7f7f7f;
  display: none;
  font-size: 12px;
  line-height: 20px;
  left: 15px;
  padding: 16px 20px 90px;
  position: absolute;
  right: 15px;
  top: 0;
}
/* line 79, ../sass/elements/_team.scss */
.employee .employee-hover .name {
  color: #fff;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.4;
  margin: 0 0 7px;
}
/* line 86, ../sass/elements/_team.scss */
.employee .employee-hover .role {
  line-height: 1;
  margin: 0 0 24px;
}
/* line 90, ../sass/elements/_team.scss */
.employee .employee-hover .contact {
  color: #fff;
}
/* line 93, ../sass/elements/_team.scss */
.employee .employee-hover .image {
  background: #f7f7f7;
  border-radius: 3px;
  display: block;
  height: 60px;
  float: right;
  line-height: 0;
  margin: 4px 0 0 5px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 60px;
  z-index: 1;
}
/* line 107, ../sass/elements/_team.scss */
.employee .employee-hover .social {
  bottom: 20px;
  left: 20px;
  position: absolute;
  text-align: center;
}
/* line 113, ../sass/elements/_team.scss */
.employee .employee-hover .social .icon {
  background: #fff;
  float: left;
  margin: 0 10px 0 0;
  opacity: 1;
  filter: alpha(opacity=100);
  position: relative;
}
/* line 121, ../sass/elements/_team.scss */
.employee .employee-hover .social .icon span {
  border-radius: 2px;
  display: block;
  height: 40px;
  opacity: .35;
  filter: alpha(opacity=35);
  -webkit-transition: opacity .2s linear;
          transition: opacity .2s linear;
  width: 40px;
}
/* line 131, ../sass/elements/_team.scss */
.employee .employee-hover .social .icon:hover span {
  opacity: 1;
  filter: alpha(opacity=100);
}

/* line 141, ../sass/elements/_team.scss */
.rotation .employee-hover {
  display: block;
  height: inherit;
  transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-transform: rotateY(-180deg);
  -moz-transform-style: preserve-3d;
  -moz-backface-visibility: hidden;
  -webkit-transition: all .6s ease-in-out;
  transition: all .6s ease-in-out;
  z-index: 13;
}
/* line 159, ../sass/elements/_team.scss */
.rotation.hover .employee-hover {
  -moz-transform: rotateX(0deg) rotateY(0deg);
  -moz-transition-delay: .2s;
  -ms-transition-delay: .2s;
  -o-transition-delay: .2s;
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  -webkit-transition-delay: .2s;
          transition-delay: .2s;
  -webkit-transform: rotateX(0deg) rotateY(0deg);
  -webkit-transition-delay: .2s;
  z-index: 46;
}

@media (max-width: 600px) {
  /* line 175, ../sass/elements/_team.scss */
  .employee-single .images-box {
    display: block;
    float: none !important;
    margin: 0 auto 20px;
  }
}
/* line 181, ../sass/elements/_team.scss */
.employee-single .images-box .carousel-box {
  overflow: hidden;
}
/* line 184, ../sass/elements/_team.scss */
.employee-single .images-box .carousel-box.load {
  max-height: 390px;
  overflow: hidden;
}
/* line 189, ../sass/elements/_team.scss */
.employee-single .images-box .image {
  box-sizing: border-box;
  float: left;
  margin: 0 0 20px;
  text-align: center;
}
/* line 197, ../sass/elements/_team.scss */
.employee-single .images-box img {
  border-radius: 5px;
}
/* line 200, ../sass/elements/_team.scss */
.employee-single .images-box .pagination {
  margin-top: 0;
}
/* line 204, ../sass/elements/_team.scss */
.employee-single.employee {
  margin: 0;
  max-width: inherit;
  width: auto;
}
/* line 210, ../sass/elements/_team.scss */
.employee-single .employee-description .name {
  font-size: 22px;
  line-height: 1.3;
  margin: 0 0 5px;
}
/* line 215, ../sass/elements/_team.scss */
.employee-single .employee-description .role {
  font-size: 14px;
  margin: 0 0 22px;
}
/* line 219, ../sass/elements/_team.scss */
.employee-single .employee-description .social {
  margin: 22px 0 0;
}

/* line 225, ../sass/elements/_team.scss */
.employee-single-rotation .content-box {
  padding-left: 15px;
  padding-right: 15px;
}
@media (max-width: 600px) {
  /* line 230, ../sass/elements/_team.scss */
  .employee-single-rotation .employee,
  .employee-single-rotation .employee.pull-right {
    display: block;
    float: none !important;
    margin: 0 auto 20px;
  }
}

/******************************************************************************
  Timeline
*******************************************************************************/
/* line 4, ../sass/elements/_timeline.scss */
.timeline {
  padding: 20px 0;
  position: relative;
}
/* line 8, ../sass/elements/_timeline.scss */
.timeline:before {
  background-color: #d2d2d2;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  content: "";
  display: block;
  height: 100%;
  left: 50%;
  margin-left: -2px;
  position: absolute;
  top: 0;
  width: 4px;
}
/* line 23, ../sass/elements/_timeline.scss */
.timeline .post {
  border: 0 none;
  margin: 0;
  padding: 0 40px 5px 0;
  width: 50%;
}
/* line 29, ../sass/elements/_timeline.scss */
.timeline .post:last-child, .timeline .post:nth-child(even):last-child {
  padding-bottom: 0;
}
/* line 33, ../sass/elements/_timeline.scss */
.timeline .post:nth-child(even) {
  margin-left: 50%;
  padding: 0 0 5px 40px;
}
/* line 37, ../sass/elements/_timeline.scss */
.timeline .post:nth-child(even) .timeline-time {
  left: auto;
  margin: 31px 40px 0 0;
  right: 50%;
  text-align: right;
}
/* line 43, ../sass/elements/_timeline.scss */
.timeline .post:nth-child(even) .timeline-content:before {
  border: 15px solid transparent;
  border-right-color: #ccc;
  left: -30px;
  right: auto;
}
/* line 49, ../sass/elements/_timeline.scss */
.timeline .post:nth-child(even) .timeline-content:after {
  border: 14px solid transparent;
  border-right-color: #fff;
  left: -28px;
  right: auto;
}
/* line 56, ../sass/elements/_timeline.scss */
.timeline .post .timeline-time {
  color: #999;
  left: 50%;
  margin: 31px 0 0 40px;
  position: absolute;
}
/* line 62, ../sass/elements/_timeline.scss */
.timeline .post .timeline-icon {
  background: #1e1e1e;
  border-radius: 20px;
  color: #fff;
  font-size: 18px;
  height: 40px;
  left: 50%;
  line-height: 40px;
  margin: 22px 0 0 -20px;
  position: absolute;
  text-align: center;
  width: 40px;
}
/* line 77, ../sass/elements/_timeline.scss */
.timeline .post .timeline-icon .livicon svg {
  left: 0 !important;
}
/* line 81, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 46px;
  position: relative;
}
/* line 88, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content:before, .timeline .post .timeline-content:after {
  border: 15px solid transparent;
  border-left-color: #ccc;
  content: "";
  display: block;
  position: absolute;
  right: -30px;
  top: 26px;
}
/* line 98, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content:after {
  border: 14px solid transparent;
  border-left-color: #fff;
  right: -28px;
  top: 27px;
}
/* line 104, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content .video-box:last-child,
.timeline .post .timeline-content blockquote:last-child {
  margin-bottom: 0;
}
/* line 108, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content .map-canvas {
  height: 250px;
}
/* line 111, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content .img-rounded, .timeline .post .timeline-content .img-thumbnail.img-rounded img, .img-thumbnail.img-rounded .timeline .post .timeline-content img,
.timeline .post .timeline-content .img-thumbnail.img-rounded .bg-images,
.img-thumbnail.img-rounded .timeline .post .timeline-content .bg-images, .timeline .post .timeline-content .img-thumbnail.img-circle img, .img-thumbnail.img-circle .timeline .post .timeline-content img,
.timeline .post .timeline-content .img-thumbnail.img-circle .bg-images,
.img-thumbnail.img-circle .timeline .post .timeline-content .bg-images {
  border-radius: 2px;
}
/* line 114, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content .entry-title {
  font-size: 18px;
  margin: 0 0 10px;
}
/* line 118, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content .entry-content {
  margin: 0;
}
/* line 121, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content.bg {
  background: #ccc;
  color: #fff;
}
/* line 125, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content.bg .entry-title a,
.timeline .post .timeline-content.bg blockquote footer,
.timeline .post .timeline-content.bg blockquote small,
.timeline .post .timeline-content.bg blockquote .small {
  color: #fff;
}
/* line 134, ../sass/elements/_timeline.scss */
.timeline .post.no-padding .timeline-content {
  padding: 0;
}
/* line 138, ../sass/elements/_timeline.scss */
.timeline .post:not(.no-padding) .timeline-content.bg:after {
  display: none;
}
/* line 152, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content.bg-danger,
.timeline .post .timeline-content.border-danger {
  border-color: #c10841;
}
/* line 156, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content.bg-danger:before,
.timeline .post .timeline-content.border-danger:before {
  border-left-color: #c10841;
}
/* line 160, ../sass/elements/_timeline.scss */
.timeline .post:nth-child(even) .timeline-content.bg-danger:before, .timeline .post:nth-child(even) .timeline-content.border-danger:before {
  border: 15px solid transparent;
  border-right-color: #c10841;
}
/* line 152, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content.bg-info,
.timeline .post .timeline-content.border-info {
  border-color: #0098ca;
}
/* line 156, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content.bg-info:before,
.timeline .post .timeline-content.border-info:before {
  border-left-color: #0098ca;
}
/* line 160, ../sass/elements/_timeline.scss */
.timeline .post:nth-child(even) .timeline-content.bg-info:before, .timeline .post:nth-child(even) .timeline-content.border-info:before {
  border: 15px solid transparent;
  border-right-color: #0098ca;
}
/* line 152, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content.bg-warning,
.timeline .post .timeline-content.border-warning {
  border-color: #f89406;
}
/* line 156, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content.bg-warning:before,
.timeline .post .timeline-content.border-warning:before {
  border-left-color: #f89406;
}
/* line 160, ../sass/elements/_timeline.scss */
.timeline .post:nth-child(even) .timeline-content.bg-warning:before, .timeline .post:nth-child(even) .timeline-content.border-warning:before {
  border: 15px solid transparent;
  border-right-color: #f89406;
}
/* line 152, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content.bg-success,
.timeline .post .timeline-content.border-success {
  border-color: #738d00;
}
/* line 156, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content.bg-success:before,
.timeline .post .timeline-content.border-success:before {
  border-left-color: #738d00;
}
/* line 160, ../sass/elements/_timeline.scss */
.timeline .post:nth-child(even) .timeline-content.bg-success:before, .timeline .post:nth-child(even) .timeline-content.border-success:before {
  border: 15px solid transparent;
  border-right-color: #738d00;
}
/* line 152, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content.bg-primary,
.timeline .post .timeline-content.border-primary {
  border-color: #037ac5;
}
/* line 156, ../sass/elements/_timeline.scss */
.timeline .post .timeline-content.bg-primary:before,
.timeline .post .timeline-content.border-primary:before {
  border-left-color: #037ac5;
}
/* line 160, ../sass/elements/_timeline.scss */
.timeline .post:nth-child(even) .timeline-content.bg-primary:before, .timeline .post:nth-child(even) .timeline-content.border-primary:before {
  border: 15px solid transparent;
  border-right-color: #037ac5;
}

/******************************************************************************
  Why Choose
*******************************************************************************/
/* line 5, ../sass/elements/_why-choose.scss */
.why-choose ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
/* line 10, ../sass/elements/_why-choose.scss */
.why-choose li {
  font-size: 13px;
  line-height: 20px;
  padding: 11px 0 8px 23px;
  position: relative;
}
/* line 16, ../sass/elements/_why-choose.scss */
.why-choose li:before {
  content: "?";
  display: block;
  font-size: 16px;
  left: 2px;
  position: absolute;
  top: 11px;
}
/* line 24, ../sass/elements/_why-choose.scss */
.why-choose li + li {
  border-top-width: 1px;
}

/******************************************************************************
  Word Rotate
*******************************************************************************/
/* line 4, ../sass/elements/_word-rotate.scss */
.word-rotate {
  background: #1e1e1e;
  display: inline-block;
  overflow: hidden;
  padding: 0 8px;
  text-align: center;
  vertical-align: top;
}
/* line 12, ../sass/elements/_word-rotate.scss */
.word-rotate:not(.border) {
  color: #fff;
}
/* line 15, ../sass/elements/_word-rotate.scss */
.word-rotate .words-box {
  display: inline-block;
  position: relative;
}
/* line 19, ../sass/elements/_word-rotate.scss */
.word-rotate .words-box span {
  display: block;
  white-space: nowrap;
}
/* line 23, ../sass/elements/_word-rotate.scss */
.word-rotate .words-box span + span {
  display: none;
}
/* line 29, ../sass/elements/_word-rotate.scss */
.word-rotate.loaded .words-box span + span {
  display: block;
}
/* line 33, ../sass/elements/_word-rotate.scss */
.word-rotate.border {
  background: none;
  border: 1px solid #1e1e1e;
}

/******************************************************************************
  Works
*******************************************************************************/
/* line 5, ../sass/elements/_works.scss */
.portfolio .filter-elements {
  overflow: visible !important;
}
/* line 8, ../sass/elements/_works.scss */
.portfolio .work {
  margin-bottom: 40px;
}

/* line 12, ../sass/elements/_works.scss */
.work {
  border-radius: 5px;
  color: #000;
  display: block;
  position: relative;
  text-align: center;
  /*text-shadow: 0 1px #000;*/
}
/* line 20, ../sass/elements/_works.scss */
/*.work:hover {
  display: block;
  text-decoration: none;
}*/
/* line 24, ../sass/elements/_works.scss */
.carousel .work {
  margin: 2px 1px;
}
/* line 27, ../sass/elements/_works.scss */
.work img {
  background-color: #555;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  text-align: center;
  width: 100%;
}
/* line 36, ../sass/elements/_works.scss */
.work .shadow {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(29%, rgba(255, 255, 255, 0.01)), color-stop(30%, rgba(0, 0, 0, 0.01)), color-stop(33%, rgba(0, 0, 0, 0.01)), color-stop(62%, rgba(0, 0, 0, 0.17)), color-stop(68%, rgba(0, 0, 0, 0.24)), color-stop(90%, rgba(0, 0, 0, 0.62)), color-stop(100%, rgba(0, 0, 0, 0.73)));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.01) 29%, rgba(0, 0, 0, 0.01) 30%, rgba(0, 0, 0, 0.01) 33%, rgba(0, 0, 0, 0.17) 62%, rgba(0, 0, 0, 0.24) 68%, rgba(0, 0, 0, 0.62) 90%, rgba(0, 0, 0, 0.73) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.01) 29%, rgba(0, 0, 0, 0.01) 30%, rgba(0, 0, 0, 0.01) 33%, rgba(0, 0, 0, 0.17) 62%, rgba(0, 0, 0, 0.24) 68%, rgba(0, 0, 0, 0.62) 90%, rgba(0, 0, 0, 0.73) 100%);
  border-radius: 5px;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
/* line 52, ../sass/elements/_works.scss */
.work .work-title {
  bottom: 37px;
  left: 0;
  margin: 0 0 -17px;
  padding: 0 5px;
  position: absolute;
  right: 0;
  text-align: center;
  -webkit-transition: bottom .2s ease-in;
  transition: bottom .2s ease-in;
}
/* line 63, ../sass/elements/_works.scss */
.work .title {
  bottom: 46px;
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  margin: 0 0 6px;
  text-align: center;
}
/* line 71, ../sass/elements/_works.scss */
.work .description {
  font-size: 11px;
  font-style: italic;
  font-family: Georgia;
  line-height: 16px;
  text-align: center;
}
/* line 78, ../sass/elements/_works.scss */
.work .bg-hover {
  background: #fff;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  bottom: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
}
/* line 93, ../sass/elements/_works.scss */
/*.work:hover {
  text-shadow: none;
}*/
/* line 96, ../sass/elements/_works.scss */
/*.work:hover .work-title {
  bottom: 46%;
  color: #1e1e1e;
  -webkit-transition: bottom .2s ease-in;
  transition: bottom .2s ease-in;
}*/
/* line 102, ../sass/elements/_works.scss */
/*.work:hover .bg-hover {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}*/

/* line 111, ../sass/elements/_works.scss */
.work-single .carousel-box {
  overflow: hidden;
}
/* line 114, ../sass/elements/_works.scss */
.work-single .carousel-box .carousel img {
  border-radius: 5px;
}
/* line 117, ../sass/elements/_works.scss */
.work-single .carousel-box.load {
  max-height: 270px;
}
/* line 121, ../sass/elements/_works.scss */
.work-single .title {
  font-size: 22px;
  font-weight: normal;
  margin: 0 0 5px;
}
/* line 126, ../sass/elements/_works.scss */
.work-single .type {
  color: #7f7f7f;
  font-size: 14px;
  margin: 0 0 22px;
}

/* Portfolio One */
/* line 133, ../sass/elements/_works.scss */
.work-one {
  color: #7f7f7f;
  max-width: 100%;
  overflow: hidden;
}
/* line 138, ../sass/elements/_works.scss */
.work-one + .work-one {
  margin-top: 40px;
}
/* line 141, ../sass/elements/_works.scss */
.work-one .title {
  font-size: 20px;
  font-weight: normal;
  line-height: 24px;
  margin: 0 0 7px;
}
/* line 147, ../sass/elements/_works.scss */
.work-one .title a {
  color: #1e1e1e;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 152, ../sass/elements/_works.scss */
.work-one .title a:hover {
  opacity: .8;
  filter: alpha(opacity=80);
  text-decoration: none;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
/* line 161, ../sass/elements/_works.scss */
.work-one .description {
  font-size: 11px;
  line-height: 14px;
  margin: 0 0 27px;
}
/* line 166, ../sass/elements/_works.scss */
.work-one .work-image {
  border-radius: 5px;
  display: block;
  line-height: 0;
  overflow: hidden;
}
/* line 172, ../sass/elements/_works.scss */
.work-one .work-image img {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}
/* line 176, ../sass/elements/_works.scss */
.work-one .work-image img:hover {
  -webkit-filter: grayscale(100%);
}

/******************************************************************************
  Max Width "1199px"
*******************************************************************************/
@media (max-width: 1199px) {
  /* line 9, ../sass/_responsive.scss */
  .phone-active,
  .search-active {
    left: 0;
    width: auto;
  }

  /* line 14, ../sass/_responsive.scss */
  .toolbar .per-page {
    display: none;
  }

  /* line 17, ../sass/_responsive.scss */
  .content .slider .slid-content {
    bottom: 43px;
  }

  /* line 20, ../sass/_responsive.scss */
  .content .slider .pagination {
    padding: 0;
  }

  /* line 23, ../sass/_responsive.scss */
  .content .slider .slider-nav .nav-box {
    bottom: 10px;
  }

  /* line 26, ../sass/_responsive.scss */
  .content .slider .slider-nav .prev,
  .content .slider .slider-nav .next {
    display: none !important;
  }

  /* line 30, ../sass/_responsive.scss */
  #car-bottom .total .checkout {
    font-size: 12px;
  }

  /* line 34, ../sass/_responsive.scss */
  .pricing .title a {
    font-size: 21px;
  }

  /* line 38, ../sass/_responsive.scss */
  .employee .employee-hover .role {
    margin: 0 0 15px;
  }

  /* line 42, ../sass/_responsive.scss */
  .gallery-images:hover .bg-images i:before {
    font-size: 40px;
  }

  /* line 45, ../sass/_responsive.scss */
  .sidebar .whats-news .product {
    max-width: 270px;
    min-width: inherit;
  }

  /* line 49, ../sass/_responsive.scss */
  .sidebar .whats-news .rotation.hover .default {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
  }

  /* line 53, ../sass/_responsive.scss */
  .sidebar .whats-news .rotation .product-hover {
    display: none;
  }

  /* line 56, ../sass/_responsive.scss */
  .rs-slider.full-width .tp-bullets {
    left: 27px !important;
    margin-left: 0 !important;
  }
}
/******************************************************************************
  Max Width "991px"
*******************************************************************************/
@media (max-width: 991px) {
  /* line 66, ../sass/_responsive.scss */
  .fixed-top-box {
    padding-top: 0;
  }

  /* line 69, ../sass/_responsive.scss */
  .fixed-top-box #top-box {
    position: static;
    top: inherit;
    z-index: inherit;
  }

  /* line 74, ../sass/_responsive.scss */
  .fixed-header .header,
  .boxed.fixed-header .header,
  .boxed.fixed-header.fixed .header,
  .fixed-header.hidden-top.visible-top .header {
    box-shadow: none;
    position: static;
    top: inherit;
    z-index: inherit;
    -webkit-backface-visibility: inherit;
    -webkit-transform: inherit;
  }

  /* line 87, ../sass/_responsive.scss */
  .fixed-header .header-two + * {
    margin-top: 0;
  }

  /* line 90, ../sass/_responsive.scss */
  .hidden-top,
  .hidden-top .page-box,
  .hidden-top.visible-top .page-box {
    margin-top: 0;
    padding-top: 0;
  }

  /* line 96, ../sass/_responsive.scss */
  .hidden-top {
    -webkit-transition: all 0 linear;
    transition: all 0 linear;
  }

  /* line 100, ../sass/_responsive.scss */
  .hidden-top #top-box,
  .boxed.hidden-top #top-box,
  .boxed.fixed-header.hidden-top #top-box {
    margin-top: 0;
    margin-bottom: 0;
    position: static;
  }

  /* line 107, ../sass/_responsive.scss */
  .hidden-top .header,
  .fixed-header.hidden-top .header {
    margin-top: 0;
    position: static;
  }

  /* line 112, ../sass/_responsive.scss */
  .fixed-header.hidden-top.visible-top .header,
  .fixed-header.hidden-top.fixed #top-box,
  .fixed-header.hidden-top.visible-top.fixed #top-box {
    margin-top: 0;
  }

  /* line 117, ../sass/_responsive.scss */
  .top-navbar {
    margin: 0;
    min-height: 30px;
  }

  /* line 121, ../sass/_responsive.scss */
  .top-navbar .btn-navbar {
    background: none !important;
    border-color: rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    color: #fff;
    color: rgba(255, 255, 255, 0.7);
    display: block;
    font-size: 16px;
    margin: 0;
    padding: 7px;
  }

  /* line 134, ../sass/_responsive.scss */
  .top-navbar .btn-navbar.collapsed {
    border-color: transparent;
  }

  /* line 137, ../sass/_responsive.scss */
  .navbar-inverse .navbar-toggle .icon-bar {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 0;
  }

  /* line 143, ../sass/_responsive.scss */
  .top-navbar .navbar-collapse.collapse {
    display: none !important;
  }

  /* line 146, ../sass/_responsive.scss */
  .top-navbar .navbar-collapse.collapse.in {
    display: block !important;
  }

  /* line 149, ../sass/_responsive.scss */
  .top-navbar .collapse,
  .top-navbar .collapsing {
    background: #fff;
    border: 0 none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    left: 0;
    margin: 5px 0 0;
    padding: 0;
    position: absolute !important;
    top: 100%;
    z-index: 1002;
  }

  /* line 163, ../sass/_responsive.scss */
  .top-navbar .navbar-nav.navbar-right:last-child,
  .top-navbar .navbar-nav {
    margin: 0;
  }

  /* line 167, ../sass/_responsive.scss */
  .top-navbar .nav > li {
    float: none;
  }

  /* line 170, ../sass/_responsive.scss */
  #top-box .top-navbar .nav > li a {
    color: #7b7b7b;
  }

  /* line 173, ../sass/_responsive.scss */
  .top-navbar .nav > li:hover a,
  .top-navbar .nav > li a:hover {
    background: none;
  }

  /* line 177, ../sass/_responsive.scss */
  .top-navbar.top-navbar-right .navbar-right {
    float: none !important;
  }

  /* line 180, ../sass/_responsive.scss */
  .top-navbar.top-navbar-right .collapse,
  .top-navbar.top-navbar-right .collapsing {
    left: auto;
    right: 0;
  }

  /* line 185, ../sass/_responsive.scss */
  .top-navbar .nav > li a .count {
    background: rgba(0, 0, 0, 0.1);
  }

  /* line 188, ../sass/_responsive.scss */
  .header {
    width: auto;
  }

  /* line 191, ../sass/_responsive.scss */
  .header .right-box-wrapper {
    text-align: right;
  }

  /* line 194, ../sass/_responsive.scss */
  .header .header-wrapper .cart-header {
    margin-right: 0;
  }

  /* line 197, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar {
    margin: 0;
    min-height: inherit;
    position: static;
  }

  /* line 202, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .btn-navbar {
    background: #fff;
    border: 1px solid #ccc;
    border-bottom: 0 none;
    border-radius: 3px 3px 0 0;
    box-shadow: none;
    display: block;
    float: left;
    height: 46px;
    line-height: 15px;
    margin: 0;
    padding: 10px 15px;
    position: relative;
    text-shadow: none;
    z-index: 920;
  }

  /* line 222, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .btn-navbar:before {
    border-left: 1px solid #ccc;
    border-left: 1px solid rgba(204, 204, 204, 0.8);
    bottom: 8px;
    content: "";
    display: none;
    left: -1px;
    position: absolute;
    top: 7px;
  }

  /* line 232, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .btn-navbar.collapsed:before {
    display: block;
  }

  /* line 235, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .btn-navbar.collapsed {
    background: none;
    border: 1px solid transparent;
    border-bottom: 0 none;
  }

  /* line 240, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .btn-navbar .text {
    color: #1e1e1e;
    float: right;
    line-height: 16px;
    padding: 0 0 0 30px;
  }

  /* line 246, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .btn-navbar .icon-bar {
    background: #1e1e1e;
    box-shadow: none;
    border-radius: 0;
    height: 3px;
    margin: 0;
    width: 22px;
  }

  /* line 254, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .btn-navbar .icon-bar + .icon-bar {
    margin-top: 3px;
  }

  /* line 257, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse {
    border: 0 none;
    box-shadow: none;
    left: auto;
    margin: 22px 0 0;
    max-height: inherit;
    overflow: hidden !important;
    padding: 0 15px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 200%;
    z-index: 919;
  }

  /* line 271, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px 0 3px 3px;
    float: none;
    padding: 6px 6px 3px;
  }

  /* line 280, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li {
    float: none;
    margin: 0 0 3px;
    padding: 0;
  }

  /* line 285, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li > a {
    background: #f2f2f2;
    border: 1px solid #f2f2f2;
    border-radius: 0;
    font-weight: normal;
    margin: 0;
    padding: 7px 9px 7px 59px;
    text-decoration: none;
  }

  /* line 296, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li > a:hover,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li:hover > a {
    background: #e9e9e9;
    border: 1px solid #e9e9e9;
    border-radius: 0;
    box-shadow: none;
  }

  /* line 307, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > .parent > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.parent:hover > a {
    background: #f2f2f2;
    position: relative;
  }

  /* line 312, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.parent .parent > a:after {
    display: none;
  }

  /* line 315, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .open-sub {
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 55px;
  }

  /* line 322, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .open-sub span {
    background: #1e1e1e;
    display: block;
    height: 2px;
    margin: 17px 0 0 15px;
    position: absolute;
    width: 16px;
  }

  /* line 330, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .open-sub span + span {
    height: 16px;
    margin: 10px 0 0 22px;
    width: 2px;
  }

  /* line 335, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .parent .parent > a .open-sub span,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu .title .open-sub span {
    background: #7f7f7f;
    height: 2px;
    margin: 14px 0 0 38px;
    position: absolute;
    width: 12px;
  }

  /* line 343, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .parent .parent > a .open-sub span + span,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu .title .open-sub span + span {
    height: 12px;
    margin: 9px 0 0 43px;
    width: 2px;
  }

  /* line 349, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.parent:hover > a:after {
    display: none;
  }

  /* line 352, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .sub,
  .header .header-wrapper .primary .navbar .navbar-collapse .parent .sub,
  .header .header-wrapper .primary .navbar .navbar-collapse .parent:hover > .sub {
    background: #f7f7f7;
    border: 0 none;
    border-radius: 0;
    box-shadow: none;
    display: none;
    font-size: 13px;
    left: 0;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 0;
    padding: 6px 0 5px;
    position: relative;
  }

  /* line 372, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.parent:hover > a {
    background-color: #e9e9e9;
    border-radius: 0;
  }

  /* line 378, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > .parent.active > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > .parent.active:hover > a {
    background: #1e1e1e;
    border-color: #1e1e1e;
    color: #fff;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }

  /* line 386, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > .parent.active > a .open-sub span {
    background: #fff;
  }

  /* line 389, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .parent.active > a .open-sub span + span,
  .header .header-wrapper .primary .megamenu .box.active .title .open-sub span + span {
    display: none;
  }

  /* line 393, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .parent:hover > .sub {
    -webkit-animation: fadeIn 0;
    animation: fadeIn 0;
    display: none;
  }

  /* line 398, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .sub li {
    line-height: 22px;
  }

  /* line 401, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .sub li a {
    background: none;
    color: #7f7f7f;
    padding: 4px 9px 4px 60px;
    text-decoration: none;
    width: auto !important;
  }

  /* line 408, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav li a > i {
    color: #1e1e1e;
  }

  /* line 411, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav li:hover > .sub a {
    background: none;
  }

  /* line 414, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .sub .sub {
    left: 0;
    margin-left: 10px;
  }

  /* line 418, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub {
    left: 0;
    padding: 6px 0 5px;
    width: 100%;
  }

  /* line 423, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .sub-wrapper {
    display: block;
  }

  /* line 426, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .promo-block {
    display: none;
  }

  /* line 429, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .sub-list {
    display: block;
    padding: 0;
    width: 100% !important;
  }

  /* line 434, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .box {
    float: none;
    margin: 0;
    padding: 0;
    width: 100% !important;
  }

  /* line 440, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu .title {
    color: #7f7f7f;
    cursor: pointer;
    font-weight: normal;
    line-height: 22px;
    margin: 0;
    padding: 4px 9px 4px 60px;
    position: relative;
    text-transform: none;
  }

  /* line 450, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu > .sub .box.closed ul {
    display: none;
    margin-left: 10px;
  }

  /* line 454, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav a .item-new,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu a .item-new,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav .megamenu .sub a .item-new {
    left: 0;
    float: right;
    position: static;
    top: 0;
  }

  /* line 462, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav a .item-new .before,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav a .item-new:before {
    display: none;
  }

  /* line 466, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-bg > a .open-sub span,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-inverse > a .open-sub span {
    background: #fff;
  }

  /* line 470, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-bg > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-inverse > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-inverse:hover > a {
    color: #fff;
  }

  /* line 476, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-primary > a {
    border-color: #428bca;
  }

  /* line 479, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-success > a {
    border-color: #738d00;
  }

  /* line 482, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-info > a {
    border-color: #0098ca;
  }

  /* line 485, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-warning > a {
    border-color: #f89406;
  }

  /* line 488, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-danger > a {
    border-color: #c10841;
  }

  /* line 491, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-primary.item-bg > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-primary.item-bg:hover > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-primary.item-inverse > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-primary.item-inverse:hover > a {
    background-color: #428bca;
    border-color: #428bca;
  }

  /* line 498, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-success.item-bg > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-success.item-bg:hover > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-success.item-inverse > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-success.item-inverse:hover > a {
    background-color: #738d00;
    border-color: #738d00;
  }

  /* line 505, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-info.item-bg > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-info.item-bg:hover > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-info.item-inverse > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-info.item-inverse:hover > a {
    background-color: #0098ca;
    border-color: #0098ca;
  }

  /* line 512, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-warning.item-bg > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-warning.item-bg:hover > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-warning.item-inverse > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-warning.item-inverse:hover > a {
    background-color: #f89406;
    border-color: #f89406;
  }

  /* line 519, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-danger.item-bg > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-danger.item-bg:hover > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-danger.item-inverse > a,
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-danger.item-inverse:hover > a {
    background-color: #c10841;
    border-color: #c10841;
  }

  /* line 526, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-bg .sub {
    background: #f7f7f7 !important;
  }

  /* line 529, ../sass/_responsive.scss */
  .header .header-wrapper .primary .navbar .navbar-collapse .nav > li.item-bg .sub a {
    color: #7f7f7f;
  }

  /* line 532, ../sass/_responsive.scss */
  .header .header-wrapper + .header-wrapper .col-sm-6.col-md-9 {
    text-align: left;
  }

  /* line 535, ../sass/_responsive.scss */
  .header .header-wrapper + .header-wrapper .primary {
    margin: 0 0 0 -1px;
  }

  /* line 538, ../sass/_responsive.scss */
  .header .header-wrapper + .header-wrapper .primary .navbar .btn-navbar:before {
    border: 0 none;
  }

  /* line 541, ../sass/_responsive.scss */
  .header .header-wrapper + .header-wrapper .primary .navbar .navbar-collapse {
    left: -1px;
    width: calc(200% + px);
  }

  /* line 546, ../sass/_responsive.scss */
  .header .header-wrapper + .header-wrapper .primary .navbar .nav {
    border-radius: 0 3px 3px 3px;
  }

  /* line 551, ../sass/_responsive.scss */
  .header .cart-header .dropdown-menu {
    margin-top: -1px;
    margin-right: -98px;
  }

  /* line 555, ../sass/_responsive.scss */
  .header .header-wrapper + .header-wrapper .cart-header .dropdown-menu {
    margin-right: 0;
  }

  /* line 558, ../sass/_responsive.scss */
  .header .cart-header.open .dropdown-toggle:after {
    left: 0;
  }

  /* line 561, ../sass/_responsive.scss */
  .header.header-two .phone-header {
    margin-right: 0;
  }

  /* line 564, ../sass/_responsive.scss */
  .header.header-two .phone-active,
  .header.header-two .search-active {
    z-index: 1003;
  }

  /* line 568, ../sass/_responsive.scss */
  .header.header-three .logo-box {
    width: 724px;
  }

  /* line 571, ../sass/_responsive.scss */
  .top-fixed-box {
    display: none;
  }

  /* line 574, ../sass/_responsive.scss */
  .fixed-header.fixed-top #top-box,
  .boxed.fixed-header.fixed-top #top-box,
  .boxed.fixed-header.fixed-top.fixed #top-box {
    position: static;
  }

  /* line 580, ../sass/_responsive.scss */
  /*.slider {
    padding: 10px 0;
  }*/

  /* line 583, ../sass/_responsive.scss */
  .slider .slid-content,
  .slider .slider-nav {
    width: 370px;
  }

  /* line 587, ../sass/_responsive.scss */
  .slider .slid-content .title {
    margin: 0 0 14px;
  }

  /* line 590, ../sass/_responsive.scss */
  .slider .slid-content .descriptions {
    margin: 0 0 17px 0;
  }

  /* line 593, ../sass/_responsive.scss */
  .slider .slider-nav {
    right: 15px;
  }

  /* line 596, ../sass/_responsive.scss */
  .slider .slider-nav .nav-box {
    bottom: 0;
  }

  /* line 599, ../sass/_responsive.scss */
  .slider .slider-nav .prev,
  .slider .slider-nav .next {
    display: none !important;
  }

  /* line 603, ../sass/_responsive.scss */
  .slider .slid-content {
    bottom: 60px;
  }

  /* line 606, ../sass/_responsive.scss */
  .progressive-slider-two .slider-wrapper {
    height: auto !important;
  }

  /* line 609, ../sass/_responsive.scss */
  .progressive-slider-two .sliders-container {
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  /* line 614, ../sass/_responsive.scss */
  .progressive-slider-two .slider-banners {
    height: auto;
    width: 100%;
  }

  /* line 618, ../sass/_responsive.scss */
  .progressive-slider-two .slider-banners .banner {
    display: table-cell;
    height: auto;
    width: 33.33333333%;
  }

  /* line 623, ../sass/_responsive.scss */
  .page-slider {
    padding: 0;
  }

  /* line 626, ../sass/_responsive.scss */
  .metro-banners {
    padding: 30px 0 11px;
  }

  /* line 629, ../sass/_responsive.scss */
  .metro-banners .banner {
    margin-bottom: 19px;
  }

  /* line 633, ../sass/_responsive.scss */
  .sidebar.col-sm-12 {
    margin-top: 40px;
  }

  /* line 636, ../sass/_responsive.scss */
  .sidebar .whats-news .carousel-pagination {
    margin: 0 auto;
    max-width: 300px;
  }

  /* line 640, ../sass/_responsive.scss */
  .toolbar .grid-list .grid,
  .toolbar .grid-list .list {
    margin-right: 6px;
  }

  /* line 644, ../sass/_responsive.scss */
  .toolbar .sort-catalog {
    margin: 0 6px 20px 1px;
  }

  /* line 647, ../sass/_responsive.scss */
  .products.grid {
    margin-left: -10px;
    margin-right: -10px;
    text-align: center;
  }

  /* line 652, ../sass/_responsive.scss */
  .table-box {
    overflow-x: auto;
    padding: 0 1px;
  }

  /* line 656, ../sass/_responsive.scss */
  .portfolio .filter-buttons {
    float: none;
    margin-bottom: 20px;
  }

  /* line 660, ../sass/_responsive.scss */
  .portfolio .year-regulator {
    float: none;
    margin-bottom: 60px;
  }

  /* line 664, ../sass/_responsive.scss */
  .product-page .product-options .buy-for,
  .product-page .product-options .col-sm-12 {
    float: none;
    margin-bottom: 20px;
    width: 100%;
  }

  /* line 670, ../sass/_responsive.scss */
  .product-options-samples .col-sm-12 {
    margin-bottom: 20px;
  }

  /* line 673, ../sass/_responsive.scss */
  .product-options-samples .required-box {
    margin-bottom: 0;
  }

  /* line 676, ../sass/_responsive.scss */
  .product-bottom .col-sm-12 + .col-sm-12 {
    margin-top: 40px;
  }

  /* line 679, ../sass/_responsive.scss */
  .shopping-cart-table tbody td,
  .shopping-cart-table .td-qty {
    padding: 20px;
  }

  /* line 683, ../sass/_responsive.scss */
  #car-bottom .car-bottom-box {
    padding: 17px 20px 20px;
  }

  /* line 686, ../sass/_responsive.scss */
  .employee-single {
    margin-left: 0;
  }

  /* line 689, ../sass/_responsive.scss */
  .employee-single .images-box {
    float: none;
    margin: 0 auto 20px;
  }

  /* line 693, ../sass/_responsive.scss */
  .employee-single .employee-description {
    margin-left: 0;
    width: 100%;
  }

  /* line 697, ../sass/_responsive.scss */
  .employee-single-rotation .content-box {
    float: none;
    width: auto;
  }

  /* line 701, ../sass/_responsive.scss */
  .employee-single-rotation .employee {
    margin: 0 20px 10px;
  }

  /* line 704, ../sass/_responsive.scss */
  .employee-single-rotation .employee.pull-right {
    margin-right: 0;
  }

  /* line 707, ../sass/_responsive.scss */
  /*.royalSlider.heroSlider,
  .royalSlider.heroSlider .rsOverflow,
  img.rsImg {
    min-height: 220px !important;
  }*/

  /* line 712, ../sass/_responsive.scss */
  .infoBlock {
    padding: 10px;
    height: auto;
    max-height: 100%;
    min-width: 40%;
    left: 5px;
    top: 5px;
    right: auto;
    font-size: 12px;
  }

  /* line 722, ../sass/_responsive.scss */
  .infoBlock h3,
  .infoBlock h4 {
    font-size: 14px;
    line-height: 17px;
  }

  /* line 727, ../sass/_responsive.scss */
  .layer-slider .container {
    padding-left: 0;
    padding-right: 0;
  }

  /* line 731, ../sass/_responsive.scss */
  .count-down-box {
    float: none;
    margin-bottom: 30px;
  }

  /* line 735, ../sass/_responsive.scss */
  #footer .newsletter form {
    width: 270px;
  }

  /* line 738, ../sass/_responsive.scss */
  .post.carousel .images-box + [class*="span"] {
    float: none;
    margin-left: 0;
    width: auto;
  }

  /* line 743, ../sass/_responsive.scss */
  #footer .sidebar .widget:nth-child(2n+1) {
    clear: left;
  }

  /* line 746, ../sass/_responsive.scss */
  #footer .sidebar .carousel-box header .title {
    margin-bottom: 0;
  }

  /* line 749, ../sass/_responsive.scss */
  #footer .sidebar .carousel-box header .next,
  #footer .sidebar .carousel-box header .prev {
    bottom: inherit;
    left: 0;
    margin: 0 0 0 10px;
    position: static;
  }
}
/******************************************************************************
  Max Width "991px" - Min Width "768px"
*******************************************************************************/
@media (min-width: 768px) and (max-width: 991px) {
  /* line 762, ../sass/_responsive.scss */
  .top-navbar .nav > li a {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* line 766, ../sass/_responsive.scss */
  .primary .navbar-collapse.collapse {
    display: none !important;
  }

  /* line 769, ../sass/_responsive.scss */
  .primary .navbar-collapse.collapse.in {
    display: block !important;
  }

  /* line 772, ../sass/_responsive.scss */
  .toolbar .price-regulator {
    width: 245px;
  }

  /* line 775, ../sass/_responsive.scss */
  .toolbar .price-regulator .layout-slider {
    width: 200px;
  }

  /* line 778, ../sass/_responsive.scss */
  .product-page .add-cart-form .add-cart {
    min-width: 200px;
  }

  /* line 781, ../sass/_responsive.scss */
  .product-page .image-box .thumblist-box {
    padding: 0 45px;
  }

  /* line 784, ../sass/_responsive.scss */
  .product-page .image-box #thumblist a {
    height: 70px;
    width: 70px;
  }

  /* line 788, ../sass/_responsive.scss */
  #car-bottom .total .checkout {
    padding: 9px 10px;
  }

  /* line 791, ../sass/_responsive.scss */
  .contact-info address {
    float: none;
    width: 100%;
  }

  /* line 795, ../sass/_responsive.scss */
  .package .title a {
    font-size: 20px;
  }

  /* line 798, ../sass/_responsive.scss */
  .package .price-box .icon {
    display: none;
  }

  /* line 801, ../sass/_responsive.scss */
  .package .price-box .description {
    margin: 0 0 17px 0;
  }

  /* line 804, ../sass/_responsive.scss */
  .products-tab .tab-content {
    overflow: visible;
  }

  /* line 807, ../sass/_responsive.scss */
  .col-sm-5.respond-carousel .carousel .respond {
    max-width: 312px;
  }

  /* line 810, ../sass/_responsive.scss */
  #footer .footer-top .col-md-3 {
    margin-bottom: 40px;
  }
}
/******************************************************************************
  Min Width "768px"
*******************************************************************************/
@media (min-width: 768px) {
  /* line 819, ../sass/_responsive.scss */
  .filter-list.filter-buttons .dropdown-toggle {
    display: none;
  }

  /* line 822, ../sass/_responsive.scss */
  .filter-list.filter-buttons .dropdown-menu {
    background: none;
    border: 0 none;
    border-radius: 0;
    box-shadow: none;
    display: block !important;
    float: none;
    left: inherit;
    margin: 0;
    min-width: inherit;
    padding: 0;
    position: relative;
    top: inherit;
    z-index: inherit;
  }

  /* line 838, ../sass/_responsive.scss */
  .filter-list.filter-buttons .dropdown-menu > li {
    float: left;
    margin: 0 20px 10px 0;
  }

  /* line 842, ../sass/_responsive.scss */
  .filter-list.filter-buttons .dropdown-menu > li > a {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
    clear: none;
    color: #1e1e1e;
    height: 38px;
    line-height: 38px;
    margin: 0;
    padding: 0 20px;
    -webkit-transition: opacity .2s ease-out;
    transition: opacity .2s ease-out;
  }

  /* line 855, ../sass/_responsive.scss */
  .filter-list.filter-buttons .dropdown-menu > li > a:hover {
    background: transparent;
    text-decoration: none;
    opacity: .7;
    filter: alpha(opacity=70);
    -webkit-transition: opacity .2s ease-out;
    transition: opacity .2s ease-out;
  }

  /* line 863, ../sass/_responsive.scss */
  .filter-list.filter-buttons .dropdown-menu > li > a.active {
    border: 1px solid #e1e1e1;
    font-weight: bold;
    opacity: 1;
    filter: alpha(opacity=100);
    text-decoration: none;
  }

  /* line 870, ../sass/_responsive.scss */
  .filter-list.filter-buttons.white .dropdown-menu > li > a {
    color: #fff;
  }
}
/******************************************************************************
  Max Width "767px"
*******************************************************************************/
@media (max-width: 767px) {
  /* line 879, ../sass/_responsive.scss */
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* line 883, ../sass/_responsive.scss */
  body,
  body.loaded {
    min-width: 320px;
    padding-left: 0;
    padding-right: 0;
    overflow-x: hidden;
  }

  /* line 890, ../sass/_responsive.scss */
  .page-box,
  .boxed.loaded .page-box {
    height: auto !important;
    min-height: inherit;
    overflow-x: hidden;
  }

  /* line 896, ../sass/_responsive.scss */
  .page-box-content {
    padding-bottom: 0;
  }

  /* line 899, ../sass/_responsive.scss */
  #top-box .btn-group.btn-select .visible-xs {
    display: inline-block !important;
  }

  /* line 902, ../sass/_responsive.scss */
  #top-box .btn-group.btn-select .dropdown-menu {
    box-sizing: border-box;
    border-radius: 0 0 3px 3px;
    min-width: inherit;
    width: 100%;
  }

  /* line 912, ../sass/_responsive.scss */
  .header-three {
    overflow: hidden;
  }

  /* line 915, ../sass/_responsive.scss */
  .header .right-box {
    padding-left: 0;
  }

  /* line 918, ../sass/_responsive.scss */
  .header .header-wrapper + .header-wrapper .right-box {
    padding-left: 15px;
  }

  /* line 921, ../sass/_responsive.scss */
  .header .header-wrapper + .header-wrapper {
    min-height: 40px;
  }

  /* line 924, ../sass/_responsive.scss */
  .header .header-wrapper + .header-wrapper .right-box-wrapper {
    height: 40px;
  }

  /* line 927, ../sass/_responsive.scss */
  .phone-active,
  .search-active {
    left: 10px;
    padding: 0;
    right: 10px;
  }

  /* line 933, ../sass/_responsive.scss */
  .phone-active {
    font-size: 22px;
    line-height: 58px;
  }

  /* line 937, ../sass/_responsive.scss */
  .phone-active .title {
    display: none;
  }

  /* line 940, ../sass/_responsive.scss */
  .search-active .search-string, .search-active .header .search-form .search-string:hover, .header .search-form .search-active .search-string:hover,
  .search-active .search-string:hover,
  .search-active .header .search-form .search-string:hover,
  .header .search-form .search-active .search-string:hover {
    padding-left: 8px;
  }

  /* line 944, ../sass/_responsive.scss */
  .header .cart-header .dropdown-toggle,
  .header .header-wrapper .primary .navbar .btn-navbar {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* line 949, ../sass/_responsive.scss */
  .header .cart-header .dropdown-menu {
    width: 325px;
  }

  /* line 952, ../sass/_responsive.scss */
  .header.header-three .logo-box {
    width: 100%;
  }

  /* line 955, ../sass/_responsive.scss */
  .breadcrumb {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* line 959, ../sass/_responsive.scss */
  .progressive-slider .container {
    padding-left: 0;
    padding-right: 0;
  }

  /* line 963, ../sass/_responsive.scss */
  .slider .slid {
    margin-left: 0;
    margin-right: 0;
  }

  /* line 967, ../sass/_responsive.scss */
  .slider .slider-nav {
    right: 0;
    width: 50%;
  }

  /* line 971, ../sass/_responsive.scss */
  .slider .slid .col-sm-12.col-md-12 {
    padding-left: 0;
    padding-right: 0;
  }

  /* line 975, ../sass/_responsive.scss */
  .slider .slid-content {
    bottom: 30px;
    padding: 13px 10px 0;
    right: 0;
    width: 50%;
  }

  /* line 981, ../sass/_responsive.scss */
  .slider .slid-content .title {
    font-size: 12px;
    line-height: 14px;
  }

  /* line 985, ../sass/_responsive.scss */
  .slider .slid-content .descriptions {
    display: none;
  }

  /* line 988, ../sass/_responsive.scss */
  .slider .slid-content .btn {
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 7px;
  }

  /* line 993, ../sass/_responsive.scss */
  .slider .slider-nav .nav-box {
    bottom: 10px;
    left: 10px;
    right: 10px;
  }

  /* line 998, ../sass/_responsive.scss */
  .slider .slider-nav .pagination {
    margin: 0;
    padding: 0;
  }

  /* line 1002, ../sass/_responsive.scss */
  .page-slider {
    margin-left: 0;
    margin-right: 0;
  }

  /* line 1006, ../sass/_responsive.scss */
  .progressive-slider-two .slider-banners .banner {
    display: block;
    width: 100%;
  }

  /* line 1010, ../sass/_responsive.scss */
  .rs-slider.full-width .tp-bullets {
    left: 7px !important;
    margin-left: 0 !important;
    top: 10px !important;
  }

  /* line 1015, ../sass/_responsive.scss */
  .slider.rs-slider.full-width .btn-link {
    font-size: 12px !important;
  }

  /* line 1018, ../sass/_responsive.scss */
  .progressive-slider-four .prev,
  .progressive-slider-four .next {
    font-size: 30px;
    left: 10px;
    margin-top: -15px;
  }

  /* line 1024, ../sass/_responsive.scss */
  .progressive-slider-four .next {
    left: auto;
    right: 10px;
  }

  /* line 1029, ../sass/_responsive.scss */
  .page-header .container:before {
    left: 10px;
  }

  /* line 1032, ../sass/_responsive.scss */
  #main {
    padding: 40px 0;
  }

  /* line 1035, ../sass/_responsive.scss */
  #main.no-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  /* line 1039, ../sass/_responsive.scss */
  .work-element.col-md-3 .work {
    margin-left: auto;
    margin-right: auto;
    max-width: 270px;
  }

  /* line 1044, ../sass/_responsive.scss */
  .work-element.col-md-4 .work {
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
  }

  /* line 1049, ../sass/_responsive.scss */
  .work-one .work-title {
    margin-bottom: 20px;
  }

  /* line 1052, ../sass/_responsive.scss */
  .work-single .carousel-box .carousel .col-md-3 {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
  }

  /* line 1057, ../sass/_responsive.scss */
  .work-single .carousel-box .carousel .col-md-4 {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    width: 280px;
  }

  /* line 1063, ../sass/_responsive.scss */
  .content.pull-right {
    float: none !important;
  }

  /* line 1066, ../sass/_responsive.scss */
  .sidebar {
    margin-top: 40px;
  }

  /* line 1069, ../sass/_responsive.scss */
  .sidebar .widget {
    margin: 0 0 40px;
  }

  /* line 1072, ../sass/_responsive.scss */
  .sidebar .carousel > .col-md-3 {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
  }

  /* line 1077, ../sass/_responsive.scss */
  #catalog .category-img {
    margin-bottom: 40px;
  }

  /* line 1080, ../sass/_responsive.scss */
  #catalog .category-img .description {
    font-size: 18px;
    padding: 20px;
  }

  /* line 1084, ../sass/_responsive.scss */
  .toolbar .price-regulator {
    float: left !important;
    margin-top: 3px;
    width: 300px;
  }

  /* line 1089, ../sass/_responsive.scss */
  .toolbar .price-regulator .layout-slider {
    width: 240px;
  }

  /* line 1092, ../sass/_responsive.scss */
  .pagination-box {
    margin-top: 0;
    text-align: center;
  }

  /* line 1096, ../sass/_responsive.scss */
  .pagination-box .pagination {
    margin: 0 -5px 0 0;
  }

  /* line 1099, ../sass/_responsive.scss */
  .pagination-box .pagination-text {
    display: block;
    float: none;
  }

  /* line 1103, ../sass/_responsive.scss */
  .products {
    text-align: center;
  }

  /* line 1106, ../sass/_responsive.scss */
  .list .product .col-md-3,
  .list .product .col-md-4 {
    margin-bottom: 20px;
  }

  /* line 1110, ../sass/_responsive.scss */
  .list .product .product-image {
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
  }

  /* line 1115, ../sass/_responsive.scss */
  .caroufredsel_wrapper {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  /* line 1119, ../sass/_responsive.scss */
  .recommended-product .products > .col-md-3 .product {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }

  /* line 1124, ../sass/_responsive.scss */
  .products-tab .tab-content > .tab-pane {
    height: 263px;
    text-align: center;
  }

  /* line 1128, ../sass/_responsive.scss */
  .bottom-padding,
  .bottom-padding-mobile,
  .bottom-padding.row.carousel-box {
    margin-bottom: 40px;
  }

  /* line 1133, ../sass/_responsive.scss */
  .bottom-padding-mini {
    margin-bottom: 0;
  }

  /* line 1136, ../sass/_responsive.scss */
  .bottom-padding.bottom-padding-mini {
    margin-bottom: 40px;
  }

  /* line 1139, ../sass/_responsive.scss */
  .text-center-mobile {
    text-align: center;
  }

  /* line 1142, ../sass/_responsive.scss */
  .accordion-tab,
  .tabs-left .accordion-tab,
  .tabs-right .accordion-tab {
    border: 0 none;
    float: none;
    width: 100%;
  }

  /* line 1149, ../sass/_responsive.scss */
  .accordion-tab > li,
  .tabs-left > .accordion-tab > li,
  .tabs-right > .accordion-tab > li {
    float: none;
    margin: 0 0 3px;
  }

  /* line 1155, ../sass/_responsive.scss */
  .accordion-tab > li > a,
  .products-tab .accordion-tab > li > a,
  .tabs-left > .accordion-tab > li > a,
  .tabs-right > .accordion-tab > li > a {
    background-position: 14px 50%;
    background-repeat: no-repeat;
    background-color: #f2f2f2;
    border: 0 none;
    border-radius: 0;
    color: #1e1e1e;
    display: block;
    font-size: 14px;
    line-height: 22px;
    margin: 0;
    padding: 9px 9px 9px 60px;
    position: relative;
    text-decoration: none;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
  }

  /* line 1175, ../sass/_responsive.scss */
  .accordion-tab > li > a:hover,
  .products-tab .accordion-tab > li > a:hover {
    background-color: #e9e9e9;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
  }

  /* line 1181, ../sass/_responsive.scss */
  .accordion-tab > li.active > a,
  .accordion-tab > li.active > a:hover,
  .accordion-tab > li.active > a:focus,
  .accordion-tab > li.active:hover > a,
  .products-tab .accordion-tab > li.active > a,
  .products-tab .accordion-tab > li.active:hover > a {
    background-color: #1e1e1e;
    border: 0 none;
    border-radius: 0;
    color: #fff !important;
  }

  /* line 1192, ../sass/_responsive.scss */
  .accordion-tab > li > a:before,
  .products-tab .accordion-tab > li > a:before {
    background: #e1e1e1;
    background: rgba(255, 255, 255, 0.4);
    bottom: 0;
    content: "";
    display: block;
    left: 45px;
    position: absolute;
    top: 0;
    width: 1px;
  }

  /* line 1204, ../sass/_responsive.scss */
  .accordion-tab > li.active > a:before,
  .products-tab .accordion-tab > li.active > a:before {
    background: #444;
    background: rgba(255, 255, 255, 0.2);
  }

  /* line 1209, ../sass/_responsive.scss */
  .accordion-tab > li > a .open-sub,
  .products-tab .accordion-tab > li > a .open-sub {
    bottom: 0;
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    width: 45px;
  }

  /* line 1218, ../sass/_responsive.scss */
  .accordion-tab > li > a .open-sub:before,
  .accordion-tab > li > a .open-sub:after,
  .products-tab .accordion-tab > li > a .open-sub:before,
  .products-tab .accordion-tab > li > a .open-sub:after {
    background: #1e1e1e;
    content: "";
    display: block;
    height: 2px;
    left: 0;
    margin: 19px 0 0 15px;
    position: absolute;
    top: 0;
    width: 16px;
  }

  /* line 1232, ../sass/_responsive.scss */
  .accordion-tab > li > a .open-sub:after,
  .products-tab .accordion-tab > li > a .open-sub:after {
    height: 16px;
    margin: 12px 0 0 22px;
    width: 2px;
  }

  /* line 1238, ../sass/_responsive.scss */
  .accordion-tab > .active > a .open-sub:before,
  .accordion-tab > .active > a .open-sub:after,
  .products-tab .accordion-tab > .active > a .open-sub:before,
  .products-tab .accordion-tab > .active > a .open-sub:after {
    background: #fff;
  }

  /* line 1244, ../sass/_responsive.scss */
  .accordion-tab > .active > a .open-sub:after,
  .products-tab .accordion-tab > .active > a .open-sub:after {
    display: none;
  }

  /* line 1248, ../sass/_responsive.scss */
  .accordion-tab .tab-pane {
    border: 0 none;
    display: none;
    font-size: 13px;
    padding: 20px 0 30px;
  }

  /* line 1254, ../sass/_responsive.scss */
  .products-tab .accordion-tab .tab-pane {
    background: none;
  }

  /* line 1257, ../sass/_responsive.scss */
  .promo-partners .manufactures {
    text-align: center;
  }

  /* line 1260, ../sass/_responsive.scss */
  .page-header {
    margin-bottom: 40px;
  }

  /* line 1263, ../sass/_responsive.scss */
  .image-box {
    margin: 0 auto 40px;
    max-width: 470px;
  }

  /* line 1267, ../sass/_responsive.scss */
  .product,
  .double-product {
    width: 300px;
  }

  /* line 1271, ../sass/_responsive.scss */
  .double-product .product {
    max-width: 100%;
  }

  /* line 1274, ../sass/_responsive.scss */
  .product.product-mini {
    width: 250px;
  }

  /* line 1277, ../sass/_responsive.scss */
  .product-page .image-box .thumblist-box {
    max-height: 80px;
    padding: 0 45px;
  }

  /* line 1281, ../sass/_responsive.scss */
  .product-page .image-box #thumblist a {
    height: 70px;
    width: 70px;
  }

  /* line 1285, ../sass/_responsive.scss */
  .product-page .col-md-7 .price-box {
    font-size: 40px;
  }

  /* line 1288, ../sass/_responsive.scss */
  .product-page .add-cart-form {
    margin-bottom: 0;
  }

  /* line 1291, ../sass/_responsive.scss */
  .product-page .add-cart-form .add-cart,
  .product-page .add-cart-form .number {
    margin-bottom: 40px;
  }

  /* line 1295, ../sass/_responsive.scss */
  .product-page .product-tab {
    margin: 40px 0;
  }

  /* line 1298, ../sass/_responsive.scss */
  .product-page .product-tab .tab-pane {
    padding: 20px 0;
  }

  /* line 1301, ../sass/_responsive.scss */
  .product-page .product-options-table table th,
  .product-page .product-options-table table td,
  .product-page .product-options-table table td.qty {
    padding-left: 15px;
    padding-right: 15px;
  }

  /* line 1307, ../sass/_responsive.scss */
  .product-options-detail .add-cart {
    display: block;
    float: none;
    margin: 20px 0 0;
    min-width: inherit;
  }

  /* line 1313, ../sass/_responsive.scss */
  #car-bottom {
    margin-bottom: 0;
  }

  /* line 1316, ../sass/_responsive.scss */
  #checkoutsteps {
    margin-bottom: 40px;
  }

  /* line 1319, ../sass/_responsive.scss */
  .login .new-costumers {
    padding: 0 0 40px;
  }

  /* line 1322, ../sass/_responsive.scss */
  .map-box .contact-info {
    margin-bottom: 20px;
    padding: 0;
    position: relative;
    top: 0;
  }

  /* line 1328, ../sass/_responsive.scss */
  .box-404 {
    border-radius: 150px;
    height: 300px;
    margin: 0 auto 40px;
    padding: 75px 0 0;
    width: 300px;
  }

  /* line 1337, ../sass/_responsive.scss */
  .box-404 h1 {
    font-size: 120px;
  }

  /* line 1340, ../sass/_responsive.scss */
  .team-box {
    text-align: center;
  }

  /* line 1343, ../sass/_responsive.scss */
  .list-images {
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 20px;
    text-align: center;
  }

  /* line 1349, ../sass/_responsive.scss */
  .list-images > .col-md-4 {
    display: inline-block;
    margin: 0 0 20px;
    text-align: left;
    width: 170px;
  }

  /* line 1355, ../sass/_responsive.scss */
  .gallery .images-box {
    text-align: center;
  }

  /* line 1358, ../sass/_responsive.scss */
  .gallery-images {
    display: inline-block;
  }

  /* line 1361, ../sass/_responsive.scss */
  .gallery-images .bg-images {
    text-align: left;
  }

  /* line 1364, ../sass/_responsive.scss */
  .year-regulator .layout-slider {
    width: 240px;
  }

  /* line 1367, ../sass/_responsive.scss */
  .work-one .description {
    margin-bottom: 15px;
  }

  /* line 1370, ../sass/_responsive.scss */
  .work-box + .work-box {
    margin-top: 40px;
  }

  /* line 1373, ../sass/_responsive.scss */
  .employee {
    max-width: 300px;
  }

  /* line 1376, ../sass/_responsive.scss */
  .employee-single {
    width: 100%;
  }

  /* line 1379, ../sass/_responsive.scss */
  .employee-single .images-box {
    display: block;
    max-width: 430px;
  }

  /* line 1383, ../sass/_responsive.scss */
  .employee-single .images-box.pull-right {
    float: right;
    margin: 0 0 10px 20px;
  }

  /* line 1387, ../sass/_responsive.scss */
  .respond-carousel .carousel [class*="span"] {
    margin-left: 2px;
    margin-right: 2px;
  }

  /* line 1391, ../sass/_responsive.scss */
  .steps {
    display: block;
  }

  /* line 1394, ../sass/_responsive.scss */
  .steps .step {
    border: 1px solid #e1e1e1;
    display: block;
  }

  /* line 1398, ../sass/_responsive.scss */
  .steps .step + .step {
    border: 1px solid #e1e1e1;
    border-top: 0 none;
  }

  /* line 1402, ../sass/_responsive.scss */
  .steps .step:first-child {
    border-radius: 3px 3px 0 0;
  }

  /* line 1407, ../sass/_responsive.scss */
  .steps .step:last-child {
    border-radius: 0 0 3px 3px;
  }

  /* line 1412, ../sass/_responsive.scss */
  .steps .step .step-wrapper:after,
  .steps .step .step-wrapper:before {
    border: 7px solid transparent;
    border-top-color: #e1e1e1;
    bottom: -30px;
    left: 50%;
    margin: 0 0 0 -7px;
    right: auto;
    top: auto;
  }

  /* line 1422, ../sass/_responsive.scss */
  .steps .step .step-wrapper:before {
    border-top-color: #fff;
    bottom: -28px;
  }

  /* line 1426, ../sass/_responsive.scss */
  .steps .step.border-grey .step-wrapper:after,
  .steps .step.bg-grey .step-wrapper:before {
    border-left-color: transparent;
    border-top-color: #777;
  }

  /* line 1431, ../sass/_responsive.scss */
  .steps .step.border-warning .step-wrapper:after {
    border-top-color: #f89406;
    border-left-color: transparent;
  }

  /* line 1435, ../sass/_responsive.scss */
  .steps .step.border-error .step-wrapper:after {
    border-top-color: #c10841;
    border-left-color: transparent;
  }

  /* line 1439, ../sass/_responsive.scss */
  .steps .step.border-info .step-wrapper:after {
    border-top-color: #0098ca;
    border-left-color: transparent;
  }

  /* line 1443, ../sass/_responsive.scss */
  .steps .step.border-success .step-wrapper:after {
    border-top-color: #738d00;
    border-left-color: transparent;
  }

  /* line 1447, ../sass/_responsive.scss */
  .steps .step.bg-warning .step-wrapper:before {
    border-top-color: #f89406;
    border-left-color: transparent;
  }

  /* line 1451, ../sass/_responsive.scss */
  .steps .step.bg-error .step-wrapper:before {
    border-top-color: #c10841;
    border-left-color: transparent;
  }

  /* line 1455, ../sass/_responsive.scss */
  .steps .step.bg-info .step-wrapper:before {
    border-top-color: #0098ca;
    border-left-color: transparent;
  }

  /* line 1459, ../sass/_responsive.scss */
  .steps .step.bg-success .step-wrapper:before {
    border-top-color: #738d00;
    border-left-color: transparent;
  }

  /* line 1463, ../sass/_responsive.scss */
  .steps.steps-apart .step {
    border-radius: 3px;
    margin-bottom: 22px;
  }

  /* line 1469, ../sass/_responsive.scss */
  .steps.progress-steps .step {
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    margin-bottom: 22px;
  }

  /* line 1476, ../sass/_responsive.scss */
  .steps.progress-steps .step .step-wrapper {
    padding-left: 100px;
  }

  /* line 1479, ../sass/_responsive.scss */
  .steps.progress-steps .step.step-right .step-wrapper {
    padding-right: 100px;
  }

  /* line 1482, ../sass/_responsive.scss */
  .steps.progress-steps .step .number {
    font-size: 40px;
    line-height: 2;
    width: 80px;
  }

  /* line 1487, ../sass/_responsive.scss */
  #count-down .county-days-wrapper,
  #count-down .county-hours-wrapper,
  #count-down .county-minutes-wrapper,
  #count-down .county-seconds-wrapper {
    height: 80px;
  }

  /* line 1493, ../sass/_responsive.scss */
  #count-down .county-days,
  #count-down .county-hours,
  #count-down .county-minutes,
  #count-down .county-seconds {
    line-height: 80px;
  }

  /* line 1499, ../sass/_responsive.scss */
  .page-404-promo #main .title h1 {
    font-size: 120px;
  }

  /* line 1502, ../sass/_responsive.scss */
  .page-404-promo #main .content .back-home {
    padding: 10px 20px 10px 10px;
  }

  /* line 1505, ../sass/_responsive.scss */
  .timeline:before {
    left: 20px;
  }

  /* line 1508, ../sass/_responsive.scss */
  .timeline .post,
  .timeline .post:nth-child(even) {
    margin: 0;
    padding: 0 0 30px 60px;
    width: 100%;
  }

  /* line 1514, ../sass/_responsive.scss */
  .timeline .post .timeline-time,
  .timeline .post:nth-child(even) .timeline-time {
    left: 0;
    margin: 0 0 5px;
    position: relative;
    right: 0;
    text-align: left;
  }

  /* line 1522, ../sass/_responsive.scss */
  .timeline .post .timeline-icon {
    left: 0;
    margin: 22px 0 0 0;
  }

  /* line 1526, ../sass/_responsive.scss */
  .timeline .post .timeline-content:before,
  .timeline .post:nth-child(even) .timeline-content:before {
    border: 15px solid transparent;
    border-right-color: #ccc;
    left: -30px;
    right: auto;
  }

  /* line 1533, ../sass/_responsive.scss */
  .timeline .post .timeline-content:after,
  .timeline .post:nth-child(even) .timeline-content:after {
    border: 14px solid transparent;
    border-right-color: #fff;
    left: -28px;
    right: auto;
  }

  /* line 1540, ../sass/_responsive.scss */
  .timeline .post .timeline-content.bg:before,
  .timeline .post .timeline-content.border:before {
    border: 15px solid transparent;
    border-right-color: #ccc;
  }

  /* line 1545, ../sass/_responsive.scss */
  .timeline .post .timeline-content.bg-danger:before,
  .timeline .post:nth-child(even) .timeline-content.bg-danger:before,
  .timeline .post .timeline-content.border-danger:before,
  .timeline .post:nth-child(even) .timeline-content.border-danger:before {
    border-right-color: #c10841;
  }

  /* line 1551, ../sass/_responsive.scss */
  .timeline .post .timeline-content.bg-warning:before,
  .timeline .post:nth-child(even) .timeline-content.bg-warning:before,
  .timeline .post .timeline-content.border-warning:before,
  .timeline .post:nth-child(even) .timeline-content.border-warning:before {
    border-right-color: #f89406;
  }

  /* line 1557, ../sass/_responsive.scss */
  .timeline .post .timeline-content.bg-info:before,
  .timeline .post:nth-child(even) .timeline-content.bg-info:before,
  .timeline .post .timeline-content.border-info:before,
  .timeline .post:nth-child(even) .timeline-content.border-info:before {
    border-right-color: #0098ca;
  }

  /* line 1563, ../sass/_responsive.scss */
  .timeline .post .timeline-content.bg-success:before,
  .timeline .post:nth-child(even) .timeline-content.bg-success:before,
  .timeline .post .timeline-content.border-success:before,
  .timeline .post:nth-child(even) .timeline-content.border-success:before {
    border-right-color: #738d00;
  }

  /* line 1569, ../sass/_responsive.scss */
  .timeline .post .timeline-content.bg-primary:before,
  .timeline .post:nth-child(even) .timeline-content.bg-primary:before,
  .timeline .post .timeline-content.border-primary:before,
  .timeline .post:nth-child(even) .timeline-content.border-primary:before {
    border-right-color: #428bca;
  }

  /* line 1575, ../sass/_responsive.scss */
  .timeline .post .timeline-content .entry-content img {
    width: 100%;
  }

  /* line 1578, ../sass/_responsive.scss */
  #footer {
    margin-top: 0;
  }

  /* line 1581, ../sass/_responsive.scss */
  #footer .sidebar {
    margin-top: 0;
  }

  /* line 1584, ../sass/_responsive.scss */
  #footer .footer-top {
    padding-bottom: 0;
  }

  /* line 1587, ../sass/_responsive.scss */
  #footer .footer-top .col-md-3 {
    margin-bottom: 36px;
    overflow: hidden;
  }

  /* line 1591, ../sass/_responsive.scss */
  #footer .footer-bottom .col-md-3 {
    overflow: hidden;
  }

  /* line 1594, ../sass/_responsive.scss */
  #footer .footer-bottom .copyright {
    padding: 17px 0;
    text-align: center;
  }

  /* line 1598, ../sass/_responsive.scss */
  #footer .phone,
  #footer .address {
    float: left;
    margin-bottom: 29px;
    width: 48%;
  }

  /* line 1604, ../sass/_responsive.scss */
  #footer .phone .title {
    display: block;
  }

  /* line 1607, ../sass/_responsive.scss */
  #footer .address {
    float: right;
  }

  /* line 1610, ../sass/_responsive.scss */
  #footer .up {
    float: none;
    margin: 0 auto;
  }

  /* line 1614, ../sass/_responsive.scss */
  #count-down {
    text-align: center;
  }

  /* line 1617, ../sass/_responsive.scss */
  #count-down .county-days,
  #count-down .county-hours,
  #count-down .county-minutes,
  #count-down .county-seconds {
    font-size: 30px;
  }
}
/******************************************************************************
  Max Width "480px"
*******************************************************************************/
@media (max-width: 480px) {
  /* line 1629, ../sass/_responsive.scss */
  div.jp-audio div.jp-type-playlist div.jp-interface {
    height: 100px;
  }

  /* line 1632, ../sass/_responsive.scss */
  div.jp-audio ul.jp-controls {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* line 1636, ../sass/_responsive.scss */
  div.jp-audio ul.jp-controls li:nth-child(6) {
    clear: left;
  }

  /* line 1639, ../sass/_responsive.scss */
  div.jp-audio div.jp-type-playlist div.jp-progress,
  div.jp-audio div.jp-type-playlist div.jp-time-holder {
    left: 156px;
  }

  /* line 1643, ../sass/_responsive.scss */
  div.jp-audio div.jp-type-playlist a.jp-mute,
  div.jp-audio div.jp-type-playlist a.jp-unmute {
    margin-left: 0;
  }

  /* line 1647, ../sass/_responsive.scss */
  div.jp-audio div.jp-volume-bar {
    top: 77px;
    left: 29px;
  }

  /* line 1651, ../sass/_responsive.scss */
  div.jp-audio .jp-type-playlist ul.jp-toggles {
    left: 114px;
    top: 71px;
  }
}
/******************************************************************************
  Touch device
*******************************************************************************/
/* line 1661, ../sass/_responsive.scss */
.touch-device .carousel-box .next:hover,
.touch-device .carousel-box .prev:hover {
  background-color: #ccc;
  color: #fbfbfb;
}
/* line 1666, ../sass/_responsive.scss */
.touch-device #footer .up:hover {
  background: #c6c6c6;
}
/* line 1669, ../sass/_responsive.scss */
/*.touch-device .work:hover {
  text-shadow: 0 1px #000;
}*/
/* line 1672, ../sass/_responsive.scss */
/*.touch-device .work:hover .work-title {
  bottom: 37px;
  color: #fff;
}*/
/* line 1676, ../sass/_responsive.scss */
/*.touch-device .work:hover .bg-hover {
  opacity: 0;
  filter: alpha(opacity=0);
}*/
/* line 1680, ../sass/_responsive.scss */
.touch-device.fixed-header .page-box {
  margin-top: 0 !important;
  padding-top: 0 !important;
  -webkit-transition: none !important;
  transition: none !important;
}
/* line 1686, ../sass/_responsive.scss */
.touch-device.fixed-header .header, .touch-device.boxed.fixed-header .header, .touch-device.boxed.fixed-header.fixed .header, .touch-device.hidden-top.visible-top .header {
  box-shadow: none;
  position: static;
  top: inherit;
  -webkit-backface-visibility: inherit;
  -webkit-transform: inherit;
}
/* line 1698, ../sass/_responsive.scss */
.touch-device.hidden-top {
  -webkit-transition: all 0 linear;
  transition: all 0 linear;
}
/* line 1702, ../sass/_responsive.scss */
.touch-device.hidden-top .page-box, .touch-device.hidden-top.visible-top .page-box {
  padding-top: 0;
}
/* line 1706, ../sass/_responsive.scss */
.touch-device.hidden-top #top-box, .touch-device.boxed.hidden-top #top-box, .touch-device.fixed-top #top-box, .touch-device.boxed.fixed-header.fixed-top #top-box, .touch-device.boxed.fixed-header.fixed-top.fixed #top-box {
  margin-top: 0;
  margin-bottom: 0;
  position: static;
}
/* line 1715, ../sass/_responsive.scss */
.touch-device.hidden-top .header, .touch-device.boxed.hidden-top .header {
  margin-top: 0;
  position: inherit;
  top: 0;
}

/* line 1722, ../sass/_responsive.scss */
.boxed.fixed-header.fixed-top #top-box,
.boxed.fixed-header.fixed-top.fixed #top-box {
  margin-top: 0;
  margin-bottom: 0;
  position: static;
}

/******************************************************************************
  Not hover
*******************************************************************************/
/* line 1733, ../sass/_responsive.scss */
.touch-device .carousel-box .next:hover,
.touch-device .carousel-box .prev:hover {
  background-color: #ccc;
  color: #fbfbfb;
}
/* line 1738, ../sass/_responsive.scss */
.touch-device .carousel-box .next:hover polygon,
.touch-device .carousel-box .prev:hover polygon {
  fill: #fbfbfb;
}
/* line 1742, ../sass/_responsive.scss */
.touch-device .slider .slider-nav .prev,
.touch-device .slider .slider-nav .next {
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
}
/* line 1748, ../sass/_responsive.scss */
.touch-device .slider .slider-nav .prev:hover,
.touch-device .slider .slider-nav .next:hover {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
}
/* line 1756, ../sass/_responsive.scss */
.touch-device .slider .slider-nav .prev:hover polygon,
.touch-device .slider .slider-nav .next:hover polygon {
  fill: #838383;
}
/* line 1760, ../sass/_responsive.scss */
.touch-device .switches a:hover {
  background: #fff;
  background: rgba(255, 255, 255, 0.1);
}
/* line 1764, ../sass/_responsive.scss */
.touch-device .switches a.selected {
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}
/* line 1768, ../sass/_responsive.scss */
.touch-device .panel-group .panel .panel-heading > .panel-title > a:hover,
.touch-device .panel-group .panel-default .panel-heading > .panel-title > a:hover {
  background-color: #f2f2f2;
}
/* line 1772, ../sass/_responsive.scss */
.touch-device .panel-group .panel-primary .panel-heading > .panel-title > a:hover {
  background-color: #037ac5;
}
/* line 1775, ../sass/_responsive.scss */
.touch-device .panel-group .panel-success .panel-heading > .panel-title > a:hover {
  background-color: #738d00;
}
/* line 1778, ../sass/_responsive.scss */
.touch-device .panel-group .panel-info .panel-heading > .panel-title > a:hover {
  background-color: #0098ca;
}
/* line 1781, ../sass/_responsive.scss */
.touch-device .panel-group .panel-warning .panel-heading > .panel-title > a:hover {
  background-color: #f89406;
}
/* line 1784, ../sass/_responsive.scss */
.touch-device .panel-group .panel-danger .panel-heading > .panel-title > a:hover {
  background-color: #c10841;
}
/* line 1787, ../sass/_responsive.scss */
.touch-device .filter-buttons a:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
/* line 1791, ../sass/_responsive.scss */
.touch-device .gallery-images:hover > .bg-images {
  background: none;
}
/* line 1794, ../sass/_responsive.scss */
.touch-device .post.carousel .carousel-box .next,
.touch-device .post.carousel .carousel-box .prev {
  display: none !important;
}
/* line 1798, ../sass/_responsive.scss */
.fancybox-margin .touch-device.fixed-header .header, .fancybox-margin .touch-device.fixed-header #top-box {
  left: 0;
}
/* line 1802, ../sass/_responsive.scss */
.touch-device .fancybox-wrap {
  position: fixed !important;
  -webkit-overflow-scrolling: touch !important;
  top: 20px !important;
}
